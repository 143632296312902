import { useMemo, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "./useLocalStorage";
import { removeLocalStorageValue } from "../Utils/localStorage";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useLocalStorage("session");

  const navigate = useNavigate();

  const setAuthToken = (authData: {
    bearerToken: string;
    refreshToken: string;
  }) => {
    setSession(authData);
  };

  const login = async (authData: {
    bearerToken: string;
    refreshToken: string;
  }) => {
    setAuthToken(authData);
    navigate("/");
  };

  const logout = () => {
    setSession(null);
    removeLocalStorageValue();
    navigate("/u/login");
  };

  const value = useMemo(
    () => ({
      session,
      login,
      logout,
      setAuthToken,
    }),
    [session]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
