export interface Button {
  id: string;
  label?: string;
  size?: string;
  style?: string;
  theme?: string;
  icon?: any;
  isLoading?: boolean;
  isDisable?: boolean;
  isActive?: boolean;
  onClick?: Function;
  isMandatory?: boolean;
  isCompleted?: boolean;
  to?: string;
  state?: any;
  type?: string;
  href?: string;
  children?: React.ReactNode;
}

export enum STYLES {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  label = "label",
}
export enum SIZES {
  small = "sm",
  medium = "md",
  large = "lg",
}

export enum THEMES {
  success = "success",
  warning = "warning",
  fail = "fail",
}

export enum TYPES {
  button = "button",
  reset = "reset",
  submit = "submit",
}
