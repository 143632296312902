import axios from "axios";

export const saveTemplate = async (companyId, templateId, params) => {
  try {
    const res = await axios.post(
      `/company/${companyId}/template/${templateId}/region`,
      null,
      {
        params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
