import Breadcrumb from "../../Reusable/Breadcrumb";
import Filter from "../Filter";
import Tab from "../Tab";

const Header = ({ headerConfig }) => {
  const { tabList, breadcrumbList, information, filterList } =
    headerConfig ?? {};

  const renderTabList = tabList?.map((tab, index) => {
    return <Tab key={index} tab={tab} />;
  });

  const renderBreadcrumbList = breadcrumbList?.map((breadcrumb, index) => {
    return <Breadcrumb key={index} breadcrumb={breadcrumb} />;
  });

  return (
    <div className="dashboard__header">
      <div className="dashboard__tab">
        {renderTabList}
        {renderBreadcrumbList}

        <div
          className={
            "dashboard__information" +
            (information?.isError ? " dashboard__information--error" : "")
          }
        >
          {information?.isLoading && (
            <div className="dashboard__loading">
              <img />
            </div>
          )}
          {information?.message ?? ""}
        </div>
      </div>

      <Filter filterList={filterList} />
    </div>
  );
};

export default Header;
