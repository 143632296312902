import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { User, UserListResponse } from "../../Types/user";
import { Company } from "../../Types/company";

const initialState: {
  user: User;
  company: Company;
  list: UserListResponse;
} = {
  user: undefined,
  company: undefined,
  list: {
    result: null,
    total: 0,
    resultTotal: 0,
    searchTotal: 0,
    active: null,
  },
};

export const userSlicer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      if (action.payload) state.user = action.payload;
    },

    setCompany: (state, action: PayloadAction<any>) => {
      if (action.payload) state.company = action.payload;
    },

    clearUser: (state) => {
      state.user = undefined;
      state.company = undefined;
    },

    setUserList: (state, action: PayloadAction<any>) => {
      if (action.payload) state.list = action.payload;
    },

    clearUserList: (state) => {
      state.list = initialState.list;
    },

    addToUserList: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.list = {
          result: state.list?.result
            ? [action.payload, ...state.list.result]
            : [action.payload],
          total: state.list?.result ? state.list.total + 1 : 1,
          resultTotal: state.list?.result ? state.list.resultTotal + 1 : 1,
          searchTotal: state.list?.result ? state.list.searchTotal + 1 : 1,
          active: action.payload,
        };
      }
    },

    deleteFromUserList: (state, action: PayloadAction<any>) => {
      if (action.payload && state.list?.result) {
        state.list = {
          ...state.list,
          result: state.list.result.filter(
            (item) => item.id !== action.payload
          ),
          total: state.list.total - 1,
          resultTotal: state.list.resultTotal - 1,
          searchTotal: state.list.searchTotal - 1,
        };
      }
    },

    setActiveUser: (state, action: PayloadAction<any>) => {
      if (state?.list) state.list.active = action.payload;
    },
  },
});

export const {
  setUser,
  setCompany,
  clearUser,
  setUserList,
  clearUserList,
  addToUserList,
  deleteFromUserList,
  setActiveUser,
} = userSlicer.actions;

export default userSlicer.reducer;
