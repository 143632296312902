import axios from "axios";

export const parseTemplateRegion = async (
  companyId,
  templateId,
  data,
  params
) => {
  try {
    const res = await axios.post(
      `/company/${companyId}/template/${templateId}/parse`,
      data,
      {
        params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
