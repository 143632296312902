import axios from "axios";

export const updateDefaultTemplate = async (companyId, customerId, data) => {
  try {
    const res = await axios.put(
      `/company/${companyId}/customer/${customerId}/default-template`,
      data
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
