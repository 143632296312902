import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import successIcon from "../../../Assets/Reusable/valid.svg";
import failIcon from "../../../Assets/Reusable/error.svg";

import { setTemplateDetail } from "../../../Redux/Template/templateSlicer";

import { saveTemplate as saveTemplateAPI } from "../../../APIs/Template/saveTemplate";

import Confirmation from "../Reusable/Confirmation/Confirmation";
import Confetti from "../Reusable/Confetti/Confetti";
import Modal from "../Reusable/Modal/Modal";
import Container from "../Reusable/Modal/Container";

const TemplateSave = ({
  company,
  templateDetail,
  isSaveConfirm,
  setIsSaveConfirm,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("pending");
  const [error, setError] = useState(null);

  const icon = {
    success: successIcon,
    fail: failIcon,
    pending: null,
  };

  const title = {
    success: `Template has been successfully saved`,
    fail: `Template save has failed`,
    pending: `Confirm template save`,
  };

  const info = {
    success: (
      <>
        You may close the popup to view the saved template,
        <br /> or you can go to all templates to see the template listing.
      </>
    ),
    fail: (
      <>
        An error occurred while attempting to save the template.
        <br />
        Please try again. If the issue persists, please contact support.
        {error ? (
          <>
            <br />
            <p className="confirmation__info--error">
              <b>Error details: </b>
              {error}
            </p>
          </>
        ) : null}
      </>
    ),
    pending: (
      <>
        Are you sure you want to save the template?
        <br /> Please ensure you have cross-checked all template-related
        information before proceeding, as this action will override the previous
        template details.
      </>
    ),
  };

  const actionList = {
    success: {
      proceed: {
        type: "button",
        label: `Close`,
        id: "close",
        style: "primary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => setIsSaveConfirm(false),
      },
      cancel: {
        type: "button",
        label: `Go to all templates`,
        id: "goToTemplate",
        style: "secondary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => navigate("/template"),
      },
    },
    fail: {
      proceed: {
        type: "button",
        label: `Try saving again`,
        id: "trySaveAgain",
        style: "primary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => saveTemplate(),
      },
    },
    pending: {
      proceed: {
        type: "button",
        label: isLoading ? `Saving template` : `Save template`,
        id: "saveTemplate",
        style: "primary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => saveTemplate(),
      },
    },
  };

  const templateSaveConfig: any = {
    icon: icon[status || "pending"],
    title: title[status || "pending"],
    info: info[status || "pending"],
    actionList: actionList[status || "pending"],
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveTemplate = () => {
    if (company?.id && templateDetail?.id && templateDetail?.customerId) {
      const params = {
        customerId: templateDetail.customerId,
      };

      setStatus("pending");
      setIsLoading(true);
      setError(null);

      saveTemplateAPI(company.id, templateDetail.id, params)
        .then((res) => {
          dispatch(setTemplateDetail(res));
          setStatus("success");
        })
        .catch((err) => {
          setStatus("fail");
          if (err?.response?.data?.detail) setError(err.response.data.detail);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (!isSaveConfirm) return null;

  return (
    <Modal id="template-save" onClose={() => setIsSaveConfirm(false)}>
      <Container onClose={() => setIsSaveConfirm(false)}>
        <div
          className={
            "template-save" +
            (status === "success" ? " template-save--success" : "") +
            (status === "fail" ? " template-save--fail" : "")
          }
        >
          <Confirmation config={templateSaveConfig} />

          {status === "success" && <Confetti />}
        </div>
      </Container>
    </Modal>
  );
};

export default TemplateSave;
