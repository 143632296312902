import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { areaConfig, setting } from "./labelSystemConfig";

import { getTemplateRegion as getTemplateRegionAPI } from "../../../../APIs/Template/getTemplateRegion";
import { getTemplateConfig as getTemplateConfigAPI } from "../../../../APIs/Template/getTemplateConfig";

import { Company } from "../../../../Types/company";
import { Template } from "../../../../Types/template";

import { RootState } from "../../../../Redux/store";
import {
  setActiveArea,
  setActiveRegion,
  setRegionConfig,
} from "../../../../Redux/Template/parserSlicer";

import Header from "./Header";
import Setting from "./Setting/Setting";
import Parser from "./Parser/Parser";
import { snakeToCamel } from "../../../../Utils";

const LabelSystem = () => {
  const dispatch = useDispatch();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const templateDetail: Template = useSelector(
    (state: RootState) => state.template.detail
  );

  const { regionConfig } = useSelector((state: RootState) => state.parser);

  useEffect(() => {
    getTemplateRegion();
  }, [company, templateDetail?.id, templateDetail?.customerId]);

  const initRegionConfig = (regionConfig) => {
    const region = regionConfig?.order?.[0];
    dispatch(setActiveRegion(region));
    dispatch(setActiveArea(regionConfig?.region?.[region]?.order?.[0]));
  };

  const getTemplateRegion = () => {
    if (company?.id && templateDetail?.id && templateDetail?.customerId) {
      const params = { customerId: templateDetail?.customerId };

      getTemplateRegionAPI(company.id, templateDetail.id, params)
        .then((res) => {
          if (res?.data) {
            res.data.order = snakeToCamel(res.data.order);
            dispatch(setRegionConfig(res.data));
            initRegionConfig(res.data);
          } else {
            getTemplateConfig();
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  };

  const getTemplateConfig = () => {
    getTemplateConfigAPI(company?.id)
      .then((res: any) => {
        res.order = snakeToCamel(res.order);
        addAreaToRegion(res);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const addAreaToRegion = (config) => {
    if (!config) return;
    const control = config?.region;

    control &&
      Object.keys(control)?.map((key) => {
        const param = control[key]?.regionType === "table" ? setting : null;

        if (control[key])
          control[key] = {
            ...control[key],
            ...areaConfig,
            ...param,
          };
      });

    dispatch(setRegionConfig(config));
    initRegionConfig(config);
  };

  const getRegionIndex = (region) => {
    const index = regionConfig?.order?.findIndex((item) => item === region);
    return Math.max(index, 0);
  };

  return (
    <div className="label-system">
      <Header getRegionIndex={getRegionIndex} />

      <Setting />

      <Parser getRegionIndex={getRegionIndex} />
    </div>
  );
};

export default LabelSystem;
