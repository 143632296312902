export const camelToSnake = (obj: object | string) => {
  const str = JSON.stringify(obj);
  const res = str.replace(/[A-Z]/g, (c) => {
    return "_" + c.toLowerCase();
  });

  return JSON.parse(res);
};

export const snakeToCamel = (obj: object | string) => {
  const str = JSON.stringify(obj);
  const res = str.replace(/_([a-z])/g, (_, match) => {
    return match.toUpperCase();
  });

  return JSON.parse(res);
};

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const openLink = (link, isOpenInNewTab = false) => {
  if (!link) return null;
  window.open(link, isOpenInNewTab ? "_blank" : null);
};

export const openStaticFile = (file) => {
  const fileURL = URL.createObjectURL(file);
  openLink(fileURL, true);
};

export const roundNum = (num) => {
  return num % 1 !== 0 ? num.toFixed(2).replace(/\.?0+$/, "") : num.toString();
};

export const extractEnum = (
  enumObject: any
): { key: string; label: string }[] => {
  return Object.keys(enumObject).map((key) => ({
    key,
    label: enumObject[key as keyof typeof enumObject],
  }));
};
