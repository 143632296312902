export const customerDetailConfig = {
  label: "Customer details",
  attributeList: {
    customerCode: {
      label: "Customer code",
      defaultValue: "Unavailable",
    },
    customerName: {
      label: "Customer name",
      defaultValue: "Unavailable",
    },
  },
  order: ["customerCode", "customerName"],
};

export const templateDetailConfig = {
  label: "Template details",
  attributeList: {
    name: {
      label: "Template name",
      defaultValue: "Unavailable",
    },
    createdByName: {
      label: "Created by",
      defaultValue: "Unavailable",
    },
    description: {
      label: "Description",
      defaultValue: "Unavailable",
    },
  },
  order: ["name", "createdByName", "description"],
};

export const saleDetailConfig = {
  label: "Sale details",
  attributeList: {
    id: {
      label: "Sale ID",
      defaultValue: "Unavailable",
    },
    saleSource: {
      label: "Source of sale",
      defaultValue: "Unavailable",
    },
    saleType: {
      label: "Type of sale",
      defaultValue: "Unavailable",
    },
    createdByName: {
      label: "Created by",
      defaultValue: "Unavailable",
    },
    createdOn: {
      label: "Created on",
      defaultValue: "Unavailable",
    },
    lastModifiedOn: {
      label: "Last modified on",
      defaultValue: "Not modified yet",
    },
  },
  order: [
    "id",
    "saleSource",
    "saleType",
    "createdByName",
    "createdOn",
    "lastModifiedOn",
  ],
};

export const saleSummaryConfig = {
  label: "Sale summary",
  attributeList: {
    customerPrice: {
      label: "Total customer price",
      defaultValue: 0,
    },
    correctedPrice: {
      label: "Total corrected price",
      defaultValue: 0,
    },
  },
  order: ["customerPrice", "correctedPrice"],
};

export const documentDetailConfig = {
  label: "Document details",
  fileList: null,
  defaultValue: "Document is unavailable or inaccessible",
};

export const saleLegendData = [
  {
    code: 1,
    label: "Validation pending",
  },
  {
    code: 2,
    label: "Lowest price",
  },
  {
    code: 3,
    label: "Highest price",
  },
  {
    code: 4,
    label: "Special product",
  },
  {
    code: 5,
    label: "Quantity exceeds stock level",
  },
];
