import SliderPrimary1 from "./../../../Assets/Onboard/Showcase/slider-primary-1.png";
import SliderSecondary1 from "./../../../Assets/Onboard/Showcase/slider-secondary-1.png";

const Showcase = () => {
  return (
    <main className="showcase h-100-perc h-remaining">
      <header className="showcase__header">
        <h1>
          Streamline Data Extraction from PDFs with Unmatched Precision and Ease
        </h1>
        <h2>The simplest and most accurate way to parse your documents</h2>
      </header>

      <main className="showcase__slider h-remaining">
        <div className="showcase__frame">
          <div className="showcase__image h-100-perc showcase__image--primary">
            <img src={SliderPrimary1} alt="" />
          </div>
          <div className="showcase__image showcase__image--secondary">
            <img src={SliderSecondary1} alt="" />
          </div>
        </div>
        <div className="showcase__nav-list">
          <div className="showcase__nav showcase__nav--active">
            <span className="showcase__nav-label">Sales dashboard</span>
            <div className="showcase__nav-dot"></div>
          </div>
          <div className="showcase__nav">
            <div className="showcase__nav-dot"></div>
          </div>
          <div className="showcase__nav">
            <div className="showcase__nav-dot"></div>
          </div>
          <div className="showcase__nav">
            <div className="showcase__nav-dot"></div>
          </div>
          <div className="showcase__nav">
            <div className="showcase__nav-dot"></div>
          </div>
        </div>
      </main>
    </main>
  );
};

export default Showcase;
