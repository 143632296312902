import Cell from "./Cell";
import Tool from "./Tool/Tool";

const Row = ({
  source,
  isAllMap,
  row,
  rowIndex,
  order,
  isSaving,
  isValidating,
  isEdit,
  setIsEdit,
  setActiveRowIndex,
  setActiveCellIndex,
  activeRowIndex,
  activeCellIndex,
  rowMax,
  setActiveEdit,
  activeRowList,
  setActiveRowList,
}) => {
  const isMapCell = {
    key: "isMap",
    type: "map",
    value: rowIndex === 0 ? isAllMap : row?.isMap,
  };

  const { pin: pinList } = order ?? {};

  const renderCell = (cell, index, isPin?: boolean) => {
    return (
      <Cell
        key={index}
        source={source}
        cell={cell}
        isPin={isPin}
        isSaving={isSaving}
        isValidating={isValidating}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        cellIndex={index}
        rowIndex={rowIndex}
        activeRowIndex={activeRowIndex}
        activeCellIndex={activeCellIndex}
        setActiveRowIndex={setActiveRowIndex}
        setActiveCellIndex={setActiveCellIndex}
        rowMax={rowMax}
        columnMax={row?.row?.length ?? 0}
        setActiveEdit={setActiveEdit}
      />
    );
  };

  const renderRow = row?.row?.map((cell, index) => {
    return !pinList?.includes(index) ? renderCell(cell, index, false) : null;
  });

  const renderPinRow = pinList?.map((index) => {
    const cell = row?.row[index];
    const isPin = pinList?.includes(index);

    return renderCell(cell, index, isPin);
  });

  const isRowActive = activeRowList?.includes(rowIndex);

  return (
    <div
      className={
        "table__row" +
        (rowIndex === 0 ? " table__row--header" : "") +
        (isRowActive ? " table__row--active" : "")
      }
    >
      {source === "sale" && (
        <>
          <Tool
            rowIndex={rowIndex}
            setActiveEdit={setActiveEdit}
            isSaving={isSaving}
            isValidating={isValidating}
            activeRowList={activeRowList}
            setActiveRowList={setActiveRowList}
          />

          <div className="table__row--pin">
            {source === "sale" && renderCell(isMapCell, -1)}
            {renderPinRow}
          </div>
        </>
      )}

      <div className="table__row--regular">{renderRow}</div>
    </div>
  );
};

export default Row;
