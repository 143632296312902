export const productCardConfig = {
  attributeList: {
    code: {
      label: "Product code",
      defaultValue: "Unavailable",
    },
    name: {
      label: "Product name",
      defaultValue: "Unavailable",
    },
    description: {
      label: "Description",
      defaultValue: "Unavailable",
    },
    price: {
      label: "Price",
      defaultValue: "Unavailable",
    },
  },
  order: ["code", "name", "description", "price"],
};
