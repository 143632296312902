import saleIcon from "../../../../Assets/Dashboard/sale.svg";
import emailIcon from "../../../../Assets/Dashboard/email.svg";
import templateIcon from "../../../../Assets/Dashboard/template.svg";
import productIcon from "../../../../Assets/Dashboard/product.svg";
import customerIcon from "../../../../Assets/Dashboard/customer.svg";
import userIcon from "../../../../Assets/Dashboard/user.svg";

import { Navigation } from "../../../../Types/navigation";

export const navigationConfig: Navigation[] = [
  {
    id: "sale",
    label: "Sales",
    icon: saleIcon,
    link: "/",
  },
  {
    id: "email",
    label: "Emails",
    icon: emailIcon,
    link: "/email",
  },
  {
    id: "template",
    label: "Templates",
    icon: templateIcon,
    link: "/template",
  },
  {
    id: "product",
    label: "Products",
    icon: productIcon,
    link: "/product",
  },
  {
    id: "customer",
    label: "Customers",
    icon: customerIcon,
    link: "/customer",
  },
  {
    id: "user",
    label: "Users",
    icon: userIcon,
    link: "/user",
  },
];

export const getNavigation = (link) => {
  return navigationConfig?.filter((nav) => nav.link === link);
};
