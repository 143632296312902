import axios from "axios";

export const getDocumentDetail = async (companyId, target, id, params) => {
  try {
    const res = await axios.get(
      `/company/${companyId}/${target}/${id}/document-link`,
      { params }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
