import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../Redux/store";

import {
  setEmailList,
  updateEmailList,
  clearEmailList,
  setActiveEmail,
} from "../../../Redux/Email/emailSlicer";

import useOnScroll from "../../../Hooks/useOnScroll";

import { formatDate } from "../../../Utils/formatDate";
import { getListMessage } from "../../../Utils/listMessage";

import { Button } from "../../../Types/button";
import { Company } from "../../../Types/company";
import { EmailListResponse } from "../../../Types/email";

import { getEmailList as getEmailListAPI } from "../../../APIs/Email/getEmailList";
import { onSync as onSyncAPI } from "../../../APIs/Reusable/onSync";
import { getSyncStatus as getSyncStatusAPI } from "../../../APIs/Reusable/getSyncStatus";
import { streamUpdate } from "../../../APIs/Reusable/streamUpdate";

import Dashboard from "../Dashboard/Dashboard";
import EmailList from "./EmailList";
import BasicInfoModal from "../Reusable/Modal/BasicInfoModal/BasicInfoModal";

const Email = () => {
  const emailRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const controller = new AbortController();
  const signal = controller.signal;

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const {
    result,
    inboxTotal,
    markLaterTotal,
    resultTotal,
    searchTotal,
  }: EmailListResponse = useSelector((state: RootState) => state.email?.list);

  const [searchParam, setSearchParam] = useSearchParams();
  const searchTerm = searchParam?.get("q");
  const modalPath = searchParam?.get("modal");
  const tab = searchParam?.get("tab");

  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const [activeTab, setActiveTab] = useState("inbox");

  const defaultLimit = 100;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isListErr, setIsListErr] = useState(false);

  useOnScroll(emailRef);

  useEffect(() => {
    if (company?.id) {
      getSyncStatus();
    }
  }, [company]);

  useEffect(() => {
    if (company?.id) {
      setSkip(0);
      setLimit(defaultLimit);
      getEmailList(company.id, true);
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading && !isListErr) {
      getEmailList(company.id, skip === 0);
    }
  }, [company, skip, activeTab, isListErr]);

  useEffect(() => {
    if (company?.id) {
      streamEmailList();
    }

    return () => {
      controller.abort();
    };
  }, [company]);

  useEffect(() => {
    const status = syncStatus?.status;

    if (company?.id && (status === "COMPLETE" || status === "FAIL")) {
      setIsSyncLoading(false);
      setSkip(0);
      dispatch(clearEmailList());
      getEmailList(company.id, true);
    }
  }, [company, syncStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearEmailList());
    };
  }, []);

  useEffect(() => {
    setSkip(0);
    setLimit(defaultLimit);
    dispatch(clearEmailList());
    setActiveTab(tab && tab === "mark-for-later" ? "markForLater" : "inbox");
  }, [tab]);

  const getSyncStatus = () => {
    getSyncStatusAPI(company.id, "email")
      .then((res: any) => {
        setSyncStatus(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const elem = emailRef?.current ?? null;

    if (elem) {
      elem.addEventListener("scroll", onScroll);

      return () => {
        elem.removeEventListener("scroll", onScroll);
      };
    }
  }, [result, isListErr]);

  const onScroll = () => {
    const elem: any = emailRef?.current ?? null;
    const offset = 10;

    if (
      elem &&
      elem?.scrollTop + elem?.clientHeight + offset >= elem?.scrollHeight
    ) {
      if (company?.id && isListErr) {
        setIsListErr(false);
      } else {
        setLimit(
          searchTotal <= resultTotal + defaultLimit &&
            searchTotal - resultTotal > 0
            ? searchTotal - resultTotal
            : defaultLimit
        );

        if (searchTotal - resultTotal > 0) {
          setSkip(resultTotal);
        }
      }
    }
  };

  const onSync = () => {
    if (company?.id) {
      setIsSyncLoading(true);
      onSyncAPI(company.id, "email")
        .then((res: any) => {
          setSyncStatus(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getEmailList = (companyId, isReset) => {
    setIsListLoading(true);

    const params = {
      skip: isReset ? 0 : skip,
      limit: isReset ? defaultLimit : limit,
      searchTerm,
      markLater: activeTab === "markForLater",
    };

    getEmailListAPI(companyId, params)
      .then((res: any) => {
        if (res) {
          if (!isReset && result?.length) {
            res.result = [...result, ...res.result];
            res.resultTotal += resultTotal;
          }

          dispatch(setEmailList(res));
          setIsListErr(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsListErr(true);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const streamEmailList = () => {
    streamUpdate(
      company?.id,
      "emails",
      signal,
      onOpen,
      onMessage,
      onClose,
      onError
    );
  };

  const onOpen = () => {
    console.log("Stream update opened:");
  };

  const onMessage = (e) => {
    if (e?.event && e?.data) {
      switch (e?.event) {
        case "create":
        case "update":
          setSyncStatus(e?.data);
          dispatch(updateEmailList(e?.data));
          setTimeout(() => {
            dispatch(setActiveEmail(null));
          }, 3000);
          break;

        default:
          break;
      }
    }
  };

  const onClose = () => {
    console.log("Stream update closed");
  };

  const onError = (err) => {
    console.log("Stream update error:", err);
  };

  const { status } = syncStatus ?? {};
  const isSync = status === "QUEUE" || status === "PROCESS" || isSyncLoading;

  const actionConfig: Button[] = [
    {
      type: "button",
      label: `Refresh${isSync ? "ing" : ""} emails`,
      id: "syncEmail",
      style: "primary",
      size: "medium",
      isLoading: isSync,
      onClick: onSync,
    },
  ];

  const onChange = (key, value) => {
    searchParam.delete("q");
    if (value) {
      searchParam.append("q", value);
      setSearchParam(searchParam);
    } else {
      setSearchParam(searchParam);
    }
  };

  const message = getListMessage(
    "email",
    isListLoading,
    skip,
    limit,
    resultTotal,
    isListErr,
    searchTotal,
    searchTerm
  );

  const headerConfig = {
    tabList: [
      {
        id: "inbox",
        label: "Inbox",
        count: inboxTotal ?? 0,
        isActive: activeTab === "inbox",
      },
      {
        id: "markForLater",
        label: "Marked for later",
        count: markLaterTotal ?? 0,
        link: "?tab=mark-for-later",
        isActive: activeTab === "markForLater",
      },
    ],
    information: {
      message: message,
      isLoading: isListLoading,
    },
    filterList: {
      templateSearch: {
        type: "text",
        key: "templateSearch",
        id: "templateSearch",
        name: "templateSearch",
        label: "Search",
        query: "s",
        defaultValue: searchTerm,
        placeholder: "Search using email details",
        onChange: onChange,
      },
    },
  };

  const headerInfo = {
    defaultValue: "Unavailable",
    key: "lastModifiedOn",
    label: "Last refreshed on",
    value: formatDate(syncStatus?.lastModifiedOn, "long") ?? null,
  };

  return (
    <Dashboard
      actionConfig={actionConfig}
      headerConfig={headerConfig}
      headerInfo={headerInfo}
      isSyncLoading={isSyncLoading}
    >
      <div className="email h-100-perc scroll" ref={emailRef}>
        <EmailList
          emailList={result}
          searchTerm={searchTerm}
          activeTab={activeTab}
        />
      </div>

      {modalPath && <BasicInfoModal />}
    </Dashboard>
  );
};

export default Email;
