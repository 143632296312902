import { useState } from "react";

import { DropdownType } from "../../../../../../Types/dropdown";

import Input from "../Input";
import Dropdown from "./Dropdown";

const DropdownSearch: React.FC<DropdownType> = ({
  type,
  id,
  name,
  label,
  size,
  icon,
  defaultValue,
  placeholder,
  list,
  hasScroll = true,
  offset,
  isListOpen,
  setIsListOpen,
  isOptional,
  isDisableOutsideClick,
  scrollElem,
  control,
  onSelect,
  onFocus,
  errors,
  clearErrors,
  register,
  ...props
}) => {
  const isEditable = type === "dropdownSearch" ? true : false;
  const [isListOpenWithin, setIsListOpenWithin] = useState(false);

  const onClick = (e) => {
    e?.preventDefault();
    if (setIsListOpen) setIsListOpen(true);
    else setIsListOpenWithin(true);
  };

  const renderChildren = () => {
    return (
      <div className="dropdown__children" onClick={onClick}>
        {props.children}
      </div>
    );
  };

  const renderInput = () => {
    return (
      <Input
        type={"text"}
        id={`${id}-search`}
        name={`${name}-search`}
        label={label}
        isEditable={isEditable}
        isOptional={isOptional}
        onClick={onClick}
        onFocus={onFocus}
        errors={errors}
        register={register}
      />
    );
  };

  const isOpen = isListOpen ? isListOpen : isListOpenWithin;
  const child = props?.children ? renderChildren() : renderInput();

  if (isOpen) {
    return (
      <Dropdown
        type={type}
        id={id}
        name={name}
        label={label}
        hasScroll={true}
        offset={offset}
        list={list}
        isListOpen={isOpen}
        setIsListOpen={setIsListOpen ? setIsListOpen : setIsListOpenWithin}
        onSelect={onSelect}
        isDisableOutsideClick={isDisableOutsideClick}
        scrollElem={scrollElem}
        errors={errors}
        register={register}
      >
        {child}
      </Dropdown>
    );
  } else return child;
};

export default DropdownSearch;
