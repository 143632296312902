import EmptyIcon from "./../../../Assets/Reusable/empty.png";

import Empty from "../Reusable/Empty";
import EmailCard from "./EmailCard";

const EmailList = ({ emailList, searchTerm, activeTab }) => {
  const renderEmailList = emailList?.map((email) => {
    if (!email) return;

    return <EmailCard key={email.id} email={email} activeTab={activeTab} />;
  });

  if (!emailList || emailList?.length === 0) {
    const title = searchTerm
      ? `No email matching the search '${searchTerm}'`
      : "You don’t have any emails, try refreshing!";

    return (
      <Empty
        icon={EmptyIcon}
        alt="Empty screen icon for email"
        title={title}
        info={
          <>
            Click on the <b>Refresh Email</b> button in the header to get the
            all the emails
          </>
        }
      />
    );
  }

  return <div className="email__list scroll__child">{renderEmailList}</div>;
};

export default EmailList;
