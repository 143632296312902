import EmptyIcon from "./../../../Assets/Reusable/empty.png";

import Empty from "../Reusable/Empty";
import ProductCard from "./ProductCard";

const ProductList = ({ productList, searchTerm }) => {
  const renderProductList = productList?.map((product) => {
    if (!product) return;

    return <ProductCard key={product.id} product={product} />;
  });

  if (!productList || productList?.length === 0) {
    const title = searchTerm
      ? `No product matching the search '${searchTerm}'`
      : "You don’t have any products, try refreshing!";

    return (
      <Empty
        icon={EmptyIcon}
        alt="Empty screen icon for product"
        title={title}
        info={
          <>
            Click on the <b>Refresh Products</b> button in the header to get the
            all the products
          </>
        }
      />
    );
  }

  return <div className="product__list scroll__child">{renderProductList}</div>;
};

export default ProductList;
