import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getNavigation } from "./NavigationConfig";

import ArrowIcon from "../../../../Assets/Reusable/arrowIcon";

import { setIsCollapse } from "../../../../Redux/Setting/settingSlicer";

import Profile from "../../Reusable/User/Profile";
import Navigation from "./Navigation";
import Footer from "./Footer";

const Sidebar = ({ isCollapse }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const activeNav = getNavigation(location?.pathname)?.[0];

  const renderNav = (nav, i) => {
    return (
      <div
        key={i}
        className={"nav" + (nav.id === activeNav?.id ? " nav--active" : "")}
        onClick={nav.onClick ?? null}
      >
        <div className="nav__icon">
          <img src={nav.icon} alt={nav.label} />
        </div>

        {!isCollapse && <div className="nav__label">{nav.label}</div>}
      </div>
    );
  };

  const renderNavList = (navList) => {
    return navList?.map((nav, i) => {
      if (!nav) return;

      if (nav.link)
        return (
          <Link key={i} to={nav.link}>
            {renderNav(nav, i)}
          </Link>
        );
      else return renderNav(nav, i);
    });
  };

  return (
    <div className="sidebar h-100-perc">
      <header className="sidebar__header">
        <Profile isCollapse={isCollapse} />
      </header>

      <Navigation renderNavList={renderNavList} />

      <Footer renderNavList={renderNavList} />

      <div
        className="sidebar__collapse"
        onClick={() => dispatch(setIsCollapse(!isCollapse))}
      >
        <ArrowIcon />
      </div>
    </div>
  );
};

export default Sidebar;
