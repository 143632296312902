import Attribute from "../../../Reusable/Attribute/Attribute";

const Text = ({ data }) => {
  const attribute = {
    ...data,
    defaultValue: "No value available",
  };

  return (
    <div className="text">
      <Attribute attribute={attribute} />
    </div>
  );
};

export default Text;
