import { useState, useEffect, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../Redux/store";
import {
  clearCustomerList,
  setCustomerList,
} from "../../../Redux/Customer/customerSlicer";

import { formatDate } from "../../../Utils/formatDate";
import { getListMessage } from "../../../Utils/listMessage";

import useOnScroll from "../../../Hooks/useOnScroll";

import { Button } from "../../../Types/button";
import { Company } from "../../../Types/company";
import { CustomerListResponse } from "../../../Types/customer";

import { getCustomerList as getCustomerListAPI } from "../../../APIs/Customer/getCustomerList";
import { onSync as onSyncAPI } from "../../../APIs/Reusable/onSync";
import { getSyncStatus as getSyncStatusAPI } from "../../../APIs/Reusable/getSyncStatus";
import { streamUpdate } from "../../../APIs/Reusable/streamUpdate";

import Dashboard from "../Dashboard/Dashboard";
import CustomerList from "./CustomerList";

const Customer = () => {
  const customerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const controller = new AbortController();
  const signal = controller.signal;

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const { result, total, resultTotal, searchTotal }: CustomerListResponse =
    useSelector((state: RootState) => state.customer?.list);

  const [searchParam, setSearchParam] = useSearchParams();
  const searchTerm = searchParam?.get("q");

  const [isListLoading, setIsListLoading] = useState<boolean>(false);
  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(false);
  const [syncStatus, setSyncStatus] = useState(null);

  const defaultLimit = 100;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isListErr, setIsListErr] = useState(false);

  useOnScroll(customerRef);

  useEffect(() => {
    if (company?.id) {
      setSkip(0);
      setLimit(defaultLimit);
      getCustomerList(company.id, true);
      getSyncStatus();
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading) {
      setSkip(0);
      dispatch(clearCustomerList());
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading && skip > 0 && limit > 0 && !isListErr) {
      getCustomerList(company.id, false);
    }
  }, [company, skip, isListErr]);

  useEffect(() => {
    if (company?.id) {
      streamCustomerList();
    }

    return () => {
      controller.abort();
    };
  }, [company]);

  useEffect(() => {
    if (company?.id && syncStatus?.status === "COMPLETE") {
      setIsSyncLoading(false);
      setSkip(0);
      setLimit(defaultLimit);
      getCustomerList(company.id, true);
    }
  }, [company, syncStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearCustomerList());
    };
  }, []);

  useEffect(() => {
    const elem = customerRef?.current ?? null;

    if (elem) {
      elem.addEventListener("scroll", onScroll);

      return () => {
        elem.removeEventListener("scroll", onScroll);
      };
    }
  }, [result, isListErr]);

  const onScroll = () => {
    const elem: any = customerRef?.current ?? null;
    const offset = 10;

    if (
      elem &&
      elem?.scrollTop + elem?.clientHeight + offset >= elem?.scrollHeight
    ) {
      if (company?.id && isListErr) {
        setIsListErr(false);
      } else {
        setLimit(
          searchTotal <= resultTotal + defaultLimit &&
            searchTotal - resultTotal > 0
            ? searchTotal - resultTotal
            : defaultLimit
        );

        if (searchTotal - resultTotal > 0) {
          setSkip(resultTotal);
        }
      }
    }
  };

  const getSyncStatus = () => {
    getSyncStatusAPI(company.id, "customer")
      .then((res: any) => {
        setSyncStatus(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSync = () => {
    if (company?.id) {
      setIsSyncLoading(true);
      onSyncAPI(company.id, "customer")
        .then((res: any) => {
          setSyncStatus(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getCustomerList = (companyId, isReset) => {
    setIsListLoading(true);
    const params = {
      skip: isReset ? 0 : skip,
      limit: isReset ? defaultLimit : limit,
      searchTerm,
    };

    getCustomerListAPI(companyId, params)
      .then((res: any) => {
        if (res) {
          if (!isReset && result?.length) {
            res.result = [...result, ...res.result];
            res.resultTotal += resultTotal;
          }

          dispatch(setCustomerList(res));
          setIsListErr(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsListErr(true);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const streamCustomerList = () => {
    streamUpdate(
      company?.id,
      "customers",
      signal,
      onOpen,
      onMessage,
      onClose,
      onError
    );
  };

  const onOpen = () => {
    console.log("Stream update opened:");
  };

  const onMessage = (e) => {
    if (e?.event && e?.data) {
      switch (e?.event) {
        case "create":
        case "update":
          setSyncStatus(e?.data);
          break;

        default:
          break;
      }
    }
  };

  const onClose = () => {
    console.log("Stream update closed");
  };

  const onError = (err) => {
    console.log("Stream update error:", err);
  };

  const { status } = syncStatus ?? {};
  const isSync = status === "QUEUE" || status === "PROCESS" || isSyncLoading;

  const actionConfig: Button[] = [
    {
      type: "button",
      label: `Refresh${isSync ? "ing" : ""} customers`,
      id: "syncCustomer",
      style: "primary",
      size: "medium",
      isLoading: isSync,
      onClick: onSync,
    },
  ];

  const onChange = (key, value) => {
    if (value) {
      setSearchParam(createSearchParams({ q: value }));
    } else {
      searchParam.delete("q");
      setSearchParam(searchParam);
    }
  };

  const message = getListMessage(
    "customer",
    isListLoading,
    skip,
    limit,
    resultTotal,
    isListErr,
    searchTotal,
    searchTerm
  );

  const headerConfig = {
    tabList: [
      {
        label: "Customers",
        count: total ?? 0,
      },
    ],
    information: {
      message: message,
      isLoading: isListLoading,
      isError: isListErr,
    },
    filterList: {
      templateSearch: {
        type: "text",
        key: "templateSearch",
        id: "templateSearch",
        name: "templateSearch",
        label: "Search",
        query: "s",
        defaultValue: searchTerm,
        placeholder: "Search using customer details",
        onChange: onChange,
      },
    },
  };

  const headerInfo = {
    defaultValue: "Unavailable",
    key: "lastModifiedOn",
    label: "Last refreshed on",
    value: formatDate(syncStatus?.lastModifiedOn, "long") ?? null,
  };

  return (
    <Dashboard
      actionConfig={actionConfig}
      headerConfig={headerConfig}
      headerInfo={headerInfo}
      isSyncLoading={isSyncLoading}
    >
      <div className="customer h-100-perc scroll" ref={customerRef}>
        <CustomerList customerList={result} searchTerm={searchTerm} />
      </div>
    </Dashboard>
  );
};

export default Customer;
