import { useState } from "react";

import OpenFileIcon from "../../../../Assets/Reusable/OpenFileIcon";
import CloseIcon from "../../../../Assets/Reusable/CloseIcon";

import { openLink, openStaticFile } from "../../../../Utils";

import { pdfViewType } from "../../../../Types/pdf";

import Button from "../Button";
import Pdf from "./Pdf";
import Zoom from "./Zoom";

const PdfViewer: React.FC<{
  type: pdfViewType;
  pdfViewerId;
  documentDetail?;
  config?;
  activeRegion?;
  activeArea?;
  getRegionIndex?;
  setRegionCoordinate?;
  onClose?;
}> = ({
  type,
  pdfViewerId,
  documentDetail,
  config,
  activeRegion,
  activeArea,
  getRegionIndex,
  setRegionCoordinate,
  onClose,
}) => {
  const { fileName } = documentDetail ?? {};
  const [zoom, setZoom] = useState(1);

  const openFile = () => {
    if (documentDetail?.link) {
      openLink(documentDetail?.link, true);
    } else if (documentDetail?.file) {
      openStaticFile(documentDetail?.file);
    }
  };

  if (!documentDetail) return;

  return (
    <div
      className={
        "pdf__viewer" +
        (type === "view" ? " pdf__viewer--view" : "") +
        (type === "draw" ? " pdf__viewer--draw" : "")
      }
    >
      <div className="pdf__header">
        <div className="pdf__meta">
          <div className="pdf__icon"></div>
          <h3 className="pdf__title">{fileName ?? ""}</h3>
        </div>
        <div className="pdf__action-list">
          <Zoom zoom={zoom} setZoom={setZoom} />

          <div className="pdf__action pdf__action--open">
            <Button
              type="button"
              id="open-btn"
              key="open-btn"
              label=""
              icon={<OpenFileIcon />}
              isLoading={false}
              isDisable={false}
              onClick={openFile}
            />
          </div>

          {type === "view" && (
            <div className="pdf__action pdf__action--close">
              <Button
                type="button"
                id="close-btn"
                key="close-button"
                label=""
                icon={<CloseIcon />}
                isLoading={false}
                isDisable={false}
                onClick={onClose ?? null}
              />
            </div>
          )}
        </div>
      </div>

      <div className="pdf__content">
        <Pdf
          type={type}
          pdfViewerId={pdfViewerId}
          documentDetail={documentDetail}
          config={config}
          zoom={zoom}
          activeRegion={activeRegion}
          activeArea={activeArea}
          getRegionIndex={getRegionIndex}
          setRegionCoordinate={setRegionCoordinate}
        />
      </div>
    </div>
  );
};

export default PdfViewer;
