export const saleCardConfig = {
  attributeList: {
    companySaleId: {
      label: "Company sale ID",
      defaultValue: "#-----",
    },
    customerSaleId: {
      label: "Customer sale ID",
      defaultValue: "New sale",
    },
    customerCode: {
      label: "Customer code",
      defaultValue: "Unavailable",
    },
    customerName: {
      label: "Customer name",
      defaultValue: "Unavailable",
    },
    saleSource: {
      label: "Source of sale",
      defaultValue: "Unavailable",
    },
    saleType: {
      label: "Type of sale",
      defaultValue: "Unavailable",
    },
    createdByName: {
      label: "Created by",
      defaultValue: "Unavailable",
    },
    createdOn: {
      label: "Created on",
      defaultValue: "Unavailable",
    },
    lastModifiedOn: {
      label: "Last modified on",
      defaultValue: "Not modified yet",
    },
  },
  order: [
    "companySaleId",
    "customerSaleId",
    "customerCode",
    "customerName",
    "saleSource",
    "saleType",
    "createdByName",
    "createdOn",
    "lastModifiedOn",
  ],
};
