import Button from "../Button";

const Confirmation = ({ config }) => {
  const { icon, title, info, actionList } = config ?? {};
  const { proceed, cancel } = actionList ?? {};

  const renderAction = (action) => {
    return (
      <Button
        type={action.type ?? "button"}
        label={action.label ?? ""}
        id={action.id ?? "button"}
        style={action.style ?? "primary"}
        size={action.size ?? "medium"}
        theme={action.theme ?? ""}
        isLoading={action.isLoading ?? false}
        onClick={action.onClick}
      />
    );
  };

  return (
    <div className="confirmation">
      {icon && (
        <div className="confirmation__icon">
          <img src={icon} alt={title} />
        </div>
      )}

      <h1 className="confirmation__title">{title ?? ""}</h1>
      <article className="confirmation__info">{info ?? ""}</article>

      <div className="confirmation__action">
        {cancel && renderAction(cancel)}
        {proceed && renderAction(proceed)}
      </div>
    </div>
  );
};

export default Confirmation;
