const Card: React.FC<{
  isActive?: boolean;
  children;
}> = ({ isActive, children }) => {
  return (
    <div className={"card" + (isActive ? " card--active" : "")}>{children}</div>
  );
};

export default Card;
