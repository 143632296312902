import Region from "../../Template/LabelSystem/Region/Region";

const Page = ({
  type,
  pdfViewerId,
  index,
  config,
  activeRegion,
  activeArea,
  getRegionIndex,
  setRegionCoordinate,
}) => {
  return (
    <div className="pdf__page" id={`pdf_${pdfViewerId}_page_${index}`}>
      {type === "draw" && (
        <Region
          pageIndex={index}
          config={config}
          activeRegion={activeRegion}
          activeArea={activeArea}
          getRegionIndex={getRegionIndex}
          setRegionCoordinate={setRegionCoordinate}
        />
      )}
    </div>
  );
};

export default Page;
