const ViewFileIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="#9797B8"
      xmlns="http://www.w3.org/2000/svg"
      className="view-file-icon"
    >
      <path d="M0.356653 0.0335441C0.234817 0.0716178 0.0698314 0.244218 0.0317577 0.37113C-0.00885412 0.508195 -0.0113924 3.26473 0.0292195 3.40941C0.0444489 3.46271 0.0952138 3.55155 0.14344 3.60485C0.392188 3.87898 0.833842 3.82568 1.0166 3.50332C1.08005 3.3891 1.08005 3.38656 1.08767 2.63017L1.09528 1.87123L2.82636 3.59724C3.99395 4.76229 4.59044 5.33847 4.65643 5.36893C4.79857 5.43238 5.03463 5.41462 5.16154 5.32578C5.31638 5.22171 5.39252 5.08972 5.40268 4.90951C5.41029 4.79782 5.40014 4.72675 5.36968 4.65568C5.33922 4.58969 4.76304 3.9932 3.59799 2.82561L1.87198 1.09453L2.63092 1.08691C3.38731 1.0793 3.38985 1.0793 3.50407 1.01584C3.82643 0.83309 3.87973 0.391436 3.6056 0.142689C3.5523 0.0944619 3.46346 0.0436971 3.41016 0.0284677C3.27309 -0.00960597 0.478488 -0.00452949 0.356653 0.0335441Z" />
      <path d="M9.56121 0.0400336C9.24647 0.151716 9.11448 0.570526 9.30738 0.847194C9.35053 0.905574 9.4343 0.981721 9.49521 1.01726C9.60943 1.08071 9.61197 1.08071 10.3684 1.08833L11.1273 1.09594L9.4013 2.82702C8.23625 3.99461 7.66007 4.5911 7.62961 4.6571C7.56615 4.79924 7.58392 5.03529 7.67276 5.16221C7.77682 5.31704 7.90881 5.39319 8.08903 5.40334C8.20071 5.41095 8.27178 5.4008 8.34285 5.37034C8.40885 5.33988 9.00533 4.7637 10.1729 3.59865L11.904 1.87264L11.9116 2.63158C11.9192 3.38797 11.9192 3.39051 11.9827 3.50473C12.1654 3.82709 12.6071 3.88039 12.8558 3.60626C12.9041 3.55296 12.9548 3.46412 12.9701 3.41082C13.0107 3.26614 13.0081 0.509608 12.9675 0.372543C12.9269 0.238016 12.7619 0.0730307 12.6274 0.0324189C12.4675 -0.0158077 9.69827 -0.00819302 9.56121 0.0400336Z" />
      <path d="M4.64628 7.63139C4.59552 7.65678 3.80105 8.4284 2.82636 9.40055L1.09528 11.1266L1.08767 10.3676C1.08005 9.61122 1.08005 9.60868 1.0166 9.49446C0.833842 9.17211 0.392188 9.1188 0.14344 9.39293C0.0952138 9.44624 0.044449 9.53507 0.0292195 9.58838C-0.0113924 9.73306 -0.00885412 12.4896 0.0317577 12.6267C0.0723696 12.7612 0.237355 12.9262 0.371882 12.9668C0.508947 13.0074 3.26548 13.0099 3.41016 12.9693C3.46346 12.9541 3.5523 12.9033 3.6056 12.8551C3.87973 12.6063 3.82643 12.1647 3.50407 11.9819C3.38985 11.9185 3.38731 11.9185 2.63092 11.9109L1.87198 11.9033L3.59799 10.1722C4.76304 9.00458 5.33922 8.40809 5.36968 8.3421C5.43314 8.19996 5.41537 7.9639 5.32653 7.83699C5.21739 7.67708 5.09301 7.60601 4.9001 7.59586C4.78335 7.59078 4.70974 7.60093 4.64628 7.63139Z" />
      <path d="M7.90247 7.63393C7.79079 7.68723 7.68672 7.79384 7.63342 7.90298C7.58011 8.01721 7.58011 8.23042 7.63595 8.34718C7.66134 8.40809 8.30351 9.07058 9.40257 10.1722L11.1286 11.9033L10.3696 11.9109C9.61324 11.9185 9.61071 11.9185 9.49648 11.9819C9.17413 12.1647 9.12083 12.6063 9.39496 12.8551C9.44826 12.9033 9.5371 12.9541 9.5904 12.9693C9.73508 13.0099 12.4916 13.0074 12.6287 12.9668C12.7632 12.9262 12.9282 12.7612 12.9688 12.6267C13.0094 12.4896 13.0119 9.73306 12.9713 9.58838C12.9561 9.53507 12.9053 9.44624 12.8571 9.39293C12.6084 9.1188 12.1667 9.17211 11.984 9.49446C11.9205 9.60868 11.9205 9.61122 11.9129 10.3676L11.9053 11.1266L10.1742 9.40055C9.0726 8.30149 8.41012 7.65931 8.3492 7.63393C8.23244 7.57809 8.01161 7.58063 7.90247 7.63393Z" />
    </svg>
  );
};

export default ViewFileIcon;
