import { useEffect } from "react";

type Event = KeyboardEvent;

const useOnKeyPress = (list: any, isBlock?: boolean) => {
  useEffect(() => {
    if (isBlock) return;

    const listener = (event: Event) => {
      for (const key in list) {
        if (event.key === key) {
          event.preventDefault();
          list[key](event);
        }
      }
    };

    document?.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [list]);
};

export default useOnKeyPress;
