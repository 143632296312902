export const formConfig = {
  fieldList: {
    flavor: {
      type: "dropdownChoose",
      key: "flavor",
      id: "flavor",
      name: "flavor",
      label: "Flavor",
      list: [
        { label: "Stream", value: "stream" },
        { label: "Lattice", value: "lattice" },
      ],
      isListOpen: false,
      validation: {
        required: "Flavor is required",
      },
    },
    splitText: {
      type: "dropdownChoose",
      key: "splitText",
      id: "splitText",
      name: "splitText",
      label: "Split text",
      list: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      isListOpen: false,
      validation: {
        required: "Split text is required",
      },
    },
    flagSize: {
      type: "dropdownChoose",
      key: "flagSize",
      id: "flagSize",
      name: "flagSize",
      label: "Flag size",
      list: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      isListOpen: false,
      validation: {
        required: "Flag size is required",
      },
    },
    repeatForRemainingPages: {
      type: "dropdownChoose",
      key: "repeatForRemainingPages",
      id: "repeatForRemainingPages",
      name: "repeatForRemainingPages",
      label: "Repeat for remaining pages",
      list: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      isListOpen: false,
      validation: {
        required: "Repeat for remaining pages is required",
      },
    },
    processBackground: {
      type: "dropdownChoose",
      key: "processBackground",
      id: "processBackground",
      name: "processBackground",
      label: "Process background",
      list: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      isListOpen: false,
      validation: {
        required: "Process background is required",
      },
    },
    lineScale: {
      type: "number",
      key: "lineScale",
      id: "lineScale",
      name: "lineScale",
      label: "Line scale",
      validation: {
        required: "Line scale is required",
      },
    },
    rowTolerance: {
      type: "number",
      key: "rowTolerance",
      id: "rowTolerance",
      name: "rowTolerance",
      label: "Row tolerance",
      validation: {
        required: "Row tolerance is required",
      },
    },
  },
};
