export const getListMessage = (
  type,
  isListLoading,
  skip,
  limit,
  resultTotal,
  isListErr,
  searchTotal,
  searchTerm
) => {
  return isListLoading
    ? skip > 0 && limit
      ? `Fetching ${resultTotal} to ${resultTotal + limit} more ${type}s...`
      : `Fetching the list of ${type}s...`
    : isListErr
    ? `Failed to fetch ${resultTotal} to ${
        resultTotal + limit
      } ${type}s. Retry by scrolling again`
    : searchTotal === 0
    ? !searchTerm
      ? `You have no ${type}s`
      : `No matches found for ${type}s` +
        (searchTerm ? ` matching the ${type} search '${searchTerm}'` : "")
    : resultTotal === searchTotal
    ? `Showing all ${searchTotal} ${type}${resultTotal > 1 ? "s" : ""}` +
      (searchTerm ? ` matching the ${type} search '${searchTerm}'` : "")
    : `Showing ${resultTotal} ${type}${
        resultTotal > 1 ? "s" : ""
      } out of a total of ${searchTotal}` +
      (searchTerm ? ` matching the ${type} search for '${searchTerm}'` : "");
};
