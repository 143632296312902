import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { EmailListResponse } from "../../Types/email";

const initialState: {
  list: EmailListResponse;
} = {
  list: {
    result: null,
    inboxTotal: 0,
    markLaterTotal: 0,
    resultTotal: 0,
    searchTotal: 0,
    active: null,
  },
};

export const emailSlicer = createSlice({
  name: "email",
  initialState,
  reducers: {
    setEmailList: (state, action: PayloadAction<any>) => {
      if (action.payload) state.list = action.payload;
    },
    clearEmailList: (state) => {
      state.list = initialState.list;
    },
    updateEmailList: (state, action: PayloadAction<any>) => {
      if (action.payload?.id && state.list?.result) {
        const index = state.list.result.findIndex(
          (email) => email.id === action.payload.id
        );

        if (index !== -1) {
          state.list.result[index] = {
            ...state.list.result[index],
            ...action.payload,
          };

          state.list.active = action.payload;
        }
      }
    },
    deleteFromEmailList: (state, action: PayloadAction<any>) => {
      const { activeTab, emailId } = action.payload ?? {};

      if (emailId && state.list?.result) {
        state.list = {
          ...state.list,
          result: state.list.result.filter((item) => item.id !== emailId),
          resultTotal: state.list.resultTotal - 1,
          ...(activeTab === "inbox"
            ? {
                inboxTotal: state.list.inboxTotal - 1,
                markLaterTotal: state.list.markLaterTotal + 1,
              }
            : activeTab === "markForLater"
            ? {
                inboxTotal: state.list.inboxTotal + 1,
                markLaterTotal: state.list.markLaterTotal - 1,
              }
            : {}),
        };
      }
    },
    setActiveEmail: (state, action: PayloadAction<any>) => {
      if (state?.list) state.list.active = action.payload;
    },
  },
});

export const {
  setEmailList,
  clearEmailList,
  updateEmailList,
  deleteFromEmailList,
  setActiveEmail,
} = emailSlicer.actions;

export default emailSlicer.reducer;
