import { useDispatch } from "react-redux";

import CloseIcon from "../../../../../Assets/Reusable/CloseIcon";

import { setActiveArea } from "../../../../../Redux/Template/parserSlicer";

import Resize from "./Resize/Resize";

const RegionCard = ({
  id,
  area,
  pageIndex,
  activeRegion,
  activeArea,
  getRegionIndex,
  getRegion,
  setRegionCoordinate,
  setResize,
}) => {
  const dispatch = useDispatch();

  const { coordinate } = area;
  if (!coordinate) return;

  const { x0, x1, y0, y1 } = coordinate;
  const width = x1 - x0;
  const height = y1 - y0;
  const label = `${getRegion()?.label} - ${area?.label}`;

  if (!width || !height) return;

  const onRemove = () => {
    setRegionCoordinate(activeRegion, id, null, pageIndex);
  };

  const onAreaSelect = () => {
    dispatch(setActiveArea(id));
  };

  return (
    <div
      className={
        "region__card" +
        ` region__card--${getRegionIndex(activeRegion) + 1}` +
        (activeArea === id ? " region__card--active" : "")
      }
      style={{
        left: `${x0 * 100 ?? 0}%`,
        top: `${y0 * 100 ?? 0}%`,
        width: `${width * 100 ?? 0}%`,
        height: `${height * 100 ?? 0}%`,
      }}
      onClick={onAreaSelect}
    >
      <div className="region__label">
        <span className="region__text">{label}</span>
        <div className="region__remove" onClick={onRemove}>
          <CloseIcon />
        </div>
      </div>

      <Resize areaId={id} setResize={setResize} onAreaSelect={onAreaSelect} />
    </div>
  );
};

export default RegionCard;
