export const setting = {
  labellingSetting: {
    flavor: "stream",
    splitText: true,
    flagSize: true,
    repeatForRemainingPages: true,
    processBackground: true,
    lineScale: 15,
    rowTolerance: 10,
  },
  validationSetting: null,
};

export const areaConfig = {
  area: {
    content: {
      label: "Content",
      isMandatory: true,
    },
    left: {
      label: "Left",
      isMandatory: false,
    },
    right: {
      label: "Right",
      isMandatory: false,
    },
    top: {
      label: "Top",
      isMandatory: false,
    },
    bottom: {
      label: "Bottom",
      isMandatory: false,
    },
  },
  order: ["content", "left", "right", "top", "bottom"],
};

export const headerMapOptionList = [
  { key: "code", label: "Code", defaultValue: "" },
  { key: "description", label: "Description", defaultValue: "" },
  { key: "quantity", label: "Quantity", defaultValue: 0 },
  { key: "unit_price", label: "Unit price", defaultValue: 0 },
  {
    key: "company_product_code",
    label: "Company product code",
    default_value: "",
  },
  {
    key: "company_product_description",
    label: "Company product description",
    default_value: "",
  },
];

export const headerMapCellConfig = {
  cellType: "dropdown_with_choose",
  filter: "header_map",
  isEditable: true,
  type: "text",
};
