import AddIcon from "../../../../Assets/Reusable/AddIcon";
import MinusIcon from "../../../../Assets/Reusable/MinusIcon";

import Button from "../Button";

const Zoom: React.FC<{ zoom; setZoom }> = ({ zoom, setZoom }) => {
  const onZoom = (type) => {
    let updatedZoom = zoom;
    const max = 3,
      min = 0.1,
      step = 0.1;

    if (type === "in" && zoom + step <= max) {
      updatedZoom = zoom + step;
    } else if (type === "out" && zoom - step >= min) {
      updatedZoom = zoom - step;
    }

    setZoom(Math.round(updatedZoom * 100) / 100);
  };

  return (
    <div className="pdf__zoom">
      <div className="pdf__action pdf__action--zoom-out">
        <Button
          type="button"
          id="zoom-out-btn"
          key="zoom-out-btn"
          label=""
          icon={<MinusIcon />}
          isLoading={false}
          isDisable={false}
          onClick={() => onZoom("out")}
        />
      </div>
      <span className="pdf__zoom-value">{Math.round(zoom * 100)}%</span>

      <div className="pdf__action pdf__action--zoom-in">
        <Button
          type="button"
          id="zoom-in-btn"
          key="zoom-in-btn"
          label=""
          icon={<AddIcon />}
          isLoading={false}
          isDisable={false}
          onClick={() => onZoom("in")}
        />
      </div>
    </div>
  );
};

export default Zoom;
