import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../Types/button";
import { Company } from "../../../Types/company";
import { Template } from "../../../Types/template";

import { formatDate } from "../../../Utils/formatDate";
import { validateRegion } from "../../../Utils/validateCoordinate";

import { RootState } from "../../../Redux/store";
import {
  setTemplateDetail,
  setTemplateDocument,
} from "../../../Redux/Template/templateSlicer";
import {
  setActiveArea,
  setActiveRegion,
  setRegionConfig,
} from "../../../Redux/Template/parserSlicer";

import { getTemplateDetail as getTemplateDetailAPI } from "../../../APIs/Template/getTemplateDetail";

import Dashboard from "../Dashboard/Dashboard";
import TemplateMeta from "./TemplateMeta";
import LabelSystem from "./LabelSystem/LabelSystem";
import TemplateSave from "./TemplateSave";

const TemplateDetail = () => {
  const { tid: templateId } = useParams();
  const dispatch = useDispatch();

  const [isSaveTemplateDisable, setIsSaveTemplateDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveConfirm, setIsSaveConfirm] = useState(false);

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const { regionConfig } = useSelector((state: RootState) => state.parser);

  const templateDetail: Template = useSelector(
    (state: RootState) => state.template.detail
  );

  const [searchParam, setSearchParam] = useSearchParams();
  const customerId = searchParam?.get("customer");

  useEffect(() => {
    if (company?.id && customerId && templateId)
      getTemplateDetail(company.id, customerId, templateId);
  }, [company, customerId, templateId]);

  useEffect(() => {
    const regionList = regionConfig?.region;

    if (regionList) {
      let isEnable = true;

      regionList &&
        Object.keys(regionList)?.map((key) => {
          const region = regionList[key];
          const headerMap = region?.validationSetting?.headerMapping ?? null;
          const isHeaderMap =
            headerMap && Object.keys(headerMap).length > 0 ? true : false;

          if (
            templateDetail?.status === "save" ||
            !(region?.isMandatory
              ? validateRegion(region)
                ? region.regionType === "table"
                  ? isHeaderMap
                  : true
                : false
              : true)
          ) {
            isEnable = false;
            return;
          }
        });

      setIsSaveTemplateDisable(!isEnable);
    }
  }, [templateDetail?.status, JSON.stringify(regionConfig)]);

  useEffect(() => {
    return () => {
      dispatch(setTemplateDetail(null));
      dispatch(setTemplateDocument(null));
      dispatch(setRegionConfig(null));
      dispatch(setActiveRegion(null));
      dispatch(setActiveArea(null));
    };
  }, []);

  const getTemplateDetail = (companyId, customerId, templateId) => {
    const params = { customerId: customerId };

    getTemplateDetailAPI(companyId, templateId, params)
      .then((res: any) => {
        res.createdOn = formatDate(res.createdOn, "long") ?? null;
        res.lastModifiedOn = formatDate(res.lastModifiedOn, "long") ?? null;

        if (res) dispatch(setTemplateDetail(res));
      })
      .catch(() => {
        dispatch(setTemplateDetail(null));
      });
  };

  const actionConfig: Button[] =
    !templateDetail || templateDetail?.status === "save"
      ? null
      : [
          {
            type: "submit",
            label: "Save template",
            id: "saveTemplate",
            style: "primary",
            size: "medium",
            isLoading: isLoading,
            isDisable: isSaveTemplateDisable,
            onClick: () => {
              setIsSaveConfirm(true);
            },
          },
        ];

  const headerConfig = {
    breadcrumbList: [
      {
        label: "Template details",
        children: {
          label: templateDetail?.name ?? "",
          onClick: null,
          isEditable: true,
          link: `/template/${templateDetail?.id}`,
        },
      },
      ,
    ],
  };

  const headerInfo =
    templateDetail?.status === "save"
      ? {
          defaultValue: "Unavailable",
          key: "lastModifiedOn",
          label: "Template saved on",
          value: formatDate(templateDetail?.lastModifiedOn, "long") ?? null,
        }
      : null;

  return (
    <Dashboard
      actionConfig={actionConfig}
      headerInfo={headerInfo}
      headerConfig={headerConfig}
    >
      <div className="template-detail h-100-perc scroll">
        <TemplateMeta company={company} templateDetail={templateDetail} />

        <LabelSystem />

        {isSaveConfirm && (
          <TemplateSave
            company={company}
            templateDetail={templateDetail}
            isSaveConfirm={isSaveConfirm}
            setIsSaveConfirm={setIsSaveConfirm}
          />
        )}
      </div>
    </Dashboard>
  );
};

export default TemplateDetail;
