import { useEffect } from "react";
import confetti from "canvas-confetti";

const Confetti = () => {
  useEffect(() => {
    var count = 75;
    var defaults = {
      origin: { y: 1 },
    };

    function fire(particleRatio, opts) {
      const canvas: any = document.querySelector("#confetti-canvas");
      canvas.confetti =
        canvas?.confetti || confetti.create(canvas, { resize: true });

      canvas.confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    fire(0.2, {
      spread: 60,
    });

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });

    fire(0.5, {
      spread: 150,
      startVelocity: 80,
    });
  }, []);

  return <canvas id="confetti-canvas" />;
};

export default Confetti;
