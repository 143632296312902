export const templateCardConfig = {
  attributeList: {
    name: {
      label: "Template name",
      defaultValue: "Unnamed template",
    },
    customerCode: {
      label: "Customer code",
      defaultValue: "Unavailable",
    },
    customerName: {
      label: "Customer name",
      defaultValue: "Unavailable",
    },
    createdByName: {
      label: "Created by",
      defaultValue: "Unavailable",
    },
    createdOn: {
      label: "Created on",
      defaultValue: "Unavailable",
    },
    lastModifiedOn: {
      label: "Last modified on",
      defaultValue: "Not yet modified",
    },
  },
  order: [
    "name",
    "customerCode",
    "customerName",
    "createdByName",
    "createdOn",
    "lastModifiedOn",
  ],
};
