import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { Product, ProductListResponse } from "../../Types/product";

const initialState: {
  list: ProductListResponse;
  detail: Product;
} = {
  list: {
    result: null,
    total: 0,
    resultTotal: 0,
    searchTotal: 0,
  },
  detail: undefined,
};

export const productSlicer = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductList: (state, action: PayloadAction<any>) => {
      if (action.payload) state.list = action.payload;
    },
    setProductDetail: (state, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
    clearProductList: (state) => {
      state.list = initialState.list;
    },
    clearProductDetail: (state) => {
      state.list = undefined;
    },
  },
});

export const {
  setProductList,
  setProductDetail,
  clearProductList,
  clearProductDetail,
} = productSlicer.actions;

export default productSlicer.reducer;
