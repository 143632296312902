import { useState, useEffect, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../Redux/store";
import {
  setUserList,
  clearUserList,
  addToUserList,
  setActiveUser,
} from "../../../Redux/User/userSlicer";

import useOnScroll from "../../../Hooks/useOnScroll";

import { Company } from "../../../Types/company";
import { User as UserType, UserListResponse } from "../../../Types/user";
import { Button } from "../../../Types/button";

import { getListMessage } from "../../../Utils/listMessage";

import { getUserList as getUserListAPI } from "../../../APIs/User/getUserList";
import { streamUpdate } from "../../../APIs/Reusable/streamUpdate";

import Dashboard from "../Dashboard/Dashboard";
import UserList from "./UserList";
import CreateUserModal from "./CreateUser/CreateUserModal";

const User = () => {
  const userRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const controller = new AbortController();
  const signal = controller.signal;

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const user: UserType = useSelector((state: RootState) => state.user.user);

  const { result, total, resultTotal, searchTotal }: UserListResponse =
    useSelector((state: RootState) => state.user.list);

  const [searchParam, setSearchParam] = useSearchParams();
  const modalPath = searchParam?.get("modal");
  const searchTerm = searchParam?.get("q");

  const [isListLoading, setIsListLoading] = useState<boolean>(true);

  const defaultLimit = 100;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isListErr, setIsListErr] = useState(false);

  useOnScroll(userRef);

  useEffect(() => {
    if (company?.id) {
      setSkip(0);
      setLimit(defaultLimit);
      getUserList(company.id, true);
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading) {
      setSkip(0);
      dispatch(clearUserList());
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading && skip > 0 && limit > 0 && !isListErr) {
      getUserList(company.id, false);
    }
  }, [company, skip, isListErr]);

  useEffect(() => {
    if (company?.id) {
      streamUserList();
    }

    return () => {
      controller.abort();
    };
  }, [company]);

  useEffect(() => {
    return () => {
      dispatch(clearUserList());
    };
  }, []);

  useEffect(() => {
    const elem = userRef?.current ?? null;

    if (elem) {
      elem.addEventListener("scroll", onScroll);

      return () => {
        elem.removeEventListener("scroll", onScroll);
      };
    }
  }, [result, isListErr]);

  const onScroll = () => {
    const elem: any = userRef?.current ?? null;
    const offset = 10;

    if (
      elem &&
      elem?.scrollTop + elem?.clientHeight + offset >= elem?.scrollHeight
    ) {
      if (company?.id && isListErr) {
        setIsListErr(false);
      } else {
        setLimit(
          searchTotal <= resultTotal + defaultLimit &&
            searchTotal - resultTotal > 0
            ? searchTotal - resultTotal
            : defaultLimit
        );

        if (searchTotal - resultTotal > 0) {
          setSkip(resultTotal);
        }
      }
    }
  };

  const createUser = () => {
    const params = { modal: "create", target: "user" };
    setSearchParam(createSearchParams(params));
  };

  const getUserList = (companyId, isReset) => {
    setIsListLoading(true);
    const params = {
      skip: isReset ? 0 : skip,
      limit: isReset ? defaultLimit : limit,
      searchTerm,
    };

    getUserListAPI(companyId, params)
      .then((res: any) => {
        if (res) {
          if (!isReset && result?.length) {
            res.result = [...result, ...res.result];
            res.resultTotal += resultTotal;
          }

          dispatch(setUserList(res));
          setIsListErr(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsListErr(true);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const streamUserList = () => {
    streamUpdate(
      company?.id,
      "users",
      signal,
      onOpen,
      onMessage,
      onClose,
      onError
    );
  };

  const onOpen = () => {
    console.log("Stream update opened:");
  };

  const onMessage = (e) => {
    if (e?.event && e?.data) {
      switch (e?.event) {
        case "create":
          dispatch(addToUserList(e?.data));

          setTimeout(() => {
            dispatch(setActiveUser(null));
          }, 3000);
          break;

        default:
          break;
      }
    }
  };

  const onClose = () => {
    console.log("Stream update closed");
  };

  const onError = (err) => {
    console.log("Stream update error:", err);
  };

  const actionConfig: Button[] = [
    {
      type: "submit",
      label: "Create user",
      id: "createUser",
      style: "primary",
      size: "medium",
      onClick: createUser,
    },
  ];

  const isCreateAllowed =
    user?.userRole === ("ADMIN_USER" as UserType["userRole"]);

  const onChange = (key, value) => {
    if (value) {
      setSearchParam(createSearchParams({ q: value }));
    } else {
      searchParam.delete("q");
      setSearchParam(searchParam);
    }
  };

  const message = getListMessage(
    "customer",
    isListLoading,
    skip,
    limit,
    resultTotal,
    isListErr,
    searchTotal,
    searchTerm
  );

  const headerConfig = {
    tabList: [
      {
        label: "Users",
        count: total ?? 0,
      },
    ],
    information: {
      message: message,
      isLoading: isListLoading,
    },
    filterList: {
      templateSearch: {
        type: "text",
        key: "templateSearch",
        id: "templateSearch",
        name: "templateSearch",
        label: "Search",
        query: "s",
        defaultValue: searchTerm,
        placeholder: "Search using user details",
        onChange: onChange,
      },
    },
  };

  return (
    <Dashboard
      actionConfig={isCreateAllowed ? actionConfig : null}
      headerConfig={headerConfig}
    >
      <div className="user h-100-perc scroll" ref={userRef}>
        <UserList userList={result} searchTerm={searchTerm} />
      </div>

      {modalPath && <CreateUserModal />}
    </Dashboard>
  );
};

export default User;
