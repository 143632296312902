import { useState, useEffect } from "react";

import { productDetailConfig } from "./productDetailConfig";

import { snakeToCamel } from "../../../Utils";

import { getProductConfig as getProductConfigAPI } from "../../../APIs/Product/getProductConfig";

import Section from "../Reusable/Section";

const ProductMeta = ({ companyId, productDetail }) => {
  const defaultAdditionalDetailConfig = {
    label: "Company specific data",
    attributeList: {},
    order: [],
  };

  const [productAdditionalDetailConfig, setProductAdditionalDetailConfig] =
    useState(null);

  const [additionalDetailConfig, setAdditionalDetailConfig] = useState(
    defaultAdditionalDetailConfig
  );

  useEffect(() => {
    if (productDetail?.companySpecificData) {
      getProductConfig();
    }
  }, [productDetail?.companySpecificData]);

  useEffect(() => {
    const data = productDetail?.companySpecificData ?? null;

    if (data && productAdditionalDetailConfig) {
      productAdditionalDetailConfig.map((item) => {
        const label = item?.label ?? "";
        const key = snakeToCamel(item?.key) ?? "";

        if (key && label) {
          defaultAdditionalDetailConfig.attributeList[key] = {
            key,
            label,
            value: data?.[key],
            defaultValue: "Unavailable",
          };

          defaultAdditionalDetailConfig.order.push(key);
        }
      });

      setAdditionalDetailConfig(defaultAdditionalDetailConfig);
    }
  }, [productAdditionalDetailConfig]);

  const getProductConfig = () => {
    getProductConfigAPI(companyId)
      .then((res: any) => {
        if (res) setProductAdditionalDetailConfig(res?.additionalFields);
      })
      .catch(() => {
        setProductAdditionalDetailConfig(null);
      });
  };

  return (
    <div className="product-detail__meta">
      <Section config={productDetailConfig} data={productDetail} />
      <Section
        config={additionalDetailConfig}
        data={productDetail?.companySpecificData}
      />
    </div>
  );
};

export default ProductMeta;
