const CloseIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 12"
      xmlns="http://www.w3.org/2000/svg"
      className="close-icon"
      fill="#9797B8"
      stroke="#9797b8"
      strokeWidth="0.5"
    >
      <path d="M9.50372 0.292023C9.47251 0.303171 8.44693 1.31315 7.22514 2.53716L5.00007 4.76L2.77723 2.53716C0.358193 0.120349 0.51203 0.26081 0.302454 0.300941C0.193207 0.321007 0.0594357 0.454779 0.0393699 0.564026C-0.00076163 0.773602 -0.141222 0.619763 2.27559 3.03657L4.49843 5.26164L2.26444 7.49563C-0.159058 9.92359 -0.0230569 9.77198 0.0148451 9.97933C0.0349108 10.0841 0.177601 10.2268 0.282389 10.2469C0.489735 10.2848 0.338127 10.4208 2.76386 7.99728L5.00007 5.76329L7.23406 7.99728C9.66425 10.4252 9.51041 10.2848 9.71999 10.2446C9.82923 10.2246 9.96301 10.0908 9.98307 9.98156C10.0232 9.77198 10.1637 9.92582 7.73571 7.49786L5.50172 5.26164L7.73571 3.02766C10.157 0.604157 10.0232 0.751306 9.9853 0.546189C9.9474 0.343302 9.70215 0.21399 9.50372 0.292023Z" />
    </svg>
  );
};

export default CloseIcon;
