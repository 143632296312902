import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { Template, TemplateListResponse } from "../../Types/template";

const initialState: {
  list: TemplateListResponse;
  detail: Template;
  document: any;
} = {
  list: {
    result: null,
    total: 0,
    resultTotal: 0,
    searchTotal: 0,
    active: null,
  },
  detail: undefined,
  document: undefined,
};

export const templateSlicer = createSlice({
  name: "template",
  initialState,
  reducers: {
    setTemplateList: (state, action: PayloadAction<any>) => {
      if (action.payload) state.list = action.payload;
    },

    setTemplateDetail: (state, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },

    setTemplateDocument: (state, action: PayloadAction<any>) => {
      state.document = action.payload;
    },

    clearTemplateList: (state) => {
      state.list = initialState.list;
    },

    addToTemplateList: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.list = {
          result: state.list?.result
            ? [action.payload, ...state.list.result]
            : [action.payload],
          total: state.list?.result ? state.list.total + 1 : 1,
          resultTotal: state.list?.result ? state.list.resultTotal + 1 : 1,
          searchTotal: state.list?.result ? state.list.searchTotal + 1 : 1,
          active: action.payload,
        };
      }
    },

    deleteFromTemplateList: (state, action: PayloadAction<any>) => {
      if (action.payload && state.list?.result) {
        state.list = {
          ...state.list,
          result: state.list.result.filter(
            (item) => item.id !== action.payload
          ),
          total: state.list.total - 1,
          resultTotal: state.list.resultTotal - 1,
          searchTotal: state.list.searchTotal - 1,
        };
      }
    },

    setActiveTemplate: (state, action: PayloadAction<any>) => {
      if (state?.list) state.list.active = action.payload;
    },
  },
});

export const {
  setTemplateList,
  setTemplateDetail,
  setTemplateDocument,

  clearTemplateList,

  addToTemplateList,
  deleteFromTemplateList,
  setActiveTemplate,
} = templateSlicer.actions;

export default templateSlicer.reducer;
