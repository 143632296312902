import React, { useState, useEffect, useRef } from "react";

import { DropdownType } from "../../../../../../Types/dropdown";

import useOnClickOutside from "../../../../../../Hooks/useOnClickOutside";
import useOnKeyPress from "../../../../../../Hooks/useOnKeyPress";
import useOnScroll from "../../../../../../Hooks/useOnScroll";
import { usePortal } from "../../../../../../Hooks/usePortal";

import OptionList from "./OptionList";

const Dropdown: React.FC<DropdownType> = ({
  type,
  id,
  name,
  label,
  offset,
  hasScroll = true,
  rule,
  list,
  isListOpen,
  setIsListOpen,
  isDisableOutsideClick,
  scrollElem,
  onSelect,
  ...props
}) => {
  const [position, setPosition] = useState<any>();
  const [optionIndex, setOptionIndex] = useState<number>(0);

  const dropdownRef = useRef<HTMLInputElement>(null);
  const dropdownListRef = useRef<HTMLInputElement>(null);

  const Portal = usePortal({ elem: "dropdown", id: id, show: isListOpen });

  useEffect(() => {
    getCoordinate();
    scrollElem && scrollElem.addEventListener("scroll", handleScroll);

    return () => {
      scrollElem && scrollElem.removeEventListener("scroll", handleScroll);
    };
  }, [scrollElem, isListOpen]);

  useEffect(() => {
    if (isListOpen) setOptionIndex(0);

    setTimeout(() => {
      const elem: any = document.querySelector(`#${id}-search`);
      elem?.focus();
    }, 0);
  }, [isListOpen]);

  useOnClickOutside(dropdownRef, () => {
    if (!isDisableOutsideClick && isListOpen) setIsListOpen(false);
  });

  useOnScroll(dropdownListRef);

  const onClick = (item) => {
    if (!item?.isDisabled) {
      onSelect(id, item);
      setIsListOpen(false);
    }
  };

  const handleScroll = () => {
    setIsListOpen(undefined);
  };

  const getCoordinate = () => {
    if (dropdownRef?.current)
      setPosition(dropdownRef.current.getBoundingClientRect());
  };

  const handleArrowUp = () => {
    if (optionIndex > 0) setOptionIndex((optionIndex) => optionIndex - 1);
  };

  const handleArrowDown = () => {
    if (optionIndex < list?.length - 1)
      setOptionIndex((optionIndex) => optionIndex + 1);
  };

  const handleKeyEnter = () => {
    onClick(list?.[optionIndex]);
  };

  useOnKeyPress({
    ArrowUp: handleArrowUp,
    ArrowDown: handleArrowDown,
    Enter: handleKeyEnter,
  });

  useEffect(() => {
    document.querySelector(".dropdown__option--active")?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }, [optionIndex]);

  const customOffset =
    name === "tool-action" ? dropdownRef?.current?.clientHeight : 0;

  const portalPosition = {
    top: `${
      position?.top +
      dropdownRef?.current?.clientHeight +
      (offset?.top ?? 0) -
      customOffset / 2
    }px`,
    left: `${position?.left + (offset?.left ?? 0)}px`,
    width: `${position?.width}px`,
  };

  return (
    <div
      className={
        "dropdown" + (type === "dropdownSearch" ? " dropdown--search" : "")
      }
      ref={dropdownRef}
    >
      {props.children}

      {isListOpen && (
        <Portal>
          <div className="dropdown__list" style={portalPosition}>
            <div
              className={
                "dropdown__scroll" +
                (hasScroll ? " scroll" : " dropdown__scroll--no-scroll")
              }
              ref={dropdownListRef}
            >
              {
                <OptionList
                  list={list}
                  label={label}
                  optionIndex={optionIndex}
                  setOptionIndex={setOptionIndex}
                  onClick={onClick}
                />
              }
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Dropdown;
