import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../Redux/store";
import { clearPdfDetail } from "../../../../Redux/Pdf/pdfSlicer";

import { DocumentDetail } from "../../../../Types/document";

import Modal from "../Modal/Modal";
import PdfViewer from "./PdfViewer";

const PdfModal: React.FC<{ type; pdfViewerId }> = ({ type, pdfViewerId }) => {
  const dispatch = useDispatch();

  const documentDetail: DocumentDetail = useSelector(
    (state: RootState) => state.pdf.detail
  );

  const onClose = () => {
    dispatch(clearPdfDetail());
  };

  if (!documentDetail) return;

  return (
    <Modal id="pdf-viewer" onClose={onClose}>
      <PdfViewer
        type={type}
        pdfViewerId={pdfViewerId}
        documentDetail={documentDetail}
        onClose={onClose}
      />
    </Modal>
  );
};

export default PdfModal;
