export const parseAttribute = (input, config) => {
  const { attributeList, order } = config ?? {};
  const output = [];

  order?.map((key) => {
    output.push({
      key: key,
      ...(attributeList?.[key] ?? {}),
      value: input?.[key] ?? null,
    });
  });

  return output;
};
