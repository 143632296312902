import axios from "axios";

export const validateTemplateName = async (companyId, params) => {
  try {
    const res = await axios.get(
      `/company/${companyId}/template/validate-name`,
      {
        params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
