import EmptyIcon from "./../../../Assets/Reusable/empty.png";

import Empty from "../Reusable/Empty";
import SaleCard from "./SaleCard";

const SaleList = ({ saleList, searchTerm }) => {
  const renderSaleList = saleList?.map((sale) => {
    if (!sale) return;

    return <SaleCard key={sale.id} sale={sale} />;
  });

  if (!saleList || saleList?.length === 0) {
    const title = searchTerm
      ? `No sale matching the customer search '${searchTerm}'`
      : "You don't have any sales!";

    return (
      <Empty
        icon={EmptyIcon}
        alt="Empty screen icon for sale"
        title={title}
        info={
          <>
            Click on the <b>Create sale</b> button in the header to create a new
            sale
          </>
        }
      />
    );
  }

  return <div className="sale__list scroll__child">{renderSaleList}</div>;
};

export default SaleList;
