const Tooltip: React.FC<{ message: string; theme?: string }> = ({
  message,
  theme,
}) => {
  return (
    <div className={"tooltip" + (theme ? " tooltip--dark" : "")}>{message}</div>
  );
};

export default Tooltip;
