import { useState, useEffect, useRef } from "react";

import Row from "./Row";
import Legend from "./Legend/Legend";
import useOnClickOutside from "../../../../../Hooks/useOnClickOutside";

const Table: React.FC<{
  source: string;
  data: any;
  order?: any;
  isSaving?: boolean;
  isValidating?: boolean;
  isEdit: boolean;
  setIsEdit: any;
  activeRowIndex?: number;
  activeCellIndex?: number;
  setActiveRowIndex?: any;
  setActiveCellIndex?: any;
  setActiveEdit?: any;
  legendData?: any;
}> = ({
  source,
  data,
  order,
  isSaving,
  isValidating,
  isEdit,
  setIsEdit,
  activeRowIndex,
  activeCellIndex,
  setActiveRowIndex,
  setActiveCellIndex,
  setActiveEdit,
  legendData,
}) => {
  const [isAllMap, setIsAllMap] = useState(null);
  const [activeRowList, setActiveRowList] = useState([]);

  const tableRef = useRef();

  useEffect(() => {
    let isAll = true;

    data?.map((row, rowIndex) => {
      if (!row.isMap && rowIndex !== 0) {
        isAll = false;
        return;
      }
    });
    setIsAllMap(isAll);
  }, [data]);

  useOnClickOutside(tableRef, () => {
    if (activeRowIndex >= 0 && activeCellIndex >= 0) {
      setActiveRowIndex(null);
      setActiveCellIndex(null);
    }
  });

  const renderRow = () => {
    if (!data) return;

    return data?.map((row, i) => {
      return (
        <Row
          key={i}
          source={source}
          isAllMap={isAllMap}
          row={row}
          rowIndex={i}
          order={order}
          isSaving={isSaving}
          isValidating={isValidating}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          activeRowIndex={activeRowIndex}
          activeCellIndex={activeCellIndex}
          setActiveRowIndex={setActiveRowIndex}
          setActiveCellIndex={setActiveCellIndex}
          rowMax={data?.length ?? 0}
          setActiveEdit={setActiveEdit}
          activeRowList={activeRowList}
          setActiveRowList={setActiveRowList}
        />
      );
    });
  };

  return (
    <div className="table" ref={tableRef}>
      <div className="table__tbody scroll">
        <div className="table__data">{renderRow()}</div>
      </div>

      <Legend legendData={legendData} />
    </div>
  );
};

export default Table;
