export const validateRegion = (control) => {
  let isCompleted = true;

  control?.area &&
    Object.keys(control.area)?.map((key) => {
      const area = control?.area[key];

      if (area?.isMandatory && !area?.coordinate) {
        isCompleted = false;
      }
    });

  return isCompleted;
};

const validateCoordinate = (config, activeRegion, activeArea) => {
  const coordinate =
    config?.region?.[activeRegion]?.area?.[activeArea]?.coordinate ?? null;

  if (!coordinate) return false;

  const width = coordinate.x1 - coordinate.x0;
  const height = coordinate.y1 - coordinate.y0;

  return width > 0 && height > 0 ? true : false;
};
