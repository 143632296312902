import { useState, useEffect, useRef } from "react";

const Attribute: React.FC<{ attribute }> = ({ attribute }) => {
  const valueRef = useRef<HTMLDivElement>(null);
  const parentValueRef = useRef<HTMLDivElement>(null);
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(false);
  const [isSeeMore, setIsSeeMore] = useState(false);

  useEffect(() => {
    if (attribute?.isSeeMore)
      setIsShowMoreVisible(
        (valueRef?.current?.offsetWidth ?? 0) >
          (parentValueRef?.current?.clientWidth ?? 0)
      );
  }, [valueRef, parentValueRef]);

  if (!attribute) return;
  const { label, value, defaultValue } = attribute;

  const isString = typeof value === "string" && value?.length;
  const isNumber = typeof value === "number";

  const val =
    value || value === 0
      ? isString || isNumber
        ? value
        : defaultValue ?? ""
      : defaultValue ?? "";

  return (
    <div className="attribute">
      {label && <div className="attribute__label label">{label}</div>}

      <div
        className={
          "attribute__value" + (isSeeMore ? " attribute__value--overflow" : "")
        }
        ref={parentValueRef}
      >
        <span ref={valueRef}>{val}</span>

        {attribute?.isSeeMore && isShowMoreVisible && (
          <span
            className="attribute__value--see-more"
            onClick={() => setIsSeeMore(!isSeeMore)}
          >
            Show {isSeeMore ? "less" : "more"}
          </span>
        )}
      </div>
    </div>
  );
};

export default Attribute;
