export const templateDetailFormConfig: any = {
  fieldList: {
    template: {
      type: "dropdownSearch",
      key: "template",
      id: "template",
      name: "template",
      label: "Template",
      validation: {
        required: "Template is required",
      },
    },
  },
};
