import React from "react";

import { Input as InputType } from "../../../../../Types/input";

const Input: React.FC<InputType> = ({
  type,
  id,
  name,
  label,
  value,
  defaultValue,
  placeholder,
  step,
  size,
  icon,
  isEditable = true,
  isValidating,
  isValid,
  isOptional,
  onClick,
  onFocus,
  errors,
  register,
}) => {
  const error = errors?.[name] || errors?.[name.replace(/-search$/, "")];

  return (
    <div
      className={
        "input" +
        (icon ? " input--with-icon" : "") +
        (error ? " input--error" : "") +
        (isEditable ? "" : " input--not-editable")
      }
    >
      <label className="input__label label">
        {label}
        <span className="input__label--optional">
          {isOptional ? " (Optional)" : ""}
        </span>
      </label>
      <input
        className="input__field"
        id={id}
        type={type}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        step={step}
        onClick={onClick}
        onFocus={(e) => (onFocus ? onFocus(id, e?.target?.value) : null)}
        {...register}
      />

      {(isValid === true || isValid === false || isValidating) && (
        <div className="input__validate">
          {isValidating && (
            <div className="input__validate-loading">
              <img />
            </div>
          )}

          {!isValidating && (
            <div
              className={
                "input__validate-icon" +
                (isValid === false
                  ? " input__validate-icon--invalid"
                  : isValid
                  ? " input__validate-icon--valid"
                  : "")
              }
            ></div>
          )}
        </div>
      )}
      {error?.message && <p className="input__error">{error.message}</p>}
    </div>
  );
};

export default Input;
