import { useDispatch } from "react-redux";

import Button from "../../Reusable/Button";
import { validateRegion } from "../../../../Utils/validateCoordinate";

const Control: React.FC<{
  label;
  type;
  config;
  activeRegion;
  activeArea;
  setActiveRegion;
  setActiveArea;
  getRegionIndex?;
}> = ({
  label,
  type,
  config,
  activeRegion,
  activeArea,
  setActiveRegion,
  setActiveArea,
  getRegionIndex,
}) => {
  const dispatch = useDispatch();

  const renderControlList = config?.order?.map((key) => {
    const control = config?.[type]?.[key];

    if (!control) return null;

    let isActive = activeRegion === key;

    let onClick = () => {
      setActiveRegion(key);
      dispatch(setActiveRegion(key));
      dispatch(setActiveArea(control?.order?.[0]));
    };

    if (type === "area") {
      isActive = activeArea === key;
      onClick = () => dispatch(setActiveArea(key));
    }

    const isCompleted =
      type === "region" ? validateRegion(control) : control?.coordinate;

    return (
      <Button
        id={key}
        key={key}
        label={control.label}
        style="label"
        isLoading={false}
        isDisable={false}
        isActive={isActive}
        isMandatory={control?.isMandatory}
        isCompleted={isCompleted}
        type={"submit"}
        onClick={onClick}
      ></Button>
    );
  });

  return (
    <div className="label-system__control">
      <div className="label-system__label label">{label}</div>

      <div
        className={
          "label-system__action-list" +
          (type ? ` label-system__action-list--${type}` : "") +
          (type === "area"
            ? ` label-system__action-list--${getRegionIndex(activeRegion) + 1}`
            : "")
        }
      >
        {renderControlList}
      </div>
    </div>
  );
};

export default Control;
