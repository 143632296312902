export const getBaseUrl = () => {
  let protocol = "https://";
  const machine = process.env.REACT_APP_MACHINE ?? "localhost",
    port = process.env.REACT_APP_PORT ?? undefined,
    prefix = process.env.REACT_APP_API_PREFIX ?? undefined,
    version = process.env.REACT_APP_API_VERSION ?? undefined;

  if (process.env?.REACT_APP_MACHINE) {
    if (process.env.REACT_APP_MACHINE === "localhost") {
      protocol = "http://";
    }

    return `${protocol}${machine}${port ? `:${port}` : ""}${
      prefix ? `/${prefix}` : ``
    }${version ? `/${version}` : ``}`;
  }

  return;
};
