export const customerConfig = {
  type: "dropdownSearch",
  key: "customer",
  id: "customer",
  name: "customer",
  label: "Customer",
  validation: {
    required: "Customer is required",
  },
};

export const templateConfig = {
  type: "dropdownSearch",
  key: "template",
  id: "template",
  name: "template",
  label: "Template",
  validation: {
    required: "Template is required",
  },
};

export const templateNameConfig = {
  type: "text",
  key: "templateName",
  id: "templateName",
  name: "templateName",
  label: "Template name",
  validation: {
    required: "Template name is required",
    minLength: {
      value: 3,
      message: "Name must be at least 3 characters long",
    },
    maxLength: {
      value: 30,
      message: "Name should not exceed 30 characters",
    },
  },
};

export const descriptionConfig = {
  type: "textarea",
  key: "description",
  id: "description",
  name: "description",
  label: "Description",
  validation: {
    required: "Description is required",
    maxLength: {
      value: 1000,
      message: "Description should not exceed 1000 characters",
    },
  },
};

export const documentConfig = {
  type: "upload",
  key: "document",
  id: "document",
  name: "document",
  label: "Document",
  validation: {
    required: "Document is required",
  },
};

export const formConfig: any = {
  fieldList: {},
  actionList: {},
};
