import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { DocumentDetail } from "../../Types/document";

const initialState: {
  detail: DocumentDetail;
} = {
  detail: undefined,
};

export const pfdSlicer = createSlice({
  name: "pdf",
  initialState,
  reducers: {
    setPdfDetail: (state, action: PayloadAction<any>) => {
      if (action.payload) state.detail = action.payload;
    },
    clearPdfDetail: (state) => {
      state.detail = undefined;
    },
  },
});

export const { setPdfDetail, clearPdfDetail } = pfdSlicer.actions;

export default pfdSlicer.reducer;
