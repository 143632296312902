import { useState, useEffect, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../Redux/store";
import {
  addToTemplateList,
  clearTemplateList,
  setActiveTemplate,
  setTemplateList,
} from "../../../Redux/Template/templateSlicer";

import useOnScroll from "../../../Hooks/useOnScroll";

import { getListMessage } from "../../../Utils/listMessage";

import { Button } from "../../../Types/button";
import { Company } from "../../../Types/company";
import { TemplateListResponse } from "../../../Types/template";

import { getTemplateList as getTemplateListAPI } from "../../../APIs/Template/getTemplateList";
import { streamUpdate } from "../../../APIs/Reusable/streamUpdate";

import BasicInfoModal from "../Reusable/Modal/BasicInfoModal/BasicInfoModal";
import Dashboard from "../Dashboard/Dashboard";
import TemplateList from "./TemplateList";

const Template = () => {
  const templateRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const controller = new AbortController();
  const signal = controller.signal;

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const { result, total, resultTotal, searchTotal }: TemplateListResponse =
    useSelector((state: RootState) => state.template?.list);

  const [searchParam, setSearchParam] = useSearchParams();
  const modalPath = searchParam?.get("modal");
  const searchTerm = searchParam?.get("q");

  const [isListLoading, setIsListLoading] = useState<boolean>(true);

  const defaultLimit = 100;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isListErr, setIsListErr] = useState(false);

  useOnScroll(templateRef);

  useEffect(() => {
    if (company?.id) {
      setSkip(0);
      setLimit(defaultLimit);
      getTemplateList(company.id, true);
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading && !isListErr) {
      getTemplateList(company.id, false);
    }
  }, [company, skip, isListErr]);

  useEffect(() => {
    if (company?.id) {
      streamTemplateList();
    }

    return () => {
      controller.abort();
    };
  }, [company]);

  useEffect(() => {
    const elem = templateRef?.current ?? null;

    if (elem) {
      elem.addEventListener("scroll", onScroll);

      return () => {
        elem.removeEventListener("scroll", onScroll);
      };
    }
  }, [result, isListErr]);

  useEffect(() => {
    return () => {
      dispatch(clearTemplateList());
    };
  }, []);

  const onScroll = () => {
    const elem: any = templateRef?.current ?? null;
    const offset = 10;

    if (
      elem &&
      elem?.scrollTop + elem?.clientHeight + offset >= elem?.scrollHeight
    ) {
      if (company?.id && isListErr) {
        setIsListErr(false);
      } else {
        setLimit(
          searchTotal <= resultTotal + defaultLimit &&
            searchTotal - resultTotal > 0
            ? searchTotal - resultTotal
            : defaultLimit
        );

        if (searchTotal - resultTotal > 0) {
          setSkip(resultTotal);
        }
      }
    }
  };

  const createTemplate = () => {
    const params = { modal: "create", target: "template" };
    setSearchParam(createSearchParams(params));
  };

  const getTemplateList = (companyId, isReset) => {
    setIsListLoading(true);

    const params = {
      skip: isReset ? 0 : skip,
      limit: isReset ? defaultLimit : limit,
      searchTerm,
    };

    getTemplateListAPI(companyId, params)
      .then((res: any) => {
        if (res) {
          if (!isReset && result?.length) {
            res.result = [...result, ...res.result];
            res.resultTotal += resultTotal;
          }

          dispatch(setTemplateList(res));
          setIsListErr(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsListErr(true);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const streamTemplateList = () => {
    streamUpdate(
      company?.id,
      "templates",
      signal,
      onOpen,
      onMessage,
      onClose,
      onError
    );
  };

  const onOpen = () => {
    console.log("Stream update opened:");
  };

  const onMessage = (e) => {
    if (e?.event && e?.data) {
      switch (e?.event) {
        case "create":
          dispatch(addToTemplateList(e?.data));

          setTimeout(() => {
            dispatch(setActiveTemplate(null));
          }, 3000);
          break;

        default:
          break;
      }
    }
  };

  const onClose = () => {
    console.log("Stream update closed");
  };

  const onError = (err) => {
    console.log("Stream update error:", err);
  };

  const actionConfig: Button[] = [
    {
      type: "submit",
      label: "Create template",
      id: "createTemplate",
      style: "primary",
      size: "medium",
      onClick: createTemplate,
    },
  ];

  const onChange = (key, value) => {
    if (value) {
      setSearchParam(createSearchParams({ q: value }));
    } else {
      searchParam.delete("q");
      setSearchParam(searchParam);
    }
  };

  const message = getListMessage(
    "template",
    isListLoading,
    skip,
    limit,
    resultTotal,
    isListErr,
    searchTotal,
    searchTerm
  );

  const headerConfig = {
    tabList: [
      {
        label: "Templates",
        count: total ?? 0,
      },
    ],
    information: {
      message: message,
      isLoading: isListLoading,
      isError: isListErr,
    },
    filterList: {
      templateSearch: {
        type: "text",
        key: "templateSearch",
        id: "templateSearch",
        name: "templateSearch",
        label: "Search",
        query: "s",
        defaultValue: searchTerm,
        placeholder: "Search using template or customer details",
        onChange: onChange,
      },
    },
  };

  return (
    <Dashboard actionConfig={actionConfig} headerConfig={headerConfig}>
      <div className="template h-100-perc scroll" ref={templateRef}>
        <TemplateList templateList={result} searchTerm={searchTerm} />
      </div>

      {modalPath && <BasicInfoModal />}
    </Dashboard>
  );
};

export default Template;
