import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { formatDate } from "../../../Utils/formatDate";

import { RootState } from "../../../Redux/store";
import { setCustomerDetail } from "../../../Redux/Customer/customerSlicer";

import { getCustomerDetail as getCustomerDetailAPI } from "../../../APIs/Customer/getCustomerDetail";

import { Company } from "../../../Types/company";
import { Customer } from "../../../Types/customer";

import Dashboard from "../Dashboard/Dashboard";
import CustomerMeta from "./CustomerMeta";

const CustomerDetail = () => {
  const { cid: customerId } = useParams();
  const dispatch = useDispatch();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const customerDetail: Customer = useSelector(
    (state: RootState) => state.customer.detail
  );

  useEffect(() => {
    if (company?.id && customerId) getCustomerDetail(company.id, customerId);
  }, [company, customerId]);

  useEffect(() => {
    return () => {
      dispatch(setCustomerDetail(null));
    };
  }, []);

  const getCustomerDetail = (companyId, customerId) => {
    getCustomerDetailAPI(companyId, customerId)
      .then((res: any) => {
        if (res) {
          res.createdOn = formatDate(res.createdOn, "long") ?? null;
          res.lastModifiedOn = formatDate(res.lastModifiedOn, "long") ?? null;

          if (res.companySpecificData.updatedOn) {
            res.companySpecificData.updatedOn =
              formatDate(res.companySpecificData.updatedOn, "long") ?? null;
          }
          dispatch(setCustomerDetail(res));
        }
      })
      .catch(() => {
        dispatch(setCustomerDetail(null));
      });
  };

  const headerConfig = {
    breadcrumbList: [
      {
        label: "Customer details",
        children: {
          label: customerDetail?.code ?? "",
          onClick: null,
          isEditable: true,
          link: `/customer/${customerDetail?.id}`,
        },
      },
      ,
    ],
  };

  return (
    <Dashboard headerConfig={headerConfig}>
      <div className="customer-detail h-100-perc scroll">
        <CustomerMeta companyId={company?.id} customerDetail={customerDetail} />
      </div>
    </Dashboard>
  );
};

export default CustomerDetail;
