import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { deleteTableRow } from "../../../../../../Redux/Sale/saleSlicer";

import DropdownSearch from "../../../Form/Element/Dropdown/DropdownSearch";

const Action = ({
  rowIndex,
  isSaving,
  isValidating,
  setActiveEdit,
  activeRowList,
  setActiveRowList,
}) => {
  const dispatch = useDispatch();
  const [isListOpen, setIsListOpen] = useState(false);

  const list = [
    {
      key: "deleteRow",
      label: `Delete row${activeRowList?.length > 1 ? "s" : ""}`,
      action: () => deleteRow(),
    },
  ];

  useEffect(() => {
    setIsListOpen(activeRowList?.[activeRowList?.length - 1] === rowIndex);
  }, [activeRowList]);

  const deleteRow = () => {
    setActiveEdit("saleTable");
    dispatch(deleteTableRow(activeRowList));
    setActiveRowList([]);
  };

  const onSelect = (key, value) => {
    value?.action(key, value);
  };

  const addToActiveRowList = () => {
    const index = activeRowList?.findIndex((row) => row === rowIndex);

    if (index === -1) {
      setActiveRowList([...activeRowList, rowIndex]);
    } else {
      activeRowList.splice(index, 1);
      setActiveRowList([...activeRowList]);

      if (activeRowList?.length === 0) {
        setIsListOpen(false);
      }
    }
  };

  const renderDropdown = () => {
    return (
      <DropdownSearch
        type={"dropdownChoose"}
        key={"tool-action"}
        id={"tool-action"}
        name={"tool-action"}
        label={null}
        offset={{ top: -25.5, left: 15 }}
        isListOpen={isListOpen}
        setIsListOpen={setIsListOpen}
        isDisableOutsideClick={true}
        list={list}
        onSelect={onSelect}
      >
        <div
          className="tool__action-icon"
          onClick={() => addToActiveRowList()}
        ></div>
      </DropdownSearch>
    );
  };

  const isActive = activeRowList?.includes(rowIndex);

  return (
    <div className={"tool__action" + (isActive ? " tool__action--active" : "")}>
      {!isSaving && !isValidating && renderDropdown()}
    </div>
  );
};

export default Action;
