export const customerDetailConfig = {
  label: "Customer details",
  attributeList: {
    customerCode: {
      label: "Customer code",
      defaultValue: "Unavailable",
    },
    customerName: {
      label: "Customer name",
      defaultValue: "Unavailable",
    },
  },
  order: ["customerCode", "customerName"],
};

export const templateDetailConfig = {
  label: "Template details",
  attributeList: {
    id: {
      label: "Template ID",
      defaultValue: "Unavailable",
    },
    description: {
      label: "Description",
      defaultValue: "Unavailable",
    },
    createdByName: {
      label: "Created by",
      defaultValue: "Unavailable",
    },
    createdOn: {
      label: "Created on",
      defaultValue: "Unavailable",
    },
    lastModifiedOn: {
      label: "Last modified on",
      defaultValue: "Not yet modified",
    },
  },
  order: ["id", "description", "createdByName", "createdOn", "lastModifiedOn"],
};

export const documentDetailConfig = {
  label: "Document details",
  fileList: null,
};
