const Legend = ({ legendData }) => {
  const renderLegendList = legendData?.map((legend) => {
    return (
      <div
        key={legend?.code}
        className={
          "legend__card" +
          (legend?.code
            ? ` legend__card--code legend__card--code-${legend?.code}`
            : "")
        }
      >
        <div className="legend__color"></div>
        <div className="legend__label">{legend?.label ?? "Unassigned"}</div>
      </div>
    );
  });

  return <div className="legend">{renderLegendList}</div>;
};

export default Legend;
