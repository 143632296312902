import EmptyIcon from "./../../../Assets/Reusable/empty.png";

import Empty from "../Reusable/Empty";
import UserCard from "./UserCard";

const UserList = ({ userList, searchTerm }) => {
  const renderUserList = userList?.map((user) => {
    if (!user) return;

    return <UserCard key={user.id} user={user} />;
  });

  if (!userList || userList?.length === 0) {
    const title = searchTerm
      ? `No user matching the search '${searchTerm}'`
      : "You don’t have any users, try refreshing!";

    return (
      <Empty
        icon={EmptyIcon}
        alt="Empty screen icon for user"
        title={title}
        info={
          <>
            Click on the <b>Refresh users</b> button in the header to get the
            all the users
          </>
        }
      />
    );
  }

  return <div className="user__list scroll__child">{renderUserList}</div>;
};

export default UserList;
