import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { formConfig, defaultTableRegexSetting } from "./formConfig";

import CloseIcon from "../../../../../../Assets/Reusable/CloseIcon";
import AddIcon from "../../../../../../Assets/Reusable/AddIcon";

import { getRegion } from "../../../../../../Utils/labelSystem";

import { RootState } from "../../../../../../Redux/store";

import TableListValidation from "./TableListValidation";

const TableValidation = ({ regexSetting, setRegexSetting, isExpanded }) => {
  const regexSettingRef = useRef(JSON.parse(JSON.stringify(regexSetting)));
  const formListRef = useRef([]);

  const [renderAllowed, setRenderAllowed] = useState(false);

  const { regionConfig, activeRegion } = useSelector(
    (state: RootState) => state.parser
  );

  useEffect(() => {
    if (regexSetting && typeof regexSetting === "object" && !renderAllowed) {
      setFormValue();
      setRenderAllowed(true);
    }
  }, [regexSetting]);

  const setFormValue = () => {
    formListRef.current = [];

    typeof regexSetting === "object" &&
      regexSetting?.map((exp) => {
        const fieldList = {};
        const formId = Math.random();

        exp &&
          Object.keys(exp).map((key) => {
            addToFieldList(fieldList, formId, key, exp?.[key]);
          });

        formListRef?.current?.push(createFormData(fieldList, formId));
      });
  };

  const getFormIndex = (formId) => {
    const formIndex = formListRef?.current?.findIndex(
      (form) => form?.formId === formId
    );

    return formIndex;
  };

  const addToFieldList = (fieldList, formId, key, defaultValue) => {
    const region = getRegion(regionConfig, activeRegion);

    const field = JSON.parse(
      JSON.stringify(formConfig?.[region?.regionType]?.fieldList?.[key] ?? null)
    );

    field.onChange = (fieldKey, value, id) => onChange(key, value, formId);

    field.key =
      field.id =
      field.name =
        `${activeRegion}_${key}${Math.random()}`;

    field.defaultValue = defaultValue;
    fieldList[field.key] = field;
  };

  const createFormData = (fieldList, formId) => {
    return {
      fieldList,
      formId,
      actionList: {
        insertColumn: {
          type: "button",
          label: "",
          id: "insertColumn",
          key: "insertColumn",
          icon: <AddIcon />,
          onClick: () => insertColumn(formId),
        },
        deleteColumn: {
          type: "button",
          label: "",
          id: "deleteColumn",
          key: "deleteColumn",
          icon: <CloseIcon />,
          onClick: () => deleteColumn(formId),
        },
      },
    };
  };

  const insertColumn = (formId) => {
    const formIndex = getFormIndex(formId);

    let setting = regexSettingRef?.current;
    if (setting?.length > 0) {
      setting.splice(formIndex + 1, 0, {
        ...defaultTableRegexSetting,
      });
    } else setting = [defaultTableRegexSetting];

    setRegexSetting(JSON.parse(JSON.stringify(setting)));
    regexSettingRef.current = setting;

    const fieldList = {};
    const id = Math.random();

    Object.keys(defaultTableRegexSetting).map((key) => {
      addToFieldList(fieldList, id, key, defaultTableRegexSetting?.[key]);
    });

    formListRef?.current?.splice(
      formIndex + 1,
      0,
      createFormData(fieldList, id)
    );
  };

  const deleteColumn = (formId) => {
    const formIndex = getFormIndex(formId);

    const setting = regexSettingRef?.current;
    if (setting[formIndex]) setting.splice(formIndex, 1);

    setRegexSetting(JSON.parse(JSON.stringify(setting)));
    regexSettingRef.current = setting;

    formListRef?.current?.splice(formIndex, 1);
  };

  const onChange = (key, value, formId) => {
    const formIndex = getFormIndex(formId);

    if (regexSettingRef?.current) {
      const setting = JSON.parse(JSON.stringify(regexSettingRef.current));

      if (setting) {
        if (key === "columnIndex") {
          value = value === "" ? "" : Number(value);
        }

        setting[formIndex][key] = value;

        setRegexSetting(JSON.parse(JSON.stringify(setting)));
        regexSettingRef.current = setting;
      }
    }
  };

  const renderFormList = () => {
    return formListRef?.current?.map((config, index) => {
      return (
        <TableListValidation
          key={config?.formId}
          index={index}
          config={config}
        />
      );
    });
  };

  return (
    <>
      {renderFormList()}

      {isExpanded &&
        (!formListRef?.current || formListRef?.current?.length === 0) && (
          <div className="setting__not-applicable">
            Failed to render validation settings due to an error. Please try
            again later or contact support if the issue persists.
          </div>
        )}
    </>
  );
};

export default TableValidation;
