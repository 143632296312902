export const emailCardConfig = {
  attributeList: {
    fromEmail: {
      label: "Email from",
      defaultValue: "Unavailable",
    },
    toEmails: {
      label: "Email to",
      defaultValue: "Unavailable",
    },
    subject: {
      label: "Subject",
      defaultValue: "Unavailable",
      isSeeMore: true,
    },
    body: {
      label: "Content",
      defaultValue: "Unavailable",
      isSeeMore: true,
    },
    customerCode: {
      label: "Customer code",
      defaultValue: "Unavailable",
    },
    customerName: {
      label: "Customer name",
      defaultValue: "Unavailable",
    },
    deliveredOn: {
      label: "Delivered on",
      defaultValue: "Unavailable",
    },
  },
  order: [
    "fromEmail",
    "subject",
    "customerCode",
    "deliveredOn",
    "toEmails",
    "body",
    "customerName",
  ],
};

export const assignedEmailCardConfig = {
  attributeList: {
    assignedOn: {
      label: "Assigned on",
      defaultValue: "Not assigned yet",
    },
    assignedToName: {
      label: "Assigned to",
      defaultValue: "Unavailable",
    },
  },
  order: ["assignedOn", "assignedToName"],
};
