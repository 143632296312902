import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { validateRegion } from "../../../../../Utils/validateCoordinate";

import { parseTemplateRegion as parseTemplateRegionAPI } from "../../../../../APIs/Template/parseTemplateRegion";

import saveIcon from "../../../../../Assets/Reusable/save.svg";
import EmptyIcon from "../../../../../Assets/Reusable/empty.png";

import { Company } from "../../../../../Types/company";
import { Template } from "../../../../../Types/template";

import { RootState } from "../../../../../Redux/store";

import useDebounce from "../../../../../Hooks/useDebounce";

import Table from "../../../Reusable/Element/Table/Table";
import Text from "../../../Reusable/Element/Text/Text";
import Button from "../../../Reusable/Button";
import Status from "../../../Reusable/Status";
import Empty from "../../../Reusable/Empty";

const ParserOutput: React.FC = () => {
  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isParseError, setIsParseError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [activeCellIndex, setActiveCellIndex] = useState(null);

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const templateDetail: Template = useSelector(
    (state: RootState) => state.template.detail
  );

  const { regionConfig, activeRegion }: any = useSelector(
    (state: RootState) => state.parser
  );

  const config = useDebounce(regionConfig, 1000);

  useEffect(() => {
    setOutput(null);
  }, [activeRegion]);

  useEffect(() => {
    if (validateRegion(regionConfig?.region?.[activeRegion])) {
      parseTemplateRegion();
    } else {
      setOutput(null);
    }
  }, [
    JSON.stringify(config?.region?.[activeRegion]?.area),
    JSON.stringify(config?.region?.[activeRegion]?.labellingSetting),
    JSON.stringify(config?.region?.[activeRegion]?.validationSetting?.regex),
    activeRegion,
  ]);

  const parseTemplateRegion = () => {
    if (company?.id && templateDetail?.id && templateDetail?.customerId) {
      const params = {
        customerId: templateDetail.customerId,
      };

      const data = config?.region?.[activeRegion] ?? null;

      if (!data) return;

      setOutput(null);
      setIsLoading(true);
      setIsParseError(false);

      parseTemplateRegionAPI(company.id, templateDetail.id, data, params)
        .then((res) => {
          setOutput(res);
        })
        .catch(() => {
          setIsParseError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const renderOutput = () => {
    if (!output) return;
    const data = output.result ?? null;

    switch (output.type) {
      case "table":
        return (
          <Table
            source="template"
            data={data}
            isSaving={isSaving}
            isValidating={isValidating}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            activeRowIndex={activeRowIndex}
            activeCellIndex={activeCellIndex}
            setActiveRowIndex={setActiveRowIndex}
            setActiveCellIndex={setActiveCellIndex}
          />
        );

      case "text":
      case "number":
      case "date": {
        const region = config?.region?.[activeRegion] ?? null;
        const textData = { label: region?.label ?? output.type, value: data };

        return <Text data={textData} />;
      }

      default:
        break;
    }
  };

  const renderPlaceholder = () => {
    return (
      <div className="parser__placeholder">
        <Empty
          icon={EmptyIcon}
          alt="Parse placeholder"
          title="Parsed data is not available!"
          info={
            <>
              Select a<b> Label </b>and a<b> Region </b>to draw rectangular
              boxes for labeling. Once the boxes are drawn, click on the{" "}
              <b> Parse </b>
              button and check the generated table.
            </>
          }
        />
      </div>
    );
  };

  return (
    <div className="parser__output">
      <div className="parser__header">
        <h4 className="parser__title">Parsed content</h4>

        <div className="parser__status">
          {(output?.lastModifiedOn || isLoading) && (
            <Status
              icon={saveIcon}
              label="Last parsed"
              value={output?.lastModifiedOn}
              isLoading={isLoading}
              loadingLabel={isLoading ? "Parsing..." : null}
            />
          )}

          <span>{isParseError ? "Parsing failed" : ""}</span>
        </div>

        <Button
          id="parse"
          key="parse"
          label="Parse"
          style="primary"
          isLoading={false}
          isDisable={false}
          type="button"
          onClick={parseTemplateRegion}
        />

        <div className="parser__info hint">
          Choose the correct column name from the dropdown by clicking the
          corresponding table column. If needed, apply regex to extract accurate
          information from parsed content.
        </div>
      </div>

      <div className="parser__result scroll">
        {!output && !isLoading && renderPlaceholder()}

        {renderOutput()}
      </div>
    </div>
  );
};

export default ParserOutput;
