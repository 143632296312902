const OpenFileIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      className="open-file-icon"
    >
      <path d="M9.41321 0.237269C9.18722 0.289769 8.97737 0.541769 8.97737 0.757019C8.97737 0.993269 9.00158 1.02477 9.7495 1.75977L10.4571 2.45539L8.3559 4.50289C7.20174 5.62902 6.22783 6.59764 6.19285 6.65539C6.05296 6.89164 6.08524 7.13052 6.28702 7.32477C6.4861 7.52164 6.73092 7.55314 6.97306 7.41664C7.03224 7.38252 8.02498 6.43227 9.17915 5.30614L11.2776 3.25602L11.9906 3.94902C12.6336 4.57114 12.717 4.64202 12.8326 4.67352C13.099 4.74439 13.3734 4.63152 13.4945 4.39789L13.5644 4.26927L13.5725 2.53414C13.5779 1.28202 13.5725 0.762269 13.551 0.659894C13.5079 0.473519 13.3277 0.287144 13.142 0.242519C12.9968 0.205769 9.56387 0.200519 9.41321 0.237269Z" />
      <path d="M2.25669 1.3897C1.77512 1.5052 1.37426 1.81758 1.13481 2.2612C0.933037 2.6392 0.946489 2.28483 0.946489 6.93107C0.946489 11.5301 0.933037 11.2046 1.12674 11.6009C1.28009 11.9159 1.58141 12.2099 1.90425 12.3596C2.3105 12.5486 1.97689 12.5354 6.69039 12.5354C10.734 12.5354 10.9627 12.5328 11.1268 12.4882C11.7106 12.3254 12.1518 11.9159 12.3455 11.3542L12.4074 11.1704L12.4155 8.74232C12.4208 7.03082 12.4155 6.28532 12.3939 6.21445C12.3294 5.99657 12.0926 5.82857 11.8559 5.82857C11.6191 5.82857 11.4469 5.92832 11.3366 6.13045C11.2774 6.23545 11.2774 6.2407 11.264 8.63732C11.2478 11.2807 11.2613 11.1048 11.0837 11.2676C10.9089 11.4303 11.2021 11.4198 6.70115 11.4198C3.52923 11.4198 2.56877 11.4119 2.48268 11.3883C2.34009 11.3463 2.16522 11.1757 2.12217 11.0366C2.09796 10.9526 2.08989 10.0154 2.08989 6.92057C2.08989 2.52895 2.07913 2.81508 2.24593 2.64445C2.41273 2.4712 2.23248 2.48433 4.94166 2.46858L7.40334 2.45545L7.5244 2.38983C7.70465 2.29008 7.80151 2.13783 7.81496 1.93308C7.82841 1.7467 7.77729 1.61808 7.64009 1.49208C7.4679 1.33195 7.59704 1.33983 4.90938 1.34245C2.64679 1.34245 2.43694 1.3477 2.25669 1.3897Z" />
    </svg>
  );
};

export default OpenFileIcon;
