const MinusIcon = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.35787 5.35294L3.56537 5.35294C1.09136 5.3518 1.23809 5.35976 1.15164 5.23464C1.10842 5.17094 1.10842 5.02535 1.15164 4.96165C1.23809 4.83653 1.09136 4.84449 3.5665 4.84221L6.35787 4.84221L8.62713 4.84335C9.87494 4.84221 10.9135 4.85017 10.9351 4.86041C11.0761 4.92183 11.1353 5.11293 11.0511 5.23578C10.9658 5.35976 11.1091 5.35294 8.63737 5.35408L6.35787 5.35408"
        stroke="#9797B8"
        strokeWidth="0.75"
      />
    </svg>
  );
};

export default MinusIcon;
