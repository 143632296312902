export const getLocalStorageValue = (keyName: string) => {
  const value = window.localStorage.getItem(keyName);
  return JSON.parse(value);
};

export const setLocalStorageValue = (keyName: string, value: any) => {
  window.localStorage.setItem(keyName, JSON.stringify(value));
};

export const removeLocalStorageValue = (keyName: string = undefined) => {
  if (keyName) window.localStorage.removeItem(keyName);
  else {
    window.localStorage.clear();
  }
};
