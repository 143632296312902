import EmptyIcon from "./../../../Assets/Reusable/empty.png";

import Empty from "../Reusable/Empty";
import CustomerCard from "./CustomerCard";

const CustomerList = ({ customerList, searchTerm }) => {
  const renderCustomerList = customerList?.map((customer) => {
    if (!customer) return;

    return <CustomerCard key={customer.id} customer={customer} />;
  });

  if (!customerList || customerList?.length === 0) {
    const title = searchTerm
      ? `No customer matching the search '${searchTerm}'`
      : "You don’t have any customers, try refreshing!";

    return (
      <Empty
        icon={EmptyIcon}
        alt="Empty screen icon for customer"
        title={title}
        info={
          <>
            Click on the <b>Refresh Customers</b> button in the header to get
            the all the customers
          </>
        }
      />
    );
  }

  return (
    <div className="customer__list scroll__child">{renderCustomerList}</div>
  );
};

export default CustomerList;
