import axios from "axios";

import { toCamel, toSnake } from "snake-camel";

import { getBaseUrl } from "../Utils/baseUrl";
import { getSession } from "../Utils/session";

axios.defaults.baseURL = getBaseUrl();

axios.interceptors.request.use(
  (req) => {
    const bearerToken = getSession("bearerToken");
    if (bearerToken) {
      req.headers.Authorization = bearerToken;
    }

    if (req?.data && !(req.data instanceof FormData))
      req.data = toSnake(req.data);

    if (req?.params) req.params = toSnake(req?.params);

    return req;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (res) => {
    const regex = /^2\d{2}$/;
    const isSuccess = regex.test(res?.status?.toString());

    if (res?.status && !isSuccess) {
      return Promise.reject(res);
    }

    const response: any = toCamel(res.data);
    return response;
  },
  (err) => {
    if (err?.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(err);
  }
);
