import { useEffect, RefObject } from "react";

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;

      const portalElem = document.querySelector(".portal#dropdown");
      const modalElem = document.querySelector(
        ".portal.portal--pdf-viewer .modal"
      );

      if (
        !el ||
        el.contains((event?.target as Node) || null) ||
        (portalElem && portalElem.contains((event?.target as Node) || null)) ||
        (modalElem && modalElem.contains((event?.target as Node) || null))
      )
        return null;
      else handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
