import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import hash from "object-hash";

import saveIcon from "../../../../Assets/Reusable/save.svg";
import EmptyIcon from "../../../../Assets/Reusable/empty.png";

import { saleLegendData } from "./saleDetailConfig";

import { RootState } from "../../../../Redux/store";
import {
  setSaleConfig,
  updateCustomerSaleId,
  updateSaleData,
  updateSaleTable,
  cleanSaleTable,
} from "../../../../Redux/Sale/saleSlicer";

import useDebounce from "../../../../Hooks/useDebounce";

import { camelToSnake } from "../../../../Utils";

import { getSaleConfig as getSaleConfigAPI } from "../../../../APIs/Sale/getSaleConfig";
import { saveSaleData as saveSaleDataAPI } from "../../../../APIs/Sale/saveSaleData";
import { validateSaleData as validateSaleDataAPI } from "../../../../APIs/Sale/validateSaleData";

import Table from "../../Reusable/Element/Table/Table";
import Empty from "../../Reusable/Empty";
import Status from "../../Reusable/Status";
import Button from "../../Reusable/Button";

const SaleData = ({
  company,
  saleDetail,
  isSaving,
  setIsSaving,
  isValidating,
  setIsValidating,
  activeEdit,
  setActiveEdit,
}) => {
  const [isValid, setIsValid] = useState(undefined);
  const [validationStatus, setValidationStatus] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [activeCellIndex, setActiveCellIndex] = useState(null);
  const [isSaveAllowed, setIsSaveAllowed] = useState(false);

  const dispatch = useDispatch();

  const config = useSelector((state: RootState) => state.sale.config);

  const { validatedData, lastModifiedOn } = saleDetail ?? {};
  const { order } = validatedData?.saleTable ?? {};

  const debouncedValidatedData = useDebounce(validatedData, 1000);
  const dataHash = validatedData && hash(validatedData);
  const debouncedDataHash =
    debouncedValidatedData && hash(debouncedValidatedData);

  var controller;

  useEffect(() => {
    getSaleConfig();
  }, [company]);

  useEffect(() => {
    if (!isEdit && validatedData) {
      if (isSaveAllowed) {
        saveSaleData();
      } else {
        setIsSaveAllowed(true);
      }
    }
  }, [isEdit, debouncedDataHash]);

  useEffect(() => {
    const { isValid, message } = validatedData?.saleTable ?? {};

    setIsValid(isValid);
    const successMessage = "Sale data has been validated";
    const errorMessage =
      "Sale data has not been validated. Review and correct any errors in the sales data";

    setValidationStatus(
      isValid ? successMessage : message ? message : errorMessage
    );
  }, [dataHash]);

  const saveSaleData = () => {
    if (
      company?.id &&
      saleDetail?.id &&
      saleDetail?.customerId &&
      validatedData
    ) {
      const params = {
        customerId: saleDetail.customerId,
      };

      setIsSaving(true);

      saveSaleDataAPI(company.id, saleDetail.id, validatedData, params)
        .then(() => {
          validateSaleData();
        })
        .catch(() => {})
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const validateSaleData = () => {
    if (
      company?.id &&
      saleDetail?.id &&
      saleDetail?.customerId &&
      activeEdit !== "additionalField" &&
      validatedData &&
      !(isValidating || isSaving)
    ) {
      setIsValidating(true);

      const edit = activeEdit ?? "saleTable";

      let params: any = {
        customerId: saleDetail.customerId,
        validateOption: camelToSnake(edit),
      };

      const key = config?.tableConfig?.order?.[activeCellIndex] ?? null;

      if (edit === "saleTable") {
        params = {
          ...params,
          saleTableIndex: activeRowIndex,
          saleTableColumnKey: key,
        };
      }

      if (controller) controller.abort();
      controller = new AbortController();

      validateSaleDataAPI(company.id, saleDetail.id, params, controller.signal)
        .then((res: any) => {
          if (res) {
            if (edit === "saleTable") {
              if (activeRowIndex !== null && activeCellIndex !== null) {
                const row = res?.saleTable?.value?.[activeRowIndex];

                if (row) {
                  dispatch(
                    updateSaleTable({
                      rowIndex: activeRowIndex,
                      cellIndex: activeCellIndex,
                      row,
                      isValid: res?.saleTable?.isValid,
                      message: res?.saleTable?.message,
                    })
                  );
                }
              } else {
                dispatch(updateSaleData(res));
              }
            } else if (activeEdit === "customerSaleId" && res?.customerSaleId) {
              dispatch(updateCustomerSaleId(res?.customerSaleId));
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsValidating(false);
          setActiveEdit(null);
        });
    }
  };

  const getSaleConfig = () => {
    if (company?.id) {
      getSaleConfigAPI(company.id)
        .then((res) => {
          dispatch(setSaleConfig(res));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setSaleConfig(null));
        });
    }
  };

  const cleanSaleData = () => {
    if (!(isValidating || isSaving)) {
      dispatch(cleanSaleTable());
    }
  };

  const renderPlaceholder = () => {
    return (
      <div className="sale-data__placeholder">
        <Empty
          icon={EmptyIcon}
          alt="Parse placeholder"
          title="Parsed data is not available!"
          info={
            <>
              Select a<b> Label </b>and a<b> Region </b>to draw rectangular
              boxes for labeling. Once the boxes are drawn, click on the{" "}
              <b> Parse </b>
              button and check the generated table.
            </>
          }
        />
      </div>
    );
  };

  return (
    <div className="sale-data__output">
      <div className="sale-data__header">
        <h4 className="sale-data__title">Parsed document data</h4>

        <div className="sale-data__status">
          {(lastModifiedOn || isSaving) && (
            <Status
              icon={saveIcon}
              label="Last saved"
              value={lastModifiedOn}
              isLoading={isSaving && activeEdit === "saleTable"}
              loadingLabel={
                isSaving && activeEdit === "saleTable" ? "Saving..." : null
              }
            />
          )}
        </div>
      </div>

      <div className="sale-data__toolbar">
        <div className="sale-data__validation-status">
          {!isValidating &&
            (activeEdit || activeEdit !== "saleTable") &&
            validationStatus && (
              <span
                className={
                  "sale-data__status-message" +
                  (isValid
                    ? " sale-data__status-message--success"
                    : " sale-data__status-message--error")
                }
              >
                {validationStatus ?? ""}
              </span>
            )}

          {isValidating && (!activeEdit || activeEdit === "saleTable") && (
            <div className="sale-data__loading">
              <span className="sale-data__loading-icon">
                <img />
              </span>
              Validating data...
            </div>
          )}
        </div>
        <div className="sale-data__toolbar-action">
          <Button
            type="button"
            label="Clean sale data"
            id="clean-sale-data"
            style="secondary"
            size="small"
            onClick={cleanSaleData}
          />

          <Button
            type="button"
            label="Validate sale data"
            id="validate-sale-data"
            style="primary"
            size="small"
            onClick={validateSaleData}
          />
        </div>
      </div>

      <div className="sale-data__result scroll">
        {!validatedData && !isSaving && renderPlaceholder()}

        <Table
          source="sale"
          data={validatedData?.saleTable?.value}
          order={order}
          isSaving={isSaving}
          isValidating={isValidating}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          activeRowIndex={activeRowIndex}
          activeCellIndex={activeCellIndex}
          setActiveRowIndex={setActiveRowIndex}
          setActiveCellIndex={setActiveCellIndex}
          setActiveEdit={setActiveEdit}
          legendData={saleLegendData}
        />
      </div>
    </div>
  );
};

export default SaleData;
