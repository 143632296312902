import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../../Redux/store";
import { insertTableRow } from "../../../../../../Redux/Sale/saleSlicer";

import { getSaleConfigDetail } from "../../../../../../Utils/sale";

const Insert = ({ rowIndex, isSaving, isValidating, setActiveEdit }) => {
  const dispatch = useDispatch();

  const config = useSelector((state: RootState) => state.sale.config);

  const insertRow = () => {
    if (isSaving || isValidating) return;

    const order = config.tableConfig?.order ?? null;

    const row = [];

    order?.map((key) => {
      const cellConfig = getSaleConfigDetail(config, key);
      row.push({
        key: key,
        message: null,
        messageColorCode: null,
        type: cellConfig?.type,
        value: cellConfig?.defaultValue,
      });
    });

    if (row.length > 0) {
      setActiveEdit("saleTable");
      dispatch(
        insertTableRow({ rowIndex: rowIndex, row: { isMap: false, row } })
      );
    }
  };

  return (
    <div className="tool__insert" onClick={() => insertRow()}>
      <div className="tool__insert-icon"></div>
    </div>
  );
};

export default Insert;
