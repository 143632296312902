import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import saveIcon from "../../../../Assets/Reusable/save.svg";

import { additionalFieldFormConfig as formConfig } from "./formConfig";

import { RootState } from "../../../../Redux/store";
import { updateAdditionalField } from "../../../../Redux/Sale/saleSlicer";

import { Company } from "../../../../Types/company";

import { camelToSnake } from "../../../../Utils";

import { getAdditionalFieldList } from "../../../../APIs/Sale/getAdditionalFieldList";

import Form from "../../Reusable/Form/Form";
import Status from "../../Reusable/Status";

const SaleAdditionalField = ({
  saleDetail,
  isSaving,
  activeEdit,
  setActiveEdit,
}) => {
  const dispatch = useDispatch();

  const [deliveryAgentCode, setDeliveryAgentCode] = useState(null);
  const [list, setList] = useState([]);
  const [isListOpen, setIsListOpen] = useState(false);

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const additionalField = saleDetail?.validatedData?.additionalInputs;
  let controller;

  const {
    setValue,
    handleSubmit,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setFormValue();
  }, [saleDetail, formConfig]);

  const setFormValue = () => {
    if (additionalField && formConfig) {
      const { fieldList } = formConfig;

      additionalField &&
        Object.keys(fieldList)?.map((key) => {
          const field = fieldList?.[key];
          const value = additionalField?.[key];

          if (
            field?.type === "text" ||
            field?.type === "number" ||
            field?.type === "textarea" ||
            field?.type === "dropdownSearch"
          ) {
            setValue(key, value);
          }
        });
    }
  };

  const onChangeTextField = (key, value) => {
    const field = JSON.parse(JSON.stringify(additionalField));

    if (key === "differedBilingDate") value = parseInt(value);

    if (field) {
      field[key] = value;

      setActiveEdit("additionalField");
      dispatch(updateAdditionalField(field));
    }
  };

  const onChange = (key, searchTerm) => {
    if (key === "deliveryAgentCode") {
      additionalFieldSearch(key, searchTerm, camelToSnake(key));
      setDeliveryAgentCode(null);
    }
  };

  const onSelect = (key, value) => {
    if (key === "deliveryAgentCode") setDeliveryAgentCode(value ?? null);
    setValue(key, value?.label ?? "");
    onChangeTextField(key, value?.label);
  };

  const onFocus = (key, searchTerm) => {
    if (key === "deliveryAgentCode-search")
      additionalFieldSearch(key, searchTerm, camelToSnake("deliveryAgentCode"));
  };

  const onBlur = (key) => {
    if (key === "deliveryAgentCode" && !deliveryAgentCode) {
      setValue(key, "");
      onChangeTextField(key, "");
      if (controller) controller.abort();
    }
  };

  const additionalFieldSearch = (key, searchTerm, fieldKey) => {
    if (company?.id) {
      const params = {
        searchTerm,
        fieldKey,
      };

      if (controller) controller.abort();
      controller = new AbortController();

      getAdditionalFieldList(company?.id, params, controller.signal)
        .then((res: any) => {
          if (res) {
            const list = res?.map((item) => {
              return { label: item.value, key: item?.id };
            });

            setList(list?.length ? list : []);
            setIsListOpen(true);
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  };

  const scrollSelector = ".sale-detail";
  const scrollElem = scrollSelector && document.querySelector(scrollSelector);

  formConfig.fieldList = {
    ...formConfig.fieldList,
    differedBilingDate: {
      ...formConfig.fieldList.differedBilingDate,
      onChange: onChangeTextField,
    },
    deliveryAgentCode: {
      ...formConfig.fieldList.deliveryAgentCode,
      list,
      isListOpen,
      setIsListOpen,
      scrollElem,
      onSelect: onSelect,
      onChange: onChange,
      onFocus: onFocus,
      onBlur: onBlur,
    },
    deliveryAddress: {
      ...formConfig.fieldList.deliveryAddress,
      onChange: onChangeTextField,
    },
    internalInstruction: {
      ...formConfig.fieldList.internalInstruction,
      onChange: onChangeTextField,
    },
  };

  return (
    <div className="sale-additional-field">
      <div className="sale-additional-field__header">
        <h4 className="sale-additional-field__title">Additional fields</h4>

        <div className="sale-data__status">
          {(saleDetail?.lastModifiedOn || isSaving) && (
            <Status
              icon={saveIcon}
              label="Last saved"
              value={saleDetail?.lastModifiedOn}
              isLoading={isSaving && activeEdit === "additionalField"}
              loadingLabel={
                isSaving && activeEdit === "additionalField"
                  ? "Saving..."
                  : null
              }
            />
          )}
        </div>
      </div>

      <Form
        config={formConfig}
        control={control}
        handleSubmit={handleSubmit}
        errors={errors}
        clearErrors={clearErrors}
        register={register}
      />
    </div>
  );
};

export default SaleAdditionalField;
