export interface User {
  id: number;
  name: string;
  email: string;
  thumbnail?: string;
  createdOn?: Date;
  lastModified_on?: Date;
  isActive: boolean;
  userRole: UserRole;
}

export enum UserRole {
  "SUPER_USER" = "Super user",
  "ADMIN_USER" = "Admin",
  "MANAGER_USER" = "Manager",
  "NORMAL_USER" = "Normal",
}

export interface UserListResponse {
  total: number;
  result: User[];
  resultTotal: number;
  searchTotal?: number;
  active: User;
}

export interface UserFormField {
  name: string;
  userRole: string;
  email: string;
  password: string;
}
