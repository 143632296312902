const EditIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      fill="#9797B8"
    >
      <path d="M11.1781 3.04746C10.9069 3.12163 10.7123 3.21503 10.5206 3.36063C10.4302 3.42931 8.74522 5.10228 6.77811 7.08017C3.43565 10.4398 3.1973 10.6843 3.1288 10.8409C3.08771 10.9343 3.04113 11.0744 3.02743 11.1541C2.98908 11.3574 2.99182 13.8435 3.03017 14.055C3.10963 14.4918 3.43017 14.8462 3.86305 14.978C3.90962 14.9918 4.58907 15 5.37537 15C6.94797 14.9973 6.99455 14.9945 7.26578 14.8297C7.35893 14.772 8.62468 13.5193 11.0493 11.0882L14.6932 7.4318L14.8055 7.1983C14.9507 6.89337 15 6.69283 15 6.38791C15 6.08298 14.9507 5.88245 14.8055 5.57752C14.6932 5.34951 14.6712 5.32204 13.6877 4.34134C12.6274 3.28096 12.5534 3.21778 12.1726 3.08592C11.8959 2.98977 11.4548 2.97054 11.1781 3.04746ZM11.8986 4.19574C12 4.25068 12.2767 4.5199 12.8959 5.15997C13.3671 5.64895 13.7753 6.09397 13.8027 6.14617C13.874 6.28077 13.8712 6.50329 13.8 6.65438C13.7616 6.73129 13.5206 6.99227 13.1096 7.40433L12.4795 8.03616L11.2329 6.78624L9.98632 5.53631L10.663 4.86053C11.1589 4.36881 11.3671 4.17651 11.4384 4.15728C11.611 4.10509 11.7507 4.11608 11.8986 4.19574ZM10.4165 7.61585L11.6575 8.86028L9.17262 11.3519L6.68496 13.8462H5.43291H4.17812V12.5881L4.17538 11.3327L6.66304 8.85479C8.03016 7.49223 9.15618 6.37692 9.1644 6.37417C9.17262 6.37417 9.737 6.93458 10.4165 7.61585Z" />
    </svg>
  );
};

export default EditIcon;
