import axios from "axios";

export const getCustomerConfig = async (companyId) => {
  try {
    const res = await axios.get(`/company/${companyId}/customer/config`);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
