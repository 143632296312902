const Breadcrumb = ({ breadcrumb }) => {
  const renderBreadCrumbChildren = (children) => {
    if (!children) return;
    return <Breadcrumb breadcrumb={children} />;
  };

  return (
    <div className="breadcrumb">
      <h3
        className={
          "breadcrumb__label" +
          (breadcrumb?.link ? " breadcrumb__label--clickable" : "") +
          (breadcrumb?.isDisabled ? " breadcrumb__label--disabled" : "")
        }
      >
        {breadcrumb.label ?? ""}
      </h3>

      {breadcrumb?.children && (
        <div className="breadcrumb__children">
          {renderBreadCrumbChildren(breadcrumb.children)}
        </div>
      )}
    </div>
  );
};

export default Breadcrumb;
