import { useEffect, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAuth } from "../../Hooks/useAuth";

import { clearUser, setCompany, setUser } from "../../Redux/User/userSlicer";

import { getUserDetails } from "../../APIs/Onboard/getUserDetails";
import { getCompanyDetails as getUserCompanyDetails } from "../../APIs/Onboard/getCompanyDetails";

import { NoMatch } from "../Public/Common/NoMatch";

import Sale from "./Sale/Sale";
import SaleDetail from "./Sale/SaleDetail/SaleDetail";

import Email from "./Email/Email";

import Template from "./Template/Template";
import TemplateDetail from "./Template/TemplateDetail";

import Product from "./Product/Product";
import ProductDetail from "./Product/ProductDetail";

import Customer from "./Customer/Customer";
import CustomerDetail from "./Customer/CustomerDetail";

import User from "./User/User";

import PdfModal from "./Reusable/Pdf/PdfModal";

const Protected = () => {
  const dispatch = useDispatch();
  const { session, logout } = useAuth();
  const isLoggedIn = useMemo(() => (session ? true : false), [session]);

  useEffect(() => {
    if (isLoggedIn) {
      validateSession();
    }
  }, [isLoggedIn]);

  const validateSession = () => {
    getUserDetails()
      .then((user) => {
        dispatch(setUser(user));
        getCompanyDetails();
      })
      .catch(() => {
        dispatch(clearUser());
        logout();
      });
  };

  const getCompanyDetails = () => {
    getUserCompanyDetails()
      .then((company) => {
        dispatch(setCompany(company));
      })
      .catch(() => {});
  };

  const renderProtectedRoute = () => {
    return (
      <Routes>
        <Route path="/" element={<Sale />} />
        <Route path="/sale/:sid" element={<SaleDetail />} />

        <Route path="/template" element={<Template />} />
        <Route path="/template/:tid" element={<TemplateDetail />} />

        <Route path="/email" element={<Email />} />

        <Route path="/product" element={<Product />} />
        <Route path="/product/:pid" element={<ProductDetail />} />

        <Route path="/customer" element={<Customer />} />
        <Route path="/customer/:cid" element={<CustomerDetail />} />

        <Route path="/user" element={<User />} />

        <Route path="*" element={<NoMatch />} />
      </Routes>
    );
  };

  return isLoggedIn ? (
    <div className="h-100">
      {renderProtectedRoute()}

      <PdfModal type="view" pdfViewerId="general" />
    </div>
  ) : (
    <Navigate to="/u/login" replace />
  );
};

export default Protected;
