import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  regionConfig: any;
  activeRegion: any;
  activeArea: any;
} = {
  regionConfig: undefined,
  activeRegion: undefined,
  activeArea: undefined,
};

export const parserSlicer = createSlice({
  name: "parser",
  initialState,
  reducers: {
    setRegionConfig: (state, action: PayloadAction<any>) => {
      state.regionConfig = action.payload;
    },
    setActiveRegion: (state, action: PayloadAction<any>) => {
      state.activeRegion = action.payload;
    },
    setActiveArea: (state, action: PayloadAction<any>) => {
      state.activeArea = action.payload;
    },
  },
});

export const { setRegionConfig, setActiveRegion, setActiveArea } =
  parserSlicer.actions;

export default parserSlicer.reducer;
