import logoutIcon from "../../../../Assets/Dashboard/logout.svg";

import { useAuth } from "../../../../Hooks/useAuth";
import { Navigation } from "../../../../Types/navigation";

const Footer = ({ renderNavList }) => {
  const { logout } = useAuth();

  const navigationConfig: Navigation[] = [
    {
      id: "logout",
      label: "Logout",
      icon: logoutIcon,
      onClick: logout,
    },
  ];

  return (
    <footer className="sidebar__footer">
      {renderNavList(navigationConfig)}
    </footer>
  );
};

export default Footer;
