import { Link } from "react-router-dom";

const Tab = ({ tab }) => {
  if (!tab) return;

  return (
    <Link to={tab.link}>
      <div className={"tab" + (tab.isActive === false ? " tab--inactive" : "")}>
        <h3 className="tab__label">{tab.label ?? ""}</h3>
        <p className="tab__count">{tab.count ?? 0}</p>
      </div>
    </Link>
  );
};

export default Tab;
