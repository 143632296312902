import { useState, useEffect } from "react";

import { customerDetailConfig } from "./customerDetailConfig";

import { snakeToCamel } from "../../../Utils";

import { getCustomerConfig as getCustomerConfigAPI } from "../../../APIs/Customer/getCustomerConfig";

import Section from "../Reusable/Section";
import CustomerTemplateDetail from "./CustomerTemplateDetail";

const CustomerMeta = ({ companyId, customerDetail }) => {
  const defaultAdditionalDetailConfig = {
    label: "Company specific data",
    attributeList: {},
    order: [],
  };

  const [customerAdditionalDetailConfig, setCustomerAdditionalDetailConfig] =
    useState(null);

  const [additionalDetailConfig, setAdditionalDetailConfig] = useState(
    defaultAdditionalDetailConfig
  );

  useEffect(() => {
    if (customerDetail?.companySpecificData) {
      getCustomerConfig();
    }
  }, [customerDetail?.companySpecificData]);

  useEffect(() => {
    const data = customerDetail?.companySpecificData ?? null;

    if (data && customerAdditionalDetailConfig) {
      Object.keys(data).map((key) => {
        const label = customerAdditionalDetailConfig?.find(
          (item) => snakeToCamel(item)?.key === key
        )?.label;

        defaultAdditionalDetailConfig.attributeList[key] = {
          label,
          value: data?.[key],
          defaultValue: "Unavailable",
        };

        defaultAdditionalDetailConfig.order.push(key);
      });

      setAdditionalDetailConfig(defaultAdditionalDetailConfig);
    }
  }, [customerAdditionalDetailConfig]);

  const getCustomerConfig = () => {
    getCustomerConfigAPI(companyId)
      .then((res: any) => {
        if (res) setCustomerAdditionalDetailConfig(res?.additionalFields);
      })
      .catch(() => {
        setCustomerAdditionalDetailConfig(null);
      });
  };

  return (
    <div className="customer-detail__meta">
      <Section config={customerDetailConfig} data={customerDetail} />

      <CustomerTemplateDetail customerDetail={customerDetail} />

      <Section
        config={additionalDetailConfig}
        data={customerDetail?.companySpecificData}
      />
    </div>
  );
};

export default CustomerMeta;
