export const additionalFieldFormConfig: any = {
  fieldList: {
    differedBilingDate: {
      type: "number",
      key: "differedBillingDate",
      id: "differedBillingDate",
      name: "differedBillingDate",
      label: "Differed billing days",
    },
    deliveryAgentCode: {
      type: "dropdownSearch",
      key: "deliveryAgentCode",
      id: "deliveryAgentCode",
      name: "deliveryAgentCode",
      label: "Override the delivery agent code",
    },
    deliveryAddress: {
      type: "textarea",
      key: "deliveryAddress",
      id: "deliveryAddress",
      name: "deliveryAddress",
      label: "Override the delivery address",
    },
    internalInstruction: {
      type: "textarea",
      key: "internalInstruction",
      id: "internalInstruction",
      name: "internalInstruction",
      label: "Internal instruction to be submitted if any",
    },
  },
};

export const customerDetailFormConfig: any = {
  fieldList: {
    customerSaleId: {
      type: "text",
      key: "customerSaleId",
      id: "customerSaleId",
      name: "customerSaleId",
      label: "Customer sale ID",
      validation: {
        required: "Customer sale ID is required",
      },
    },
  },
};
