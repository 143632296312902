import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { customerDetailConfig } from "./saleDetailConfig";
import { customerDetailFormConfig as formConfig } from "./formConfig";

import { updateCustomerSaleId } from "../../../../Redux/Sale/saleSlicer";

import Section from "../../Reusable/Section";
import Form from "../../Reusable/Form/Form";

const SaleCustomerDetail = ({
  saleDetail,
  isSaving,
  isValidating,
  setActiveEdit,
}) => {
  const {
    setValue,
    handleSubmit,
    control,
    register,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const customerSaleId = saleDetail?.validatedData?.customerSaleId;
  const { isValid, message, value } = customerSaleId ?? {};

  useEffect(() => {
    if (value) {
      setValue("customerSaleId", value);
    }
  }, [value]);

  useEffect(() => {
    if (!isValid && message) {
      if (value) {
        setError("customerSaleId", {
          type: "invalid",
          message: message,
        });
      } else {
        trigger("customerSaleId");
      }
    } else {
      clearErrors();
    }
  }, [isValid, message]);

  const onChange = (key, value) => {
    const saleId = JSON.parse(JSON.stringify(customerSaleId));
    if (saleId) {
      saleId.value = value;
      setActiveEdit("customerSaleId");
      dispatch(updateCustomerSaleId(saleId));
    }
  };

  formConfig.fieldList = {
    ...formConfig.fieldList,
    customerSaleId: {
      ...formConfig.fieldList.customerSaleId,
      isValid,
      isValidating: isSaving || isValidating,
      onChange: onChange,
    },
  };

  return (
    <div className="sale-customer-detail">
      <Section config={customerDetailConfig} data={saleDetail}>
        <div className="sale-customer-detail__field">
          <Form
            config={formConfig}
            control={control}
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={() => {}}
            clearErrors={clearErrors}
            register={register}
          />
        </div>
      </Section>
    </div>
  );
};

export default SaleCustomerDetail;
