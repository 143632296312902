import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { templateCardConfig } from "./templateCardConfig";

import { parseAttribute } from "../../../Utils/parseAttribute";
import { formatDate } from "../../../Utils/formatDate";

import { Company } from "../../../Types/company";
import { User } from "../../../Types/user";
import { Template, TemplateListResponse } from "../../../Types/template";

import { RootState } from "../../../Redux/store";
import { deleteFromTemplateList } from "../../../Redux/Template/templateSlicer";

import { deleteTemplate as deleteTemplateAPI } from "../../../APIs/Template/deleteTemplate";

import Attribute from "../Reusable/Attribute/Attribute";
import Card from "../Reusable/Card/Card";
import Button from "../Reusable/Button";
import Menu from "../Reusable/Menu/Menu";
import Confirmation from "../Reusable/Confirmation/Confirmation";

const TemplateCard: React.FC<{ template: Template }> = ({ template }) => {
  const [attributeList, setAttributeList] = useState(null);
  const [searchParam, setSearchParam] = useSearchParams();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { id: templateId, customerId, status } = template ?? {};

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const user: User = useSelector((state: RootState) => state.user.user);

  const active: TemplateListResponse["active"] = useSelector(
    (state: RootState) => state.template?.list?.active
  );

  useEffect(() => {
    template = {
      ...template,
      createdOn: formatDate(template.createdOn) ?? null,
      lastModifiedOn: formatDate(template.lastModifiedOn) ?? null,
    };

    const list = parseAttribute(template, templateCardConfig);
    setAttributeList(list ?? null);
  }, [template]);

  const deleteTemplate = () => {
    if (company?.id && template.id && template.customerId) {
      const params = { customerId: template.customerId };
      setIsLoading(true);

      deleteTemplateAPI(company?.id, template.id, params)
        .then(() => {
          dispatch(deleteFromTemplateList(template.id));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const copyTemplate = () => {
    const params = {
      modal: "copy",
      target: "template",
      cid: template?.customerId?.toString(),
      tid: template?.id?.toString(),
    };
    setSearchParam(createSearchParams(params));
  };

  const renderAttributeList = attributeList?.map((attribute, index) => {
    return <Attribute key={index} attribute={attribute} />;
  });

  if (!template) return;

  const isDeleteAllowed = !(
    user?.userRole === ("ADMIN_USER" as User["userRole"]) ||
    user?.id === template?.createdBy
  );

  const buttonLabel =
    (status === "configure"
      ? "Configure"
      : status === "edit"
      ? "Resume editing"
      : "View") + " template";

  const menuList = [
    {
      key: "deleteTemplate",
      label: "Delete",
      action: () => setIsConfirm(true),
      isDisabled: isDeleteAllowed,
    },
    {
      key: "copyTemplate",
      label: "Copy",
      isDisabled: status !== "save",
      action: () => copyTemplate(),
    },
  ];

  const confirmationConfig = {
    title: "Confirm to delete template?",
    info: (
      <>
        Are you certain about deleting the template?
        <br />
        Please note that this action cannot be undone.
      </>
    ),

    actionList: {
      proceed: {
        type: "button",
        label: "Delete",
        id: "deleteTemplate",
        style: "primary",
        size: "medium",
        theme: "fail",
        isLoading: isLoading,
        onClick: deleteTemplate,
      },
      cancel: {
        type: "button",
        label: "Cancel",
        id: "cancelTemplateDelete",
        style: "secondary",
        size: "medium",
        onClick: () => setIsConfirm(false),
      },
    },
  };

  return (
    <Card isActive={template?.id === active?.id}>
      <div className="template__card">
        {renderAttributeList}

        <Button
          type="link"
          label={buttonLabel}
          id="template-detail"
          style="primary"
          size="small"
          to={`/template/${templateId}?customer=${customerId}`}
        />
      </div>

      <Menu list={menuList} />

      {isConfirm && <Confirmation config={confirmationConfig} />}
    </Card>
  );
};

export default TemplateCard;
