export const productDetailConfig = {
  label: "Product details",
  attributeList: {
    code: {
      label: "Product code",
      defaultValue: "Unavailable",
    },
    price: {
      label: "Price",
      defaultValue: "Unavailable",
    },
    name: {
      label: "Product name",
      defaultValue: "Unavailable",
    },
    description: {
      label: "Description",
      defaultValue: "Unavailable",
    },
  },
  order: ["code", "price", "name", "description"],
};
