import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../Hooks/useAuth";
import Logo from "../../Protected/Reusable/Logo";
import Login from "./Login";
import Showcase from "./Showcase";

const Onboard = () => {
  const navigate = useNavigate();
  const { session } = useAuth();

  useEffect(() => {
    if (session) {
      navigate("/");
    }
  }, [session]);

  return (
    <div className="onboard h-100 onboard--login">
      <div className="onboard__left h-100">
        <header className="onboard__header">
          <Logo />
        </header>

        <main className="onboard__content h-100-perc">
          <Login />
        </main>
      </div>
      <div className="onboard__right h-100">
        <Showcase />
      </div>
    </div>
  );
};

export default Onboard;
