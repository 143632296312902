import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { formConfig } from "./formConfig";

import { getRegion } from "../../../../../../Utils/labelSystem";

import { RootState } from "../../../../../../Redux/store";

import Form from "../../../../Reusable/Form/Form";

const TextValidation = ({ regexSetting, setRegexSetting, isExpanded }) => {
  const regexSettingRef = useRef(JSON.parse(JSON.stringify(regexSetting)));

  const [fieldList, setFieldList] = useState(null);

  const {
    handleSubmit,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { regionConfig, activeRegion } = useSelector(
    (state: RootState) => state.parser
  );

  useEffect(() => {
    if (regionConfig && activeRegion) {
      setRegexSetting(
        regionConfig?.region?.[activeRegion]?.validationSetting?.regex ?? ""
      );

      regexSettingRef.current =
        regionConfig?.region?.[activeRegion]?.validationSetting?.regex;
    }
  }, [activeRegion]);

  useEffect(() => {
    setFormValue();
  }, [regexSettingRef?.current, activeRegion]);

  const setFormValue = () => {
    const fieldList = {};
    setFieldList(null);

    if (typeof regexSettingRef?.current === "string")
      addToFieldList(fieldList, "regexPattern", regexSettingRef?.current);

    setFieldList(fieldList ?? null);
  };

  const addToFieldList = (fieldList, key, defaultValue) => {
    const region = getRegion(regionConfig, activeRegion);
    const field = formConfig?.[region?.regionType]?.fieldList?.[key] ?? null;

    if (field) {
      field.onChange = (fieldKey, value) => {
        setRegexSetting(value);
      };

      field.key = field.id = field.name = `${activeRegion}_${key}`;
      field.defaultValue = defaultValue;

      fieldList[field.key] = field;
    }
  };

  return (
    <>
      <Form
        config={{ fieldList }}
        control={control}
        handleSubmit={handleSubmit}
        errors={errors}
        clearErrors={clearErrors}
        register={register}
      />

      {isExpanded &&
        (!fieldList || (fieldList && Object.keys(fieldList)?.length === 0)) && (
          <div className="setting__not-applicable">
            Failed to render validation settings due to an error. Please try
            again later or contact support if the issue persists.
          </div>
        )}
    </>
  );
};

export default TextValidation;
