import { useEffect, useState } from "react";

import { saleSummaryConfig } from "./saleDetailConfig";

import { roundNum, snakeToCamel } from "../../../../Utils";

import Section from "../../Reusable/Section";

const SaleSummary = ({ saleDetail }) => {
  const [data, setData] = useState({});
  const table = saleDetail?.validatedData?.saleTable?.value ?? null;

  useEffect(() => {
    if (table) {
      parseSummary(table);
    }
  }, [table]);

  const parseSummary = (table) => {
    const total = {};

    table?.map((row, rowIndex) => {
      if (row?.row && rowIndex !== 0) {
        let quantity = 0;
        const value = {};
        const order = saleSummaryConfig?.order;

        row?.row?.map((cell) => {
          const cellKey = snakeToCamel(cell?.key);
          const cellValue = Number(cell?.value) || 0;

          if (cell.key === "quantity") quantity = cellValue;

          order?.map((key) => {
            if (cellKey === key) {
              value[key] = cellValue;
            }
          });
        });

        order.map((key) => {
          total[key] = (total[key] || 0) + value[key] * quantity;
        });
      }
    });

    Object.keys(total)?.map((key) => {
      total[key] = Number(roundNum(total?.[key] ?? 0)) || 0;
    });

    setData(total);
  };

  return (
    <div className="sale-summary">
      <Section config={saleSummaryConfig} data={data} />
    </div>
  );
};

export default SaleSummary;
