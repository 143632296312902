const Resize = ({ areaId, setResize, onAreaSelect }) => {
  const controlList = [
    "top",
    "right",
    "bottom",
    "left",
    "top-left",
    "top-right",
    "bottom-right",
    "bottom-left",
  ];

  const renderResizeControl = (control, index) => {
    return (
      <div
        key={index}
        className={`resize__control ${
          control ? `resize__control--${control}` : ""
        }`}
        onMouseDown={() => {
          onAreaSelect(areaId);
          setResize(control);
        }}
      >
        <div className="resize__control-button"></div>
      </div>
    );
  };

  const renderControl = controlList?.map((control, index) => {
    return renderResizeControl(control, index);
  });

  return <div className="resize">{renderControl}</div>;
};

export default Resize;
