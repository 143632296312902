import Attribute from "../../Reusable/Attribute/Attribute";
import Button from "../../Reusable/Button";
import Logo from "../../Reusable/Logo";

const PageHeader = ({ actionConfig, headerInfo, isSyncLoading }) => {
  const renderAction = actionConfig?.map((action) => {
    if (!action) return;

    return (
      <Button
        id={action.id}
        key={action.id}
        label={action.label}
        size={action.size}
        style={action.style}
        icon={action.icon}
        isLoading={action.isLoading}
        isDisable={action.isDisable}
        onClick={action.onClick}
        type={action.type}
      />
    );
  });

  return (
    <header className="header h-100-perc">
      <Logo />

      <div
        className={
          "header__info" +
          (!actionConfig || actionConfig?.length === 0
            ? " header__info--align-right"
            : "")
        }
      >
        {headerInfo && !isSyncLoading && (
          <Attribute key={"lastModified"} attribute={headerInfo} />
        )}
      </div>

      {actionConfig?.length > 0 && (
        <div className="header__action">{renderAction}</div>
      )}
    </header>
  );
};

export default PageHeader;
