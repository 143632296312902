import { toCamel } from "snake-camel";
import {
  fetchEventSource,
  EventSourceMessage,
} from "@microsoft/fetch-event-source";

import { StreamMethod } from "../Types/streamingResponse";

import { getBaseUrl } from "../Utils/baseUrl";
import { getSession } from "../Utils/session";

export const eventSource = async (
  method: StreamMethod,
  url: string,
  signal: AbortSignal,
  onOpen: (res: Response) => Promise<void>,
  onMessage: (e: EventSourceMessage) => void,
  onClose: () => void,
  onError?: (err: any) => number | void
) => {
  try {
    const bearerToken = getSession("bearerToken");
    if (!bearerToken) Promise.reject();

    const baseUrl = getBaseUrl();

    fetchEventSource(`${baseUrl}${url}`, {
      method: method,
      headers: {
        Authorization: bearerToken,
      },
      signal: signal,
      onopen: onOpen,
      onmessage: (e: any) => {
        const jsonStr = e?.data?.replace(/^data:\s*/, "");

        if (jsonStr?.trim().length > 0) {
          const obj = toCamel(JSON.parse(jsonStr));
          e.data = obj;
          onMessage(e);
        }
      },
      onclose: onClose,
      onerror: onError,
    });
  } catch (err) {
    onError?.(err);
    console.log(err);
    throw err;
  }
};
