import Action from "./Action";
import Insert from "./Insert";

const Tool: React.FC<{
  rowIndex: number;
  isSaving: boolean;
  isValidating: boolean;
  setActiveEdit: any;
  activeRowList?: [any];
  setActiveRowList?: any;
}> = ({
  rowIndex,
  isSaving,
  isValidating,
  setActiveEdit,
  activeRowList,
  setActiveRowList,
}) => {
  return (
    <div
      className={"tool" + (isSaving || isValidating ? " tool--inactive" : "")}
    >
      <Insert
        rowIndex={rowIndex}
        isSaving={isSaving}
        isValidating={isValidating}
        setActiveEdit={setActiveEdit}
      />

      {rowIndex > 0 && (
        <Action
          rowIndex={rowIndex}
          isSaving={isSaving}
          isValidating={isValidating}
          setActiveEdit={setActiveEdit}
          activeRowList={activeRowList}
          setActiveRowList={setActiveRowList}
        />
      )}
    </div>
  );
};

export default Tool;
