import axios from "axios";

export const createSaleFromEmail = async (companyId, emailId, data, params) => {
  try {
    const res = await axios.put(
      `/company/${companyId}/email/${emailId}`,
      data,
      {
        params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
