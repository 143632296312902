import axios from "axios";

export const getSavedTemplateList = async (companyId, params) => {
  try {
    const res = await axios.get(`/company/${companyId}/template/saved`, {
      params,
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
