import { useState, useEffect, useRef } from "react";

import RegionCard from "./RegionCard";

import { getRegion } from "../../../../../Utils/labelSystem";

const Region = ({
  pageIndex,
  config,
  activeRegion,
  activeArea,
  getRegionIndex,
  setRegionCoordinate,
}) => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [resize, setResize] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const pdfPageRef = useRef(null);

  useEffect(() => {
    if (start && end && start.x0 !== end.x1 && start.y0 !== end.y1) {
      let { x0, y0 } = start ?? {};
      let { x1, y1 } = end ?? {};

      x0 = Math.min(1, Math.max(0, x0));
      y0 = Math.min(1, Math.max(0, y0));
      x1 = Math.min(1, Math.max(0, x1));
      y1 = Math.min(1, Math.max(0, y1));

      setRegionCoordinate(
        activeRegion,
        activeArea,
        {
          x0: Math.min(x0, x1),
          y0: Math.min(y0, y1),
          x1: Math.max(x0, x1),
          y1: Math.max(y0, y1),
        },
        pageIndex
      );
    }
  }, [start, end]);

  useEffect(() => {
    if (resize) {
      const coordinate =
        config?.region?.[activeRegion]?.area?.[activeArea]?.coordinate ?? null;

      const { x0, x1, y0, y1 } = coordinate;
      setStart({ x0, y0 });
      setEnd({ x1, y1 });

      setIsDragging(true);
    }
  }, [resize]);

  const onRegionDrawStart = (e) => {
    const elem = document.querySelectorAll(".resize__control");

    const isResize =
      elem &&
      Array.from(elem).some((el) => el.contains((e?.target as Node) || null));

    if (!isResize) {
      const position = pdfPageRef?.current?.getBoundingClientRect();

      if (position) {
        setIsDragging(true);
        const x0 = (e.clientX - position.left) / position.width;
        const y0 = (e.clientY - position.top) / position.height;

        setStart({ x0, y0 });
        setEnd({ x1: x0, y1: x0 });
      }
    }
  };

  const onRegionDraw = (e) => {
    if (!isDragging) return;

    const position = pdfPageRef?.current?.getBoundingClientRect();

    if (position) {
      const x = (e.clientX - position.left) / position.width;
      const y = (e.clientY - position.top) / position.height;

      setPosition(x, y);
    }
  };

  const setPosition = (x, y) => {
    switch (resize) {
      case "top":
        setStart({ ...start, y0: y });
        break;

      case "right":
        setEnd({ ...end, x1: x });
        break;

      case "bottom":
        setEnd({ ...end, y1: y });
        break;

      case "left":
        setStart({ ...start, x0: x });
        break;

      case "top-left":
        setStart({ x0: x, y0: y });
        break;

      case "top-right":
        setStart({ ...start, y0: y });
        setEnd({ ...end, x1: x });
        break;

      case "bottom-right":
        setEnd({ x1: x, y1: y });
        break;

      case "bottom-left":
        setStart({ ...start, x0: x });
        setEnd({ ...end, y1: y });
        break;

      default:
        setEnd({ x1: x, y1: y });
        break;
    }
  };

  const onRegionDrawEnd = () => {
    if (isDragging) setIsDragging(false);
    if (resize) setResize(null);
  };

  const areaList = config?.region?.[activeRegion]?.area ?? null;
  const renderRegionList =
    areaList &&
    Object.keys(areaList)?.map((key, index) => {
      const area = areaList?.[key];

      if (area?.pageNumber === pageIndex + 1)
        return (
          <RegionCard
            key={index}
            id={key}
            area={area}
            pageIndex={pageIndex}
            activeRegion={activeRegion}
            activeArea={activeArea}
            getRegionIndex={getRegionIndex}
            getRegion={() => getRegion(config, activeRegion)}
            setRegionCoordinate={setRegionCoordinate}
            setResize={setResize}
          />
        );
    });

  return (
    <div
      className="region"
      ref={pdfPageRef}
      onMouseDown={(e) => onRegionDrawStart(e)}
      onMouseMove={(e) => onRegionDraw(e)}
      onMouseUp={() => onRegionDrawEnd()}
    >
      {renderRegionList}
    </div>
  );
};

export default Region;
