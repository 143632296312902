import ParserOutput from "./ParserOutput";
import ParserInput from "./ParserInput";

const Parser: React.FC<{
  getRegionIndex;
}> = ({ getRegionIndex }) => {
  return (
    <div className="parser">
      <ParserInput getRegionIndex={getRegionIndex} />

      <ParserOutput />
    </div>
  );
};

export default Parser;
