import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../Redux/store";

import EditIcon from "../../../../../Assets/Reusable/EditIcon";

import { headerMapCellConfig } from "../../../Template/LabelSystem/labelSystemConfig";

import { getSaleConfigDetail } from "../../../../../Utils/sale";

import useOnClickOutside from "../../../../../Hooks/useOnClickOutside";
import useOnKeyPress from "../../../../../Hooks/useOnKeyPress";

import Interaction from "./Interaction/Interaction";
import Map from "./Interaction/Map";
import Tooltip from "../../Tooltip/Tooltip";
import Pin from "./Pin/Pin";

const Cell = ({
  source,
  cell,
  cellIndex,
  rowIndex,
  isPin,
  isSaving,
  isValidating,
  isEdit,
  setIsEdit,
  activeRowIndex,
  activeCellIndex,
  setActiveRowIndex,
  setActiveCellIndex,
  rowMax,
  columnMax,
  setActiveEdit,
}) => {
  const [value, setValue] = useState(cell?.value ?? null);
  const [cellConfig, setCellConfig] = useState(null);
  const cellRef = useRef();

  const config = useSelector((state: RootState) => state.sale.config);

  const { regionConfig, activeRegion }: any =
    useSelector((state: RootState) => state.parser) ?? {};

  useEffect(() => {
    setValue(cell?.value ?? cellConfig?.defaultValue);
  }, [cell?.value]);

  useEffect(() => {
    if (source === "template" && rowIndex === 0) {
      const value =
        regionConfig?.region?.[activeRegion]?.validationSetting
          ?.headerMapping?.[cellIndex]?.label ?? null;

      setValue(value ?? cellIndex);
    } else if (source === "sale") {
      setValue(value ?? "");
    }
  }, [regionConfig, activeRegion]);

  useEffect(() => {
    if (source && source === "sale") {
      const cellConfig = getSaleConfigDetail(config, cell.key);
      setCellConfig(cellConfig);
    } else if (source === "template" && headerMapCellConfig) {
      setCellConfig(headerMapCellConfig);
    }
  }, [source, headerMapCellConfig]);

  useOnClickOutside(cellRef, () => {
    if (
      isEdit &&
      activeRowIndex === rowIndex &&
      activeCellIndex === cellIndex
    ) {
      setIsEdit(false);
    }
  });

  const handleArrowUp = () => {
    if (!isEdit && activeRowIndex > 0) setActiveRowIndex(activeRowIndex - 1);
  };

  const handleArrowDown = () => {
    if (!isEdit && activeRowIndex < rowMax - 1)
      setActiveRowIndex(activeRowIndex + 1);
  };

  const handleArrowLeft = () => {
    if (!isEdit && activeCellIndex > 0) setActiveCellIndex(activeCellIndex - 1);
  };

  const handleArrowRight = () => {
    if (!isEdit && activeCellIndex < columnMax - 1)
      setActiveCellIndex(activeCellIndex + 1);
  };

  const handleEnter = () => {
    if (isEditable) {
      if (source === "sale") setActiveEdit("saleTable");
      setActiveRowIndex(activeRowIndex);
      setActiveCellIndex(activeCellIndex);
      setIsEdit(!isEdit);
    }
  };

  const isDisableKeyPress = isEdit || (!activeRowIndex && !activeCellIndex);

  useOnKeyPress(
    {
      ArrowUp: handleArrowUp,
      ArrowDown: handleArrowDown,
      ArrowLeft: handleArrowLeft,
      ArrowRight: handleArrowRight,
      Enter: handleEnter,
    },
    isDisableKeyPress
  );

  const isEditable =
    cellConfig?.isEditable &&
    ((source === "sale" && rowIndex > 0) ||
      (source === "template" && rowIndex === 0));

  const enableEdit = () => {
    if (isEditable) {
      if (source === "sale") setActiveEdit("saleTable");
      setActiveRowIndex(rowIndex);
      setActiveCellIndex(cellIndex);
      setIsEdit(true);
    }
  };

  const setActiveCell = () => {
    setActiveRowIndex(rowIndex);
    setActiveCellIndex(cellIndex);
  };

  return (
    <div
      key={`${rowIndex}_${cellIndex}`}
      ref={cellRef}
      className={
        "table__cell" +
        ` table__cell${rowIndex === 0 ? "--th" : "--td"} ` +
        (rowIndex === activeRowIndex && cellIndex === activeCellIndex
          ? " table__cell--active"
          : "") +
        (cellConfig?.cellType ? " validate" : "") +
        (cellConfig?.cellType &&
        (cellConfig?.cellType === "dropdown_with_search" ||
          cellConfig?.cellType === "dropdown_with_choose")
          ? " validate--dropdown"
          : "") +
        (cellConfig?.cellType && cellConfig?.cellType === "text"
          ? " validate--text"
          : "") +
        (cellConfig?.cellType && cellConfig?.cellType === "number"
          ? " validate--number"
          : "") +
        (cellConfig?.isEditable ? " validate--editable" : "") +
        (cell?.messageColorCode
          ? ` validate--message-code validate--message-code-${cell?.messageColorCode}`
          : "") +
        (cell?.type === "map" ? " table__cell--map" : "")
      }
      onClick={() => setActiveCell()}
      onDoubleClick={() => enableEdit()}
    >
      {isEditable && (
        <>
          {!isSaving && !isValidating && (
            <div
              className={
                "validate__trigger" +
                (rowIndex === activeRowIndex && cellIndex === activeCellIndex
                  ? " validate__trigger--visible"
                  : "")
              }
              onClick={() => enableEdit()}
            >
              <EditIcon />
            </div>
          )}

          {(isSaving || isValidating) &&
            rowIndex === activeRowIndex &&
            cellIndex === activeCellIndex && (
              <div className="validate__loading">
                <img />
              </div>
            )}
        </>
      )}

      {value}

      {rowIndex === 0 && cellIndex >= 0 && source === "sale" && (
        <Pin cellIndex={cellIndex} isPin={isPin} />
      )}

      {cell.type === "map" && <Map cell={cell} rowIndex={rowIndex} />}

      {cell?.message && <Tooltip message={cell?.message} />}

      {isEdit &&
        activeRowIndex === rowIndex &&
        activeCellIndex === cellIndex && (
          <Interaction
            source={source}
            value={value}
            cellConfig={cellConfig}
            cellIndex={cellIndex}
            rowIndex={rowIndex}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            activeRowIndex={activeRowIndex}
            activeCellIndex={activeCellIndex}
            setActiveRowIndex={setActiveRowIndex}
            setActiveCellIndex={setActiveCellIndex}
            regionConfig={regionConfig}
            activeRegion={activeRegion}
          ></Interaction>
        )}
    </div>
  );
};

export default Cell;
