export const formConfig = {
  fieldList: {
    name: {
      type: "text",
      key: "name",
      id: "name",
      name: "name",
      label: "Name",
      validation: {
        required: "Name is required",
      },
    },
    userRole: {
      type: "dropdownChoose",
      key: "userRole",
      id: "userRole",
      name: "userRole",
      label: "User role",
      offset: { top: -20 },
      validation: {
        required: "User role is required",
      },
    },
    email: {
      type: "text",
      key: "email",
      id: "email",
      name: "email",
      label: "Email address",
      validation: {
        required: "Email address is required",
        pattern: {
          value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
          message: "Email address is either invalid or incomplete",
        },
      },
    },
    password: {
      type: "password",
      key: "password",
      id: "password",
      name: "password",
      label: "Password",
      validation: {
        required: "Password is required",
        minLength: {
          value: 6,
          message: "Password must be at least 6 characters long",
        },
      },
    },
  },
  actionList: {
    login: {
      type: "submit",
      key: "createUser",
      id: "createUser",
      name: "createUser",
      label: "Create user",
      style: "primary",
      size: "large",
    },
  },
};
