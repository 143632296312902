import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { saleCardConfig } from "./saleCardConfig";

import { parseAttribute } from "../../../Utils/parseAttribute";
import { formatDate } from "../../../Utils/formatDate";

import { Sale, SaleListResponse } from "../../../Types/sale";
import { Company } from "../../../Types/company";

import { RootState } from "../../../Redux/store";
import { deleteFromSaleList } from "../../../Redux/Sale/saleSlicer";

import { deleteSale as deleteSaleAPI } from "../../../APIs/Sale/deleteSale";

import Attribute from "../Reusable/Attribute/Attribute";
import Button from "../Reusable/Button";
import Card from "../Reusable/Card/Card";
import Menu from "../Reusable/Menu/Menu";
import Confirmation from "../Reusable/Confirmation/Confirmation";

const SaleCard: React.FC<{ sale: Sale }> = ({ sale }) => {
  const [attributeList, setAttributeList] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { id: saleId, companySaleId, customerId, status } = sale ?? {};

  const active: SaleListResponse["active"] = useSelector(
    (state: RootState) => state.sale?.list?.active
  );

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  useEffect(() => {
    sale = {
      ...sale,
      companySaleId:
        companySaleId === "" && status === "complete"
          ? "Unavailable"
          : companySaleId,
      createdOn: formatDate(sale.createdOn) ?? null,
      lastModifiedOn: formatDate(sale.lastModifiedOn) ?? null,
    };

    const list = parseAttribute(sale, saleCardConfig);
    setAttributeList(list ?? null);
  }, [sale]);

  const deleteSale = () => {
    if (company?.id && sale.id && sale.customerId) {
      const params = { customerId: sale.customerId, templateId: 1 };
      setIsLoading(true);
      deleteSaleAPI(company?.id, sale.id, params)
        .then(() => {
          dispatch(deleteFromSaleList(sale.id));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const renderAttributeList = attributeList?.map((attribute, index) => {
    return <Attribute key={index} attribute={attribute} />;
  });

  if (!sale) return;

  const buttonLabel = (status === "validate" ? "Validate" : "View") + " sale";

  const menuList =
    sale.status === "complete"
      ? []
      : [
          {
            key: "deleteSale",
            label: "Delete",
            action: () => setIsConfirm(true),
          },
        ];

  const confirmationConfig = {
    title: "Confirm to delete sale?",
    info: (
      <>
        Are you certain about deleting the sale?
        <br />
        Please note that this action cannot be undone.
      </>
    ),

    actionList: {
      proceed: {
        type: "button",
        label: "Delete",
        id: "deleteSale",
        style: "primary",
        size: "medium",
        theme: "fail",
        isLoading: isLoading,
        onClick: deleteSale,
      },
      cancel: {
        type: "button",
        label: "Cancel",
        id: "cancelSaleDelete",
        style: "secondary",
        size: "medium",
        onClick: () => setIsConfirm(false),
      },
    },
  };

  return (
    <Card isActive={sale?.id === active?.id}>
      <div
        className={
          "sale__card" +
          (companySaleId === "" && status === "complete"
            ? " sale__card--no-sale-id"
            : "")
        }
      >
        {renderAttributeList}

        <Button
          type="link"
          label={buttonLabel}
          id="sale-detail"
          style="primary"
          size="small"
          to={`/sale/${saleId}?customer=${customerId}`}
        />
      </div>

      <Menu list={menuList} />

      {isConfirm && <Confirmation config={confirmationConfig} />}
    </Card>
  );
};

export default SaleCard;
