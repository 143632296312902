import { useForm } from "react-hook-form";

import Form from "../../../../Reusable/Form/Form";

const TableListValidation = ({ index, config }) => {
  const {
    handleSubmit,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useForm();

  return (
    <Form
      key={index}
      config={config}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      clearErrors={clearErrors}
      register={register}
    />
  );
};

export default TableListValidation;
