import { getAvatarInitial } from "../../../Utils/avatarInitial";

const UserThumbnail = ({ user }) => {
  return (
    <div className="user__thumbnail">
      {user?.thumbnail ? (
        <img src={user?.thumbnail} alt="Profile thumbnail" />
      ) : (
        <div className="user__avatar-initial">
          {getAvatarInitial(user?.name ?? "")}
        </div>
      )}
    </div>
  );
};

export default UserThumbnail;
