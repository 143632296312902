import { eventSource } from "../eventSource";

export const streamUpdate = (
  companyId,
  eventCategory,
  signal,
  onOpen,
  onMessage,
  onClose,
  onError
) => {
  try {
    const res = eventSource(
      "GET",
      `/company/${companyId}/sse/subscribe?event_category=${eventCategory}`,
      signal,
      onOpen,
      onMessage,
      onClose,
      onError
    );

    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
