import Tooltip from "../../../Tooltip/Tooltip";

const Option: React.FC<{
  item;
  index;
  onClick;
  optionIndex;
  setOptionIndex;
}> = ({ item, index, onClick, optionIndex, setOptionIndex }) => {
  return (
    <button
      className={
        `dropdown__option` +
        (index === optionIndex ? " dropdown__option--active" : "") +
        (item?.isDisabled ? " dropdown__option--disabled" : "")
      }
      key={index}
      onClick={() => onClick(item)}
      onMouseMove={() => setOptionIndex(index)}
    >
      <span className="dropdown__option-label">
        {item.label}
        {item?.isDefault && (
          <span className="dropdown__option--default">(Default)</span>
        )}
      </span>
      <Tooltip message={item.label} theme="dark" />
    </button>
  );
};

export default Option;
