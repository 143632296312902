const PinIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      fill="#9797B8"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M3145 5101 c-51 -23 -101 -79 -114 -128 -25 -88 -7 -129 102 -241
l91 -93 -529 -530 -530 -530 -40 5 c-22 2 -104 10 -182 17 -432 38 -898 -102
-1242 -374 -117 -94 -182 -158 -202 -202 -23 -49 -24 -113 -4 -163 11 -25 173
-194 523 -544 l507 -508 -752 -752 c-687 -689 -752 -757 -763 -798 -42 -159
91 -292 250 -250 41 11 109 76 798 763 l752 752 508 -507 c350 -350 519 -512
544 -523 74 -30 162 -13 222 43 52 49 179 206 232 287 213 328 319 742 285
1118 -6 72 -14 152 -17 177 l-5 45 530 530 530 529 93 -91 c112 -109 153 -127
241 -102 110 30 175 162 132 267 -22 51 -1743 1776 -1800 1803 -51 24 -109 24
-160 0z m790 -1171 l420 -420 -582 -582 c-319 -321 -588 -598 -597 -615 -23
-46 -22 -101 2 -223 55 -276 30 -550 -73 -805 -36 -89 -116 -236 -155 -285
l-19 -25 -976 975 c-536 536 -975 978 -975 982 0 11 113 81 210 129 203 101
385 143 620 143 122 0 179 -5 270 -23 146 -29 190 -29 244 3 22 14 297 282
611 595 313 314 572 571 575 571 3 0 194 -189 425 -420z"
        />
      </g>
    </svg>
  );
};

export default PinIcon;
