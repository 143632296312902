import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  customerDetailConfig,
  templateDetailConfig,
  documentDetailConfig,
} from "./templateDetailConfig";

import { DocumentDetail } from "../../../Types/document";

import { openLink } from "../../../Utils";

import { RootState } from "../../../Redux/store";
import { setTemplateDocument } from "../../../Redux/Template/templateSlicer";
import { setPdfDetail } from "../../../Redux/Pdf/pdfSlicer";

import { getDocumentDetail as getDocumentDetailAPI } from "../../../APIs/Reusable/getDocumentDetail";

import Section from "../Reusable/Section";

const TemplateMeta = ({ company, templateDetail }) => {
  const dispatch = useDispatch();
  const [documentList, setDocumentList] = useState(null);

  const templateDocument: DocumentDetail = useSelector(
    (state: RootState) => state.template.document
  );

  useEffect(() => {
    getDocumentDetail();
  }, [company?.id, templateDetail?.id, templateDetail?.customerId]);

  useEffect(() => {
    if (templateDocument) {
      const document = JSON.parse(JSON.stringify(templateDocument));

      document.action = {
        view: () => dispatch(setPdfDetail(templateDocument)),
        open: () => openLink(templateDocument?.link, true),
      };

      setDocumentList({
        fileList: [document],
      });
    }
  }, [templateDocument]);

  const getDocumentDetail = () => {
    if (company?.id && templateDetail?.id && templateDetail?.customerId) {
      getDocumentDetailAPI(company.id, "template", templateDetail.id, {
        customerId: templateDetail.customerId,
      })
        .then((res) => {
          dispatch(setTemplateDocument(res));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setTemplateDocument(null));
        });
    }
  };

  return (
    <div className="template-detail__meta">
      <Section config={customerDetailConfig} data={templateDetail} />
      <Section config={templateDetailConfig} data={templateDetail} />
      <Section config={documentDetailConfig} data={documentList} />
    </div>
  );
};

export default TemplateMeta;
