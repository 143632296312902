import axios from "axios";

export const saveTemplateRegion = async (
  companyId,
  templateId,
  data,
  params
) => {
  try {
    const res = await axios.put(
      `/company/${companyId}/template/${templateId}/region`,
      data,
      {
        params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
