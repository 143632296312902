import { useState, useEffect } from "react";
import {
  createSearchParams,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../../Types/button";
import { Company } from "../../../../Types/company";
import { Sale } from "../../../../Types/sale";

import { formatDate } from "../../../../Utils/formatDate";

import { RootState } from "../../../../Redux/store";
import {
  setSaleDetail,
  setSaleDocument,
  setSaleConfig,
} from "../../../../Redux/Sale/saleSlicer";

import { getSaleDetail as getSaleDetailAPI } from "../../../../APIs/Sale/getSaleDetail";

import Dashboard from "../../Dashboard/Dashboard";
import SaleMeta from "./SaleMeta";
import SaleData from "./SaleData";
import SaleSummary from "./SaleSummary";
import SaleAdditionalField from "./SaleAdditionalField";
import SaleSubmission from "./SaleSubmission";
import BasicInfoModal from "../../Reusable/Modal/BasicInfoModal/BasicInfoModal";

const SaleDetail = () => {
  const { sid: saleId } = useParams();
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [activeEdit, setActiveEdit] = useState(null);
  const [type, setType] = useState(null);
  const [isSubmitConfirm, setIsSubmitConfirm] = useState(false);

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const saleDetail: Sale = useSelector((state: RootState) => state.sale.detail);

  const [searchParam, setSearchParam] = useSearchParams();
  const modalPath = searchParam?.get("modal");
  const customerId = searchParam?.get("customer");

  const isValid =
    saleDetail?.validatedData?.customerSaleId?.isValid &&
    saleDetail?.validatedData?.saleTable?.isValid;

  useEffect(() => {
    if (company?.id && customerId && saleId)
      getSaleDetail(company.id, customerId, saleId);
  }, [company, customerId, saleId]);

  useEffect(() => {
    return () => {
      dispatch(setSaleDetail(null));
      dispatch(setSaleDocument(null));
      dispatch(setSaleConfig(null));
    };
  }, []);

  const getSaleDetail = (companyId, customerId, saleId) => {
    const params = { customerId: customerId, templateId: 1 };

    getSaleDetailAPI(companyId, saleId, params)
      .then((res: any) => {
        res.createdOn = formatDate(res.createdOn, "long") ?? null;
        res.lastModifiedOn = formatDate(res.lastModifiedOn, "long") ?? null;

        if (res) dispatch(setSaleDetail(res));
      })
      .catch(() => {
        dispatch(setSaleDetail(null));
      });
  };

  const onTemplateEdit = () => {
    const sid = saleDetail?.id?.toString() ?? null;
    const cid = saleDetail?.customerId?.toString() ?? null;

    if (sid) {
      const params = {
        modal: "edit",
        target: "sale",
        cid,
        sid,
      };
      setSearchParam(createSearchParams(params));
    }
  };

  const actionConfig: Button[] =
    !saleDetail || saleDetail?.status === "complete"
      ? null
      : [
          {
            type: "submit",
            label: "Submit as quotation",
            id: "submitAsQuotation",
            style: "secondary",
            size: "medium",
            isDisable: !isValid || saleDetail?.status === "complete",
            onClick: () => {
              setIsSubmitConfirm(true);
              setType("QUOTATION");
            },
          },
          {
            type: "submit",
            label: "Submit as order",
            id: "submitAsOrder",
            style: "primary",
            size: "medium",
            isDisable: !isValid || saleDetail?.status === "complete",
            onClick: () => {
              setIsSubmitConfirm(true);
              setType("ORDER");
            },
          },
        ];

  const headerConfig = {
    breadcrumbList: [
      {
        label: "Sale details",
        children: {
          label: saleDetail?.companySaleId
            ? saleDetail?.companySaleId
            : saleDetail?.companySaleId === "" &&
              saleDetail?.status === "complete"
            ? "Unavailable"
            : "#-----",
          onClick: null,
          isEditable: true,
          link: `/sale/${saleDetail?.id}`,
          isDisabled:
            saleDetail?.companySaleId === "" &&
            saleDetail?.status === "complete",
        },
      },
      ,
    ],
  };

  const headerInfo =
    saleDetail?.status === "complete"
      ? {
          defaultValue: "Unavailable",
          key: "submittedOn",
          label: "Sale submitted on",
          value: formatDate(saleDetail?.lastModifiedOn, "long") ?? null,
        }
      : null;

  return (
    <Dashboard
      actionConfig={actionConfig}
      headerConfig={headerConfig}
      headerInfo={headerInfo}
    >
      <div className="sale-detail h-100-perc scroll">
        <SaleMeta
          company={company}
          saleDetail={saleDetail}
          isSaving={isSaving}
          isValidating={isValidating}
          activeEdit={activeEdit}
          setActiveEdit={setActiveEdit}
          onTemplateEdit={onTemplateEdit}
        />

        <SaleData
          company={company}
          saleDetail={saleDetail}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          isValidating={isValidating}
          setIsValidating={setIsValidating}
          activeEdit={activeEdit}
          setActiveEdit={setActiveEdit}
        />

        <SaleSummary saleDetail={saleDetail} />

        <SaleAdditionalField
          saleDetail={saleDetail}
          isSaving={isSaving}
          activeEdit={activeEdit}
          setActiveEdit={setActiveEdit}
        />

        {isSubmitConfirm && (
          <SaleSubmission
            company={company}
            saleDetail={saleDetail}
            type={type}
            isSubmitConfirm={isSubmitConfirm}
            setIsSubmitConfirm={setIsSubmitConfirm}
          />
        )}
      </div>

      {modalPath && <BasicInfoModal />}
    </Dashboard>
  );
};

export default SaleDetail;
