import axios from "axios";

export const getCompanyDetails = async () => {
  try {
    const res = await axios.get("/company/me");
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
