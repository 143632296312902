import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { headerMapOptionList } from "../../../../Template/LabelSystem/labelSystemConfig";

import { RootState } from "../../../../../../Redux/store";
import { updateSaleTable } from "../../../../../../Redux/Sale/saleSlicer";
import { setRegionConfig } from "../../../../../../Redux/Template/parserSlicer";

import { Company } from "../../../../../../Types/company";

import { getProductList } from "../../../../../../APIs/Product/getProductList";
import { getAdditionalFieldList } from "../../../../../../APIs/Sale/getAdditionalFieldList";

import Form from "../../../Form/Form";

const Interaction: React.FC<{
  source;
  value;
  cellConfig;
  cellIndex;
  rowIndex;
  isEdit;
  setIsEdit;
  activeRowIndex;
  activeCellIndex;
  setActiveRowIndex;
  setActiveCellIndex;
  regionConfig;
  activeRegion;
}> = ({
  source,
  value,
  cellConfig,
  cellIndex,
  rowIndex,
  isEdit,
  setIsEdit,
  activeRowIndex,
  activeCellIndex,
  setActiveRowIndex,
  setActiveCellIndex,
  regionConfig,
  activeRegion,
}) => {
  const [list, setList] = useState([]);
  const [isListOpen, setIsListOpen] = useState(false);

  const {
    setValue,
    handleSubmit,
    control,
    register,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const key = `table_row_${rowIndex}_cell_${cellIndex}`;

  useEffect(() => {
    setValue(key, value ?? "");
    onChange(key, "");
    setFocus(key);
    setIsListOpen(true);
  }, []);

  const onSetIsListOpen = (isListOpen) => {
    if (
      !isListOpen &&
      isEdit &&
      activeRowIndex === rowIndex &&
      activeCellIndex === cellIndex
    ) {
      setIsEdit(false);
      setIsListOpen(false);
    }
  };

  const onChange = (key, searchTerm) => {
    if (cellConfig?.filter === "product_listing") {
      productSearch(key, searchTerm);
    } else if (cellConfig?.filter === "price_override_reason") {
      additionalFieldSearch(key, searchTerm, cellConfig?.filter);
    } else if (cellConfig?.filter === "header_map" && headerMapOptionList) {
      setList([
        { key: cellIndex, label: cellIndex, defaultValue: "" },
        ...headerMapOptionList,
      ]);
    }
  };

  const onSelect = (id, item) => {
    if (item) {
      if (source === "sale") {
        dispatch(
          updateSaleTable({
            rowIndex,
            cellIndex,
            cell: { value: item?.label },
          })
        );
      } else if (source === "template") {
        onSelectHeaderMap(id, item);
        setActiveRowIndex(null);
        setActiveCellIndex(null);
      }

      setValue(id, item?.label);
      setIsEdit(false);
    }
  };

  const onSubmit = () => {
    setIsEdit(false);
  };

  const productSearch = (key, searchTerm) => {
    if (company?.id) {
      const searchTermKey =
        cellConfig?.key === "company_product_code"
          ? "code"
          : cellConfig?.key === "company_product_description"
          ? "description"
          : "";

      const params: any = {
        skip: 0,
        limit: 10000,
        searchTerm,
      };

      if (searchTermKey) params.searchTermKey = searchTermKey;

      getProductList(company?.id, params)
        .then((res: any) => {
          if (res) {
            const list = res?.result?.map((item) => {
              const label = item?.[searchTermKey];

              return { label, key: item?.id };
            });

            setList(list?.length ? list : []);
            setIsListOpen(true);
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  };

  const additionalFieldSearch = (key, searchTerm, fieldKey) => {
    if (company?.id) {
      const params = {
        searchTerm,
        fieldKey,
      };

      getAdditionalFieldList(company?.id, params)
        .then((res: any) => {
          if (res) {
            const list = res?.map((item) => {
              return { label: item.value, key: item?.id };
            });

            setList(list?.length ? list : []);
            setIsListOpen(true);
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  };

  const onSelectHeaderMap = (id, item) => {
    if (regionConfig && item) {
      const config = JSON.parse(JSON.stringify(regionConfig));
      let region = config?.region?.[activeRegion];

      const headerMap = region?.validationSetting?.headerMapping;
      const headerMapping = {};

      headerMap &&
        Object.keys(headerMap)?.map((header) => {
          if (headerMap?.[header]?.key !== item?.key) {
            headerMapping[header] = headerMap?.[header];
          }
        });

      if (
        cellIndex.toString() === item?.key.toString() &&
        Object.prototype.hasOwnProperty.call(
          headerMapping,
          cellIndex.toString()
        )
      ) {
        delete headerMapping[cellIndex.toString()];
      }

      if (region) {
        region = {
          ...region,
          validationSetting: {
            ...region?.validationSetting,
            headerMapping: {
              ...headerMapping,
              ...(cellIndex.toString() !== item?.key.toString()
                ? { [cellIndex.toString()]: { ...item, value: item?.key } }
                : null),
            },
          },
        };
        config.region[activeRegion] = region;
      }

      dispatch(setRegionConfig(config));
    }
  };

  const onChangeTextField = (key, value) => {
    dispatch(updateSaleTable({ rowIndex, cellIndex, cell: { value } }));
  };

  const type =
    cellConfig?.cellType === "dropdown_with_search"
      ? "dropdownSearch"
      : cellConfig?.cellType === "dropdown_with_choose"
      ? "dropdownChoose"
      : cellConfig?.cellType === "text_field"
      ? "text"
      : cellConfig?.cellType === "number_field"
      ? "number"
      : "";

  const scrollSelector =
    source === "sale"
      ? ".sale-detail"
      : source === "template"
      ? ".template-detail"
      : null;

  const scrollElem = scrollSelector && document.querySelector(scrollSelector);

  const dropdownConfig = {
    list,
    isListOpen,
    setIsListOpen: onSetIsListOpen,
    scrollElem,
    onSelect: onSelect,
    onChange: onChange,
  };

  const fieldConfig = {
    onChange: onChangeTextField,
  };

  const cellParam =
    type === "dropdownSearch" || type === "dropdownChoose"
      ? dropdownConfig
      : type === "text" || type === "number"
      ? fieldConfig
      : null;

  const formConfig = {
    fieldList: {
      [key]: {
        type,
        key: key,
        id: key,
        name: key,
        label: "",
        ...cellParam,
      },
    },
  };

  return (
    <Form
      config={formConfig}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      clearErrors={clearErrors}
      register={register}
    />
  );
};

export default Interaction;
