import OpenFileIcon from "../../../../Assets/Reusable/OpenFileIcon";
import CloseIcon from "../../../../Assets/Reusable/CloseIcon";
import ViewFileIcon from "../../../../Assets/Reusable/ViewFileIcon";

import { size } from "../../../../Utils/size";
import { formatDate } from "../../../../Utils/formatDate";

import Card from "./Card";
import Button from "../Button";

const File: React.FC<{ file: any; progress?: Object }> = ({ file }) => {
  if (!file) return;

  return (
    <Card>
      <div className="file">
        <div className="file__title">{file.fileName}</div>
        <div className="file__detail-list">
          {file.fileSize && (
            <div className="file__detail">{size(file.fileSize ?? 0)}</div>
          )}
          {file.uploadedOn && (
            <div className="file__detail">
              {formatDate(file.uploadedOn) ?? null}
            </div>
          )}
        </div>
        <div className="file__action-list">
          {file.action?.["view"] && (
            <div className="file__action  file__action--open">
              <Button
                type="button"
                id="view-btn"
                key="view-btn"
                label=""
                icon={<ViewFileIcon />}
                isLoading={false}
                isDisable={false}
                onClick={file.action?.["view"] ?? null}
              />
            </div>
          )}

          {file.action?.["open"] && (
            <div className="file__action  file__action--open">
              <Button
                type="button"
                id="open-btn"
                key="open-btn"
                label=""
                icon={<OpenFileIcon />}
                isLoading={false}
                isDisable={false}
                onClick={file.action?.["open"] ?? null}
              />
            </div>
          )}

          {file.action?.["close"] && (
            <div className="file__action file__action--close">
              <Button
                type="button"
                id="close-btn"
                key="close-button"
                label=""
                icon={<CloseIcon />}
                isLoading={false}
                isDisable={false}
                onClick={file.action?.["close"] ?? null}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default File;
