import { useForm } from "react-hook-form";

import Form from "../Reusable/Form/Form";

const Filter = ({ filterList }) => {
  const {
    handleSubmit,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useForm();

  return (
    <div className="dashboard__filter">
      <Form
        config={{ fieldList: filterList }}
        isLoading={false}
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={() => {}}
        errors={errors}
        clearErrors={clearErrors}
        register={register}
      />
    </div>
  );
};

export default Filter;
