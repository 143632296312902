import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (timestamp, type?) => {
  if (!timestamp) return;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utctimestamp = dayjs.utc(timestamp);
  const localTimestamp = dayjs.tz(utctimestamp, timezone);

  let format = "D MMM YY, h:mm A";
  if (type === "long") format = "ddd, D MMM YYYY, h:mm:ss A";

  return localTimestamp.format(format);
};
