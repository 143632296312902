import axios from "axios";

export const onSync = async (companyId, type) => {
  try {
    const res = await axios.post(`/company/${companyId}/${type}/sync`);

    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
