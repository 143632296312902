import { useState, useEffect, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../Redux/store";
import {
  clearProductList,
  setProductList,
} from "../../../Redux/Product/productSlicer";

import useOnScroll from "../../../Hooks/useOnScroll";

import { formatDate } from "../../../Utils/formatDate";
import { getListMessage } from "../../../Utils/listMessage";

import { Button } from "../../../Types/button";
import { Company } from "../../../Types/company";
import { ProductListResponse } from "../../../Types/product";

import { getProductList as getProductListAPI } from "../../../APIs/Product/getProductList";
import { onSync as onSyncAPI } from "../../../APIs/Reusable/onSync";
import { getSyncStatus as getSyncStatusAPI } from "../../../APIs/Reusable/getSyncStatus";
import { streamUpdate } from "../../../APIs/Reusable/streamUpdate";

import Dashboard from "../Dashboard/Dashboard";
import ProductList from "./ProductList";

const Product = () => {
  const productRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const controller = new AbortController();
  const signal = controller.signal;

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const { result, total, resultTotal, searchTotal }: ProductListResponse =
    useSelector((state: RootState) => state.product?.list);

  const [searchParam, setSearchParam] = useSearchParams();
  const searchTerm = searchParam?.get("q");

  const [isListLoading, setIsListLoading] = useState<boolean>(false);
  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(false);
  const [syncStatus, setSyncStatus] = useState(null);

  const defaultLimit = 100;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isListErr, setIsListErr] = useState(false);

  useOnScroll(productRef);

  useEffect(() => {
    if (company?.id) {
      setSkip(0);
      setLimit(defaultLimit);
      getProductList(company.id, true);
      getSyncStatus();
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading) {
      setSkip(0);
      dispatch(clearProductList());
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading && skip > 0 && limit > 0 && !isListErr) {
      getProductList(company.id, false);
    }
  }, [company, skip, isListErr]);

  useEffect(() => {
    if (company?.id) {
      streamProductList();
    }

    return () => {
      controller.abort();
    };
  }, [company]);

  useEffect(() => {
    if (company?.id && syncStatus?.status === "COMPLETE") {
      setIsSyncLoading(false);
      setSkip(0);
      dispatch(clearProductList());
      getProductList(company.id, true);
    }
  }, [company, syncStatus]);

  useEffect(() => {
    const elem = productRef?.current ?? null;

    if (elem) {
      elem.addEventListener("scroll", onScroll);

      return () => {
        elem.removeEventListener("scroll", onScroll);
      };
    }
  }, [result, isListErr]);

  useEffect(() => {
    return () => {
      dispatch(clearProductList());
    };
  }, []);

  const onScroll = () => {
    const elem: any = productRef?.current ?? null;
    const offset = 10;

    if (
      elem &&
      elem?.scrollTop + elem?.clientHeight + offset >= elem?.scrollHeight
    ) {
      if (company?.id && isListErr) {
        setIsListErr(false);
      } else {
        setLimit(
          searchTotal <= resultTotal + defaultLimit &&
            searchTotal - resultTotal > 0
            ? searchTotal - resultTotal
            : defaultLimit
        );

        if (searchTotal - resultTotal > 0) {
          setSkip(resultTotal);
        }
      }
    }
  };

  const getSyncStatus = () => {
    getSyncStatusAPI(company.id, "product")
      .then((res: any) => {
        setSyncStatus(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSync = () => {
    if (company?.id) {
      setIsSyncLoading(true);
      onSyncAPI(company.id, "product")
        .then((res: any) => {
          setSyncStatus(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProductList = (companyId, isReset) => {
    setIsListLoading(true);

    const params = {
      skip: isReset ? 0 : skip,
      limit: isReset ? defaultLimit : limit,
      searchTerm,
    };

    getProductListAPI(companyId, params)
      .then((res: any) => {
        if (res) {
          if (!isReset && result?.length) {
            res.result = [...result, ...res.result];
            res.resultTotal += resultTotal;
          }

          dispatch(setProductList(res));
          setIsListErr(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsListErr(true);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const streamProductList = () => {
    streamUpdate(
      company?.id,
      "products",
      signal,
      onOpen,
      onMessage,
      onClose,
      onError
    );
  };

  const onOpen = () => {
    console.log("Stream update opened:");
  };

  const onMessage = (e) => {
    if (e?.event && e?.data) {
      switch (e?.event) {
        case "create":
        case "update":
          setSyncStatus(e?.data);
          break;

        default:
          break;
      }
    }
  };

  const onClose = () => {
    console.log("Stream update closed");
  };

  const onError = (err) => {
    console.log("Stream update error:", err);
  };

  const { status } = syncStatus ?? {};
  const isSync = status === "QUEUE" || status === "PROCESS" || isSyncLoading;

  const actionConfig: Button[] = [
    {
      type: "button",
      label: `Refresh${isSync ? "ing" : ""} products`,
      id: "syncProduct",
      style: "primary",
      size: "medium",
      isLoading: isSync,
      onClick: onSync,
    },
  ];

  const onChange = (key, value) => {
    if (value) {
      setSearchParam(createSearchParams({ q: value }));
    } else {
      searchParam.delete("q");
      setSearchParam(searchParam);
    }
  };

  const message = getListMessage(
    "product",
    isListLoading,
    skip,
    limit,
    resultTotal,
    isListErr,
    searchTotal,
    searchTerm
  );

  const headerConfig = {
    tabList: [
      {
        label: "Products",
        count: total ?? 0,
      },
    ],
    information: {
      message: message,
      isLoading: isListLoading,
      isError: isListErr,
    },
    filterList: {
      productSearch: {
        type: "text",
        key: "productSearch",
        id: "productSearch",
        name: "productSearch",
        label: "Search",
        query: "s",
        defaultValue: searchTerm,
        placeholder: "Search using product details",
        onChange: onChange,
      },
    },
  };

  const headerInfo = {
    defaultValue: "Unavailable",
    key: "lastModifiedOn",
    label: "Last refreshed on",
    value: formatDate(syncStatus?.lastModifiedOn, "long") ?? null,
  };

  return (
    <Dashboard
      actionConfig={actionConfig}
      headerConfig={headerConfig}
      headerInfo={headerInfo}
      isSyncLoading={isSyncLoading}
    >
      <div className="product h-100-perc scroll" ref={productRef}>
        <ProductList productList={result} searchTerm={searchTerm} />
      </div>
    </Dashboard>
  );
};

export default Product;
