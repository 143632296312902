import axios from "axios";

export const getTemplateRegion = async (companyId, templateId, params) => {
  try {
    const res = await axios.get(
      `/company/${companyId}/template/${templateId}/region`,
      { params }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
