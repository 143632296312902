const regex = {
  fieldList: {
    regexPattern: {
      type: "text",
      key: "regexPattern",
      id: "regexPattern",
      name: "regexPattern",
      label: "Regex pattern",
      validation: {
        required: "Regex pattern is required",
      },
    },
  },
};

const indexRegex = {
  fieldList: {
    columnIndex: {
      type: "number",
      key: "columnIndex",
      id: "columnIndex",
      name: "columnIndex",
      label: "Column index",
      validation: {
        required: "Column index is required",
      },
    },
    regexPattern: {
      type: "text",
      key: "regexPattern",
      id: "regexPattern",
      name: "regexPattern",
      label: "Regex pattern",
      validation: {
        required: "Regex pattern is required",
      },
    },
  },
};

export const formConfig = {
  table: indexRegex,
  text: regex,
};

export const defaultTableRegexSetting = {
  columnIndex: "",
  regexPattern: "",
};
