import { useDispatch } from "react-redux";

import PinIcon from "../../../../../../Assets/Reusable/PinIcon";

import { pinColumn } from "../../../../../../Redux/Sale/saleSlicer";

const Pin = ({ cellIndex, isPin }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={"pin" + (isPin ? " pin--active" : "")}
      onClick={() => dispatch(pinColumn({ cellIndex }))}
    >
      <PinIcon />
    </div>
  );
};

export default Pin;
