import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userCardConfig } from "./userCardConfig";

import { parseAttribute } from "../../../Utils/parseAttribute";

import { Company } from "../../../Types/company";
import { User, UserListResponse } from "../../../Types/user";

import { RootState } from "../../../Redux/store";
import { deleteFromUserList } from "../../../Redux/User/userSlicer";

import { deleteUser as deleteUserAPI } from "../../../APIs/User/deleteUser";

import Attribute from "../Reusable/Attribute/Attribute";
import Card from "../Reusable/Card/Card";
import UserThumbnail from "./UserThumbnail";
import Menu from "../Reusable/Menu/Menu";
import Confirmation from "../Reusable/Confirmation/Confirmation";

const UserCard: React.FC<{ user: User }> = ({ user }) => {
  const [attributeList, setAttributeList] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const sessionUser: User = useSelector((state: RootState) => state.user.user);

  const active: UserListResponse["active"] = useSelector(
    (state: RootState) => state.user?.list?.active
  );

  useEffect(() => {
    const list = parseAttribute(user, userCardConfig);
    setAttributeList(list ?? null);
  }, [user]);

  const deleteUser = () => {
    if (company?.id && user.id) {
      setIsLoading(true);

      deleteUserAPI(company?.id, user.id)
        .then(() => {
          dispatch(deleteFromUserList(user.id));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const renderAttributeList = attributeList?.map((attribute, index) => {
    return <Attribute key={index} attribute={attribute} />;
  });

  if (!user) return;

  const isDeleteAllowed = !(
    sessionUser?.userRole === ("ADMIN_USER" as User["userRole"]) &&
    sessionUser?.id !== user?.id
  );

  const menuList = [
    {
      key: "deleteUser",
      label: "Delete",
      action: () => setIsConfirm(true),
      isDisabled: isDeleteAllowed,
    },
  ];

  const isMenuVisible =
    sessionUser?.userRole === ("ADMIN_USER" as User["userRole"]);

  const confirmationConfig = {
    title: "Confirm to delete user?",
    info: (
      <>
        Are you certain about deleting user?
        <br />
        This action cannot be undone.
      </>
    ),

    actionList: {
      proceed: {
        type: "button",
        label: "Delete",
        id: "deleteUser",
        style: "primary",
        size: "medium",
        theme: "fail",
        isLoading: isLoading,
        onClick: deleteUser,
      },
      cancel: {
        type: "button",
        label: "Cancel",
        id: "cancelUserDelete",
        style: "secondary",
        size: "medium",
        onClick: () => setIsConfirm(false),
      },
    },
  };

  return (
    <Card isActive={user?.id === active?.id}>
      <div className="user__card">
        <UserThumbnail user={user} />

        {renderAttributeList}
      </div>

      {isMenuVisible && <Menu list={menuList} />}

      {isMenuVisible && isConfirm && (
        <Confirmation config={confirmationConfig} />
      )}
    </Card>
  );
};

export default UserCard;
