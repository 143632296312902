import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../Hooks/useAuth";

import LogoIcon from "../../../Assets/Reusable/logo.svg";

const Logo = () => {
  const navigate = useNavigate();
  const { session } = useAuth();

  return (
    <div className="logo" onClick={() => navigate(session ? "/" : "/u/login")}>
      <img src={LogoIcon} alt="Logo" />
      <span className="logo__label">Parser</span>
    </div>
  );
};
export default Logo;
