import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  formConfig,
  customerConfig,
  templateConfig,
  templateNameConfig,
  descriptionConfig,
  documentConfig,
} from "./formConfig";

import { Company } from "../../../../../Types/company";
import { TemplateFormField } from "../../../../../Types/template";
import { DocumentDetail } from "../../../../../Types/document";

import { RootState } from "../../../../../Redux/store";
import { setPdfDetail } from "../../../../../Redux/Pdf/pdfSlicer";
import { setSaleDetail } from "../../../../../Redux/Sale/saleSlicer";

import {
  capitalizeString,
  openLink,
  openStaticFile,
} from "../../../../../Utils";

import { getCustomerList } from "../../../../../APIs/Customer/getCustomerList";
import { getSavedTemplateList } from "../../../../../APIs/Template/getSavedTemplateList";

import { getSaleDetail as getSaleDetailAPI } from "../../../../../APIs/Sale/getSaleDetail";
import { getCustomerDetail as getCustomerDetailAPI } from "../../../../../APIs/Customer/getCustomerDetail";
import { getDocumentDetail as getDocumentDetailAPI } from "../../../../../APIs/Reusable/getDocumentDetail";
import { getTemplateDetail as getTemplateDetailAPI } from "../../../../../APIs/Template/getTemplateDetail";

import { createSale } from "../../../../../APIs/Sale/createSale";
import { createSaleFromEmail } from "../../../../../APIs/Email/createSale";
import { createTemplate } from "../../../../../APIs/Template/createTemplate";
import { copyTemplate } from "../../../../../APIs/Template/copyTemplate";
import { editSale } from "../../../../../APIs/Sale/editSale";

import { validateTemplateName } from "../../../../../APIs/Template/validateTemplateName";

import Modal from "../Modal";
import Form from "../../Form/Form";
import Container from "../Container";

const BasicInfoModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [customer, setCustomer] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [isCustomerListOpen, setIsCustomerListOpen] = useState(false);

  const [template, setTemplate] = useState(null);
  const [templateList, setTemplateList] = useState(null);
  const [isTemplateListOpen, setIsTemplateListOpen] = useState(false);

  const [isTemplateNameValid, setIsTemplateNameValid] = useState(null);
  const [isTemplateNameValidating, setTemplateNameValidating] = useState(false);

  const [document, setDocument] = useState(null);

  const [searchParam, setSearchParam] = useSearchParams();
  const path = searchParam?.get("modal");
  const target = searchParam?.get("target");
  const customerId = searchParam?.get("cid");
  const saleId = searchParam?.get("sid");
  const emailId = searchParam?.get("eid");
  const templateId = searchParam?.get("tid");

  const pathList = ["create", "edit", "copy"];
  const targetList = ["sale", "template"];
  const isValidRoute = pathList.includes(path) && targetList.includes(target);

  const {
    setValue,
    getValues,
    resetField,
    handleSubmit,
    trigger,
    control,
    register,
    unregister,
    setError,
    clearErrors,
    getFieldState,
    formState: { errors },
  } = useForm<TemplateFormField>();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  useEffect(() => {
    if (company?.id && customerId) getCustomerDetail(company.id, customerId);
  }, [company, customerId]);

  useEffect(() => {
    if (company?.id && customer?.key)
      getCustomerDetail(company.id, customer?.key);
  }, [company, customer?.key]);

  useEffect(() => {
    const isTouched = getFieldState("templateName").isTouched;

    if (customer && isTouched) {
      trigger("templateName");
    }

    const templateName = getValues("templateName") ?? null;
    if (templateName) {
      onTemplateNameChange("templateName", templateName, customer);
    }
  }, [customer]);

  useEffect(() => {
    initFormConfig();
    updateFormConfig();
  }, [target]);

  useEffect(() => {
    getDocumentDetail("email", emailId, null);
  }, [company?.id && emailId, customerId]);

  useEffect(() => {
    if (
      customer &&
      company?.id &&
      target === "template" &&
      path === "copy" &&
      templateId
    ) {
      getTemplateDetail(company.id, customerId, templateId, customer);
      getDocumentDetail("template", templateId, { customerId: customerId });
    }
  }, [customer, company?.id, customerId, templateId]);

  useEffect(() => {
    if (company?.id && target === "sale" && path === "edit" && saleId) {
      getSaleDetail(company.id, customerId, saleId);
      getDocumentDetail("sale", saleId, { customerId: customerId });
    }
  }, [customer, company?.id, customerId, saleId]);

  useEffect(() => {
    if (!(document || template)) {
      clearErrors("template");
      clearErrors("document");

      setTimeout(() => {
        unregister("template");
        unregister("document");
      }, 0);
    }
  }, [template, document]);

  const onCustomerSearch = (key, searchTerm) => {
    if (company?.id) {
      const params = {
        skip: 0,
        limit: 10000,
        searchTerm: searchTerm,
        searchTermKey: "name",
      };
      getCustomerList(company.id, params)
        .then((res: any) => {
          const list = res?.result?.map((item) => {
            return { label: item?.name, key: item?.id };
          });

          setCustomerList(list?.length ? list : null);
          setIsCustomerListOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onTemplateSearch = (key, searchTerm) => {
    if (company?.id && customer?.key) {
      const params = {
        skip: 0,
        limit: 10000,
        searchTerm: searchTerm,
        customerId: customer?.key,
        searchTermKey: "name",
      };

      getSavedTemplateList(company.id, params)
        .then((res: any) => {
          const list = res?.result?.map((item) => {
            return {
              label: item?.name,
              key: item?.id,
              ...(item?.isDefault && { isDefault: item.isDefault }),
            };
          });

          setTemplateList(list?.length ? list : null);
          setIsTemplateListOpen(true);
        })
        .catch(() => {});
    }
  };

  const onCustomerChange = (key, searchTerm) => {
    onCustomerSearch(key, searchTerm);
    setCustomer(null);
  };

  const onTemplateChange = (key, searchTerm) => {
    onTemplateSearch(key, searchTerm);
    setTemplate(null);
  };

  const onSelect = (key, value) => {
    if (key === "customer") setCustomer(value ?? null);
    else if (key === "template") setTemplate(value ?? null);

    setValue(key, value?.label ?? "");
  };

  const onFocus = (key, searchTerm) => {
    if (key === "customer-search") onCustomerSearch(key, searchTerm);
    else if (key === "template-search") onTemplateSearch(key, searchTerm);
  };

  const onBlur = (key) => {
    if (
      (key === "customer" && !customer) ||
      (key === "template" && !template)
    ) {
      setValue(key, "");
    }
  };

  const onDocumentClose = () => {
    resetField("document");
    setDocument(null);
  };

  const onDocumentChange = (key, value) => {
    const file = value?.[0] ?? null;
    const { name, size, lastModified } = file ?? {};

    let document: DocumentDetail = {
      fileName: name,
      fileSize: size,
      file: file,
      uploadedOn: lastModified,
    };

    document = {
      ...document,
      action: {
        view: () => dispatch(setPdfDetail(document)),
        open: () => openStaticFile(file),
        close: onDocumentClose,
      },
    };

    setDocument(document);
  };

  const onDocumentDrop = (key, value) => {
    setValue("document", value);
    onDocumentChange(key, value);
  };

  const getSaleDetail = (companyId, customerId, saleId) => {
    const params = { customerId: customerId };

    getSaleDetailAPI(companyId, saleId, params)
      .then((res: any) => {
        const template = res?.template;
        if (template?.id) {
          const { id, name } = template;

          setValue("template", name ?? "");
          setTemplate({
            label: name,
            key: id,
          });
        }
      })
      .catch(() => {
        dispatch(setSaleDetail(null));
      });
  };

  const getTemplateDetail = (companyId, customerId, templateId, customer) => {
    const params = { customerId: customerId };

    getTemplateDetailAPI(companyId, templateId, params)
      .then((res: any) => {
        if (res?.name) {
          const name = `Copy of ${res.name}`;

          setValue("templateName", name);
          onTemplateNameChange("templateName", name, customer);
        }
      })
      .catch(() => {});
  };

  const getCustomerDetail = (companyId, customerId) => {
    getCustomerDetailAPI(companyId, customerId)
      .then((res: any) => {
        if (res?.id) {
          setValue("customer", res?.name ?? "");
          setCustomer({ label: res?.name, key: res.id });

          if (res?.template) {
            setValue("template", res.template?.name ?? "");
            setTemplate({ label: res.template?.name, key: res.template?.id });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumentDetail = (target, id, params) => {
    if (company?.id && id) {
      getDocumentDetailAPI(company.id, target, id, params)
        .then((res: any) => {
          res.action = {
            view: () => dispatch(setPdfDetail(res)),
            open: () => openLink(res?.link, true),
          };

          setDocument(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onTemplateNameChange = (key, value, customer?: any) => {
    setTemplateNameValidating(true);

    if (!customer) {
      if (value) {
        setError("templateName", {
          type: "customerMissing",
          message:
            "Choose a customer to check the availability of the template name",
        });
      }

      setTemplateNameValidating(false);
      return;
    }

    if (company?.id && value) {
      const params = {
        customerId: customer?.key,
        name: value,
      };

      validateTemplateName(company.id, params)
        .then((res: any) => {
          if (res?.id) {
            setIsTemplateNameValid(false);
            setError("templateName", {
              type: "usedAlready",
              message: "Template name is already used",
            });

            return;
          }

          setIsTemplateNameValid(true);
          clearErrors("templateName");
        })
        .catch((err) => {
          console.log(err);

          setIsTemplateNameValid(true);
          setError("templateName", {
            type: err?.response?.status ?? 400,
            message: "Template name is invalid",
          });
        })
        .finally(() => {
          setTemplateNameValidating(false);
        });
    } else {
      if (customer) {
        trigger("templateName");
      }
      setTemplateNameValidating(false);
    }
  };

  const onSubmit = (value) => {
    if (
      value?.customer === customer?.label &&
      (target === "template" ? isTemplateNameValid : true)
    ) {
      setIsLoading(true);

      const data = new FormData();

      const params = {
        customerId: customer?.key,
      };

      // sale from email
      if (emailId && target === "sale" && path === "create") {
        const data = {
          assign: true,
          templateId: template?.key,
        };

        onTargetAction(
          createSaleFromEmail(company?.id, emailId, data, params),
          "/email",
          "Sale creation from email failed due to an unspecified error. Please check your input or contact support"
        );

        // sale
      } else if (target === "sale" && path === "create") {
        const isNotEmptySale = value.document?.[0] && template?.key;

        data.append("document", isNotEmptySale ? value.document?.[0] : "");
        data.append("template_id", isNotEmptySale ? template?.key : "");

        onTargetAction(
          createSale(company?.id, data, params),
          "/",
          "Sale creation failed due to an unspecified error. Please check your input or contact support"
        );

        // template
      } else if (target === "template" && path === "create") {
        data.append("document", value.document?.[0]);
        data.append("name", value.templateName);
        data.append("description", value.description);

        onTargetAction(
          createTemplate(company?.id, data, params),
          "/template",
          "Template creation failed due to an unspecified error. Please check your input or contact support"
        );

        // template copy
      } else if (target === "template" && path === "copy") {
        const params = {
          name: value.templateName,
          copyToCustomerId: customer?.key,
          customerId: customerId,
          description: value.description,
        };

        onTargetAction(
          copyTemplate(company?.id, templateId, null, params),
          "/template",
          "Template creation failed due to an unspecified error. Please check your input or contact support"
        );

        // sale edit
      } else if (saleId && target === "sale" && path === "edit") {
        const params = {
          customerId: customerId,
          templateId: template?.key,
        };

        onTargetAction(
          editSale(company?.id, saleId, null, params),
          `/sale/${saleId}?customer=${customerId}`,
          "Sale edit failed due to an unspecified error. Please check your input or contact support"
        );
      }
    } else if (!isTemplateNameValid) {
      onTemplateNameChange("templateName", value.templateName, customer);
    }
  };

  const onTargetAction = (api, successPath, errorMessage) => {
    api
      .then((res) => {
        navigate(successPath);
      })
      .catch((err) => {
        setError("root.serverError", {
          type: err?.response?.status ?? 400,
          message: err?.response?.data?.detail ?? errorMessage,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saleFormConfig = {
    template: {
      ...templateConfig,
      ...(document || template
        ? {
            validation: templateConfig.validation,
            isOptional: false,
          }
        : { validation: null, isOptional: true }),
    },
    document: {
      ...documentConfig,
      ...(document || template
        ? {
            validation: documentConfig.validation,
            isOptional: false,
          }
        : { validation: null, isOptional: true }),
    },
  };

  const initFormConfig = () => {
    console.log("initFormConfig");

    formConfig.fieldList = {
      customer: customerConfig,
      ...(target === "sale" && { template: saleFormConfig?.template }),
      ...(target === "template" && {
        templateName: templateNameConfig,
        description: descriptionConfig,
      }),
      document: documentConfig,
    };

    const action = `${path}${capitalizeString(target)}`;
    const label = `${capitalizeString(
      path === "edit" ? "Save" : path
    )} ${target}`;

    formConfig.actionList = {};
    formConfig.actionList[action] = {
      type: "submit",
      key: action,
      id: action,
      name: action,
      label,
      style: "primary",
      size: "medium",
    };
  };

  const updateFormConfig = () => {
    const config =
      target && target === "sale"
        ? {
            template: {
              ...formConfig.fieldList.template,
              ...saleFormConfig?.template,
              ...{
                list: templateList,
                isListOpen: isTemplateListOpen,
                setIsListOpen: setIsTemplateListOpen,
                onChange: onTemplateChange,
                onSelect: onSelect,
                onFocus: onFocus,
                onBlur: onBlur,
              },
            },
          }
        : target && target === "template"
        ? {
            templateName: {
              ...formConfig.fieldList.templateName,

              isValid: getValues("templateName") ? isTemplateNameValid : null,
              isValidating: isTemplateNameValidating,
              ...{
                onChange: (key, value) =>
                  onTemplateNameChange(key, value, customer),
              },
            },
            description: {
              ...formConfig.fieldList.description,
              onChange: () => trigger("description"),
            },
          }
        : null;

    const documentValidation =
      emailId || templateId || saleId
        ? { validation: null }
        : target && target === "sale"
        ? saleFormConfig?.document
        : null;

    formConfig.fieldList = {
      ...formConfig.fieldList,
      customer: {
        ...formConfig.fieldList.customer,
        ...{
          list: customerList,
          isListOpen: isCustomerListOpen,
          setIsListOpen: setIsCustomerListOpen,
          onChange: onCustomerChange,
          onSelect: onSelect,
          onFocus: onFocus,
          onBlur: onBlur,
        },
      },
      ...config,
      document: {
        ...formConfig.fieldList.document,
        ...documentValidation,
        ...{
          file: document,
          isEditable: !(emailId || templateId || saleId),
          onChange: onDocumentChange,
          onDrop: onDocumentDrop,
        },
      },
    };
  };

  updateFormConfig();

  if (!isValidRoute) return;

  const info =
    target === "sale" && path === "edit"
      ? "Editing the template will clear all the changes you have made to the sale"
      : "Choose the customer, template, and document you want to process";

  return (
    <Modal id="basic-info-modal" navigateTo={-1}>
      <Container
        title={`${path} ${target}`}
        info={info}
        onClose={() => navigate(-1)}
      >
        <div
          className={
            "template-basic-info" +
            (target && target === "sale" ? " template-basic-info--sale" : "") +
            (target && target === "template"
              ? " template-basic-info--template"
              : "")
          }
        >
          {formConfig?.fieldList && (
            <Form
              config={formConfig}
              isLoading={isLoading}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              errors={errors}
              clearErrors={clearErrors}
              register={register}
            />
          )}
        </div>
      </Container>
    </Modal>
  );
};

export default BasicInfoModal;
