import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { formatDate } from "../../../Utils/formatDate";

import { RootState } from "../../../Redux/store";
import { setProductDetail } from "../../../Redux/Product/productSlicer";

import { getProductDetail as getProductDetailAPI } from "../../../APIs/Product/getProductDetail";

import { Company } from "../../../Types/company";
import { Product } from "../../../Types/product";

import Dashboard from "../Dashboard/Dashboard";
import ProductMeta from "./ProductMeta";

const ProductDetail = () => {
  const { pid: productId } = useParams();
  const dispatch = useDispatch();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const productDetail: Product = useSelector(
    (state: RootState) => state.product.detail
  );

  useEffect(() => {
    if (company?.id && productId) getProductDetail(company.id, productId);
  }, [company, productId]);

  useEffect(() => {
    return () => {
      dispatch(setProductDetail(null));
    };
  }, []);

  const getProductDetail = (companyId, productId) => {
    getProductDetailAPI(companyId, productId)
      .then((res: any) => {
        if (res) {
          res.price = `€${res.price}`;

          if (res.companySpecificData.updatedOn) {
            res.companySpecificData.updatedOn =
              formatDate(res.companySpecificData.updatedOn, "long") ?? null;
          }
          dispatch(setProductDetail(res));
        }
      })
      .catch(() => {
        dispatch(setProductDetail(null));
      });
  };

  const headerConfig = {
    breadcrumbList: [
      {
        label: "Product details",
        children: {
          label: productDetail?.code ?? "",
          onClick: null,
          isEditable: true,
          link: `/product/${productDetail?.id}`,
        },
      },
      ,
    ],
  };

  return (
    <Dashboard headerConfig={headerConfig}>
      <div className="product-detail h-100-perc scroll">
        <ProductMeta companyId={company?.id} productDetail={productDetail} />
      </div>
    </Dashboard>
  );
};

export default ProductDetail;
