const ArrowIcon = () => {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      xmlns="http://www.w3.org/2000/svg"
      className="arrow-icon"
      fill="#9797B8"
    >
      <path d="M0.985856 9.71663C1.03771 9.91815 1.2084 10.1021 1.42662 10.194C1.53681 10.239 1.58434 10.2488 1.7399 10.2468C2.0856 10.2468 1.85226 10.4347 4.63942 7.91472C6.32037 6.39454 7.13491 5.64521 7.17164 5.58456C7.25591 5.44173 7.28615 5.27543 7.25158 5.12674C7.23646 5.06022 7.19973 4.96435 7.17164 4.91544C7.13491 4.85479 6.32037 4.10546 4.63942 2.58528C1.85225 0.0653397 2.0856 0.253161 1.7399 0.253161C1.58434 0.253161 1.53681 0.260987 1.4223 0.307943C1.02475 0.472287 0.85622 0.910537 1.05283 1.26857C1.08092 1.31944 1.84793 2.02768 3.248 3.29744L5.40211 5.25L3.248 7.20256C1.84145 8.47819 1.08092 9.18056 1.05283 9.23143C0.972893 9.38012 0.946966 9.56403 0.985856 9.71663Z" />
    </svg>
  );
};

export default ArrowIcon;
