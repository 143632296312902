import React from "react";

import DropdownSearch from "../Form/Element/Dropdown/DropdownSearch";

const Menu: React.FC<{ list: any }> = ({ list }) => {
  const onSelect = (key, value) => {
    value?.action(key, value);
  };

  const renderDropdown = () => {
    return (
      <DropdownSearch
        type={"dropdownChoose"}
        key={"menu"}
        id={"menu"}
        name={"menu"}
        offset={{ top: 30 }}
        label={null}
        list={list}
        onSelect={onSelect}
      >
        <div className="menu__kebab"></div>
      </DropdownSearch>
    );
  };

  if (!list || list.length === 0) return;

  return (
    <div className="menu">
      <div className="menu__option-list">{renderDropdown()}</div>
    </div>
  );
};

export default Menu;
