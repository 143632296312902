const Status = ({ icon, label, value, isLoading, loadingLabel }) => {
  const val = isLoading && loadingLabel ? loadingLabel : value ?? "";

  return (
    <article className={"status" + (isLoading ? " status--loading" : "")}>
      <div className="status__icon">
        {icon && <img src={icon} alt={label} />}
      </div>
      {!isLoading && <span className="status__label">{`${label}:` ?? ""}</span>}
      <span className="status__value">{val}</span>
    </article>
  );
};

export default Status;
