import { useSelector } from "react-redux";

import { RootState } from "../../../Redux/store";

import PageHeader from "./Header/PageHeader";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import { Setting } from "../../../Types/setting";

const Dashboard: React.FC<{
  actionConfig?: any;
  headerConfig?: any;
  headerInfo?: any;
  isSyncLoading?: any;
  children?: any;
}> = ({ actionConfig, headerConfig, headerInfo, isSyncLoading, children }) => {
  const { isCollapse }: Setting = useSelector(
    (state: RootState) => state.setting
  );

  return (
    <div
      className={
        "dashboard h-100-perc" + (isCollapse ? " dashboard--collapse" : "")
      }
    >
      <div className="dashboard__page-header">
        <PageHeader
          actionConfig={actionConfig}
          headerInfo={headerInfo}
          isSyncLoading={isSyncLoading}
        />
      </div>
      <div className="dashboard__sidebar">
        <Sidebar isCollapse={isCollapse} />
      </div>
      <div className="dashboard__content h-100-perc">
        {headerConfig && <Header headerConfig={headerConfig} />}

        {children}
      </div>
    </div>
  );
};

export default Dashboard;
