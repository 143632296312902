import axios from "axios";

import { BearerToken } from "../../Types/session";

export const onUserLogin = async (loginData) => {
  try {
    const res: BearerToken = await axios.post("/login/access-token", loginData);

    if (res?.accessToken) {
      const bearerToken = `${res.tokenType} ${res.accessToken}`;
      return { bearerToken };
    } else return;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
