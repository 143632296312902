import React from "react";
import { Link } from "react-router-dom";

import TickIcon from "../../../Assets/Reusable/TickIcon";

import {
  STYLES,
  TYPES,
  SIZES,
  Button as ButtonType,
  THEMES,
} from "../../../Types/button";

const Button: React.FC<ButtonType> = ({
  id,
  label,
  size,
  style,
  theme,
  icon = undefined,
  isLoading = false,
  isDisable,
  isActive = false,
  onClick,
  isMandatory,
  isCompleted,
  type,
  to,
  state,
  href,
  children,
  ...props
}) => {
  let Elem: any, elementSpecificProps;

  const handleClick = (e) => {
    e.stopPropagation();
    if (!isLoading && !isDisable && onClick) {
      onClick(e);
    }
  };

  const buttonTag = "button";
  const anchorTag = "a";

  switch (type) {
    case "submit":
    case "button":
    case "upload":
      Elem = buttonTag;
      elementSpecificProps = {
        type: TYPES[type] ?? "button",
      };
      break;

    case "link":
      Elem = Link;
      elementSpecificProps = { to };

      if (state) {
        elementSpecificProps = { state, ...elementSpecificProps };
      }
      break;

    case "external":
      Elem = anchorTag;
      elementSpecificProps = { href };
      break;

    default:
      return;
  }

  return (
    <Elem
      id={`${type}-${id}`}
      onClick={handleClick}
      type={type}
      aria-label={label ? `${label} button` : ""}
      className={
        "button" +
        (style ? ` button--${STYLES[style]}` : "") +
        (size ? ` button--${SIZES[size]}` : "") +
        (theme ? ` button--theme-${THEMES[theme]}` : "") +
        (isDisable ? " button--disable" : "") +
        (isLoading ? " button--loading" : "") +
        (isActive ? " button--active" : "") +
        (icon ? " button--icon" : "") +
        (isCompleted ? " button--completed" : "")
      }
      {...elementSpecificProps}
    >
      {icon ?? ""}

      {isLoading && (
        <div className="button__loading">
          <img />
        </div>
      )}

      {isCompleted && (
        <div className="button__completed">
          <TickIcon />
        </div>
      )}

      {label && (
        <span>
          {label}
          {isMandatory && <span className="button__mandatory">*</span>}
        </span>
      )}
      {children}
    </Elem>
  );
};

export default Button;
