import axios from "axios";

export const updateEmail = async (companyId, emailId, data) => {
  try {
    const res = await axios.put(`/company/${companyId}/email/${emailId}`, data);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
