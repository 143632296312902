import { useSelector } from "react-redux";

import ProfileThumbnail from "./../../../../Assets/Dashboard/user-thumbnail.png";

import { RootState } from "../../../../Redux/store";

import { User as UserType } from "../../../../Types/user";
import { Company } from "../../../../Types/company";
import UserThumbnail from "../../User/UserThumbnail";

const Profile = ({ isCollapse }) => {
  const profile: UserType = useSelector((state: RootState) => state.user.user);
  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  return (
    <div className="profile">
      <UserThumbnail user={profile} />

      {!isCollapse && (
        <>
          <span className="profile__name">{profile?.name ?? ""}</span>
          <span
            className={
              "profile__company" +
              (company?.name ? "" : " profile__company--unavailable")
            }
          >
            {profile?.name
              ? company?.name ?? "Company name is not available"
              : ""}
          </span>
        </>
      )}
    </div>
  );
};

export default Profile;
