import { useState, useEffect, useRef } from "react";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import * as pdfjsViewer from "pdfjs-dist/legacy/web/pdf_viewer";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker";

import { pdfViewType } from "../../../../Types/pdf";

import Page from "./Page";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Pdf: React.FC<{
  type: pdfViewType;
  pdfViewerId;
  documentDetail;
  config?;
  zoom;
  activeRegion?;
  activeArea?;
  getRegionIndex?;
  setRegionCoordinate?;
}> = ({
  type,
  pdfViewerId,
  documentDetail,
  config,
  zoom,
  activeRegion,
  activeArea,
  getRegionIndex,
  setRegionCoordinate,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [documentData, setDocumentData] = useState(null);
  const [pdfHeight, setPdfHeight] = useState(800);
  const pdfRef = useRef(null);
  const widthOffset = 35;
  const heightOffset = 90;

  useEffect(() => {
    getDocumentParam();
  }, [documentDetail]);

  useEffect(() => {
    if (pageCount && documentData) renderDocument();
  }, [pageCount, documentData, zoom]);

  const getDocumentParam = () => {
    if (documentDetail?.link)
      getDocumentData({
        url: documentDetail.link,
      });
    else if (documentDetail?.file) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(documentDetail.file);
      fileReader.onload = (e) => {
        const data = e.target.result;
        getDocumentData({ data: data });
      };
    }
  };

  const getDocumentData = (param) => {
    const loadingTask = pdfjsLib.getDocument(param);
    loadingTask?.promise
      ?.then((res) => {
        const count = res?._pdfInfo?.numPages ?? 0;
        setPageCount(count);
        setDocumentData(count ? res : null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderDocument = () => {
    for (let index = 0; index < pageCount; index++) {
      documentData.getPage(index + 1).then((page) => {
        renderPage(page, index);
      });
    }
  };

  const removeChildren = (elem) => {
    for (const child of elem.childNodes) {
      if (child.classList.contains("page")) elem.removeChild(child);
    }
  };

  const renderPage = (page, index) => {
    const container = document.getElementById(
      `pdf_${pdfViewerId}_page_${index}`
    ) as any;

    if (container) {
      removeChildren(container);

      const cssUnit = 96 / 72;
      const defaultScale = 1.0;
      const viewport = page.getViewport({ scale: defaultScale });

      if (index === 0 && viewport) {
        setPdfHeight(viewport.height + heightOffset);
      }

      const scale =
        (type === "draw"
          ? (pdfRef?.current?.offsetWidth - widthOffset) /
            (viewport.width * cssUnit)
          : 1) * zoom;

      const pageView = new pdfjsViewer.PDFPageView({
        container: container,
        id: index,
        scale: scale,
        eventBus: new pdfjsViewer.EventBus(),
        defaultViewport: page.getViewport({ scale: scale }),
        textLayerMode: 0,
        annotationMode: 0,
      });

      pageView.setPdfPage(page);
      return pageView.draw();
    }
  };

  const renderPageList = Array.from({ length: pageCount }, (_, index) => {
    return (
      <Page
        type={type}
        pdfViewerId={pdfViewerId}
        key={index}
        index={index}
        config={config}
        activeRegion={activeRegion}
        activeArea={activeArea}
        getRegionIndex={getRegionIndex}
        setRegionCoordinate={setRegionCoordinate}
      />
    );
  });

  return (
    <div
      className="pdf__scroll scroll"
      ref={pdfRef}
      style={{ maxHeight: `${pdfHeight ?? 0}px` }}
    >
      {renderPageList}
    </div>
  );
};

export default Pdf;
