import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import successIcon from "../../../../Assets/Reusable/valid.svg";
import failIcon from "../../../../Assets/Reusable/error.svg";

import { setSaleDetail } from "../../../../Redux/Sale/saleSlicer";

import { submitSale as submitSaleAPI } from "../../../../APIs/Sale/submitSale";

import Confirmation from "../../Reusable/Confirmation/Confirmation";
import Confetti from "../../Reusable/Confetti/Confetti";
import Modal from "../../Reusable/Modal/Modal";
import Container from "../../Reusable/Modal/Container";

const SaleSubmission = ({
  company,
  saleDetail,
  type,
  isSubmitConfirm,
  setIsSubmitConfirm,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("pending");
  const [error, setError] = useState(null);
  const saleType = type.toLowerCase();

  const icon = {
    success: successIcon,
    fail: failIcon,
    pending: null,
  };

  const title = {
    success:
      saleDetail?.companySaleId !== ""
        ? `Sale ${saleType} #${saleDetail?.companySaleId} has been successfully submitted`
        : `Sale ${saleType} has been submitted successfully but awaits authorization to generate the sale ID`,
    fail: `Sale ${saleType} submission has been failed`,
    pending: `Confirm sale ${saleType} submission`,
  };

  const info = {
    success: (
      <>
        You may close the popup to view the submitted sale {saleType} details,
        <br /> or you can go to all sales to see the sale listing.
      </>
    ),
    fail: (
      <>
        An error occurred while attempting to submit the sale {saleType}.
        <br />
        Please try again. If the issue persists, please contact support.
        {error ? (
          <>
            <br />
            <p className="confirmation__info--error">
              <b>Error details: </b>
              {error}
            </p>
          </>
        ) : null}
      </>
    ),
    pending: (
      <>
        Are you sure you want to submit the sale {saleType}? <br />
        This action may take up to 15 seconds. Please ensure you have verified
        all sale-related information before proceeding, as this action cannot be
        reversed.
      </>
    ),
  };

  const actionList = {
    success: {
      proceed: {
        type: "button",
        label: `Close`,
        id: "close",
        style: "primary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => setIsSubmitConfirm(false),
      },
      cancel: {
        type: "button",
        label: `Go to all sales`,
        id: "goToSale",
        style: "secondary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => navigate("/"),
      },
    },
    fail: {
      proceed: {
        type: "button",
        label: `Try submitting again`,
        id: "trySubmitAgain",
        style: "primary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => submitSale(),
      },
    },
    pending: {
      proceed: {
        type: "button",
        label: isLoading
          ? `Submitting sale ${saleType}`
          : `Submit sale ${saleType}`,
        id: "submit",
        style: "primary",
        size: "medium",
        isLoading: isLoading,
        onClick: () => submitSale(),
      },
    },
  };

  const saleSubmitConfig: any = {
    icon: icon[status || "pending"],
    title: title[status || "pending"],
    info: info[status || "pending"],
    actionList: actionList[status || "pending"],
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitSale = () => {
    if (company?.id && saleDetail?.id && saleDetail?.customerId) {
      const params = {
        customerId: saleDetail.customerId,
        saleType: type,
      };

      setStatus("pending");
      setIsLoading(true);
      setError(null);

      submitSaleAPI(company.id, saleDetail.id, params)
        .then((res) => {
          if (res) {
            dispatch(setSaleDetail(res));
            setStatus("success");
          }
        })
        .catch((err) => {
          setStatus("fail");
          if (err?.response?.data?.detail) setError(err.response.data.detail);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (!isSubmitConfirm) return null;

  return (
    <Modal id="sale-submission" onClose={() => setIsSubmitConfirm(false)}>
      <Container onClose={() => setIsSubmitConfirm(false)}>
        <div
          className={
            "sale-submission" +
            (status === "success" ? " sale-submission--success" : "") +
            (status === "fail" ? " sale-submission--fail" : "")
          }
        >
          <Confirmation config={saleSubmitConfig} />

          {status === "success" && <Confetti />}
        </div>
      </Container>
    </Modal>
  );
};

export default SaleSubmission;
