import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { camelToSnake } from "../../Utils";

import { Sale, SaleListResponse } from "../../Types/sale";

const initialState: {
  list: SaleListResponse;
  detail: Sale;
  document: any;
  config: any;
} = {
  list: {
    result: null,
    total: 0,
    resultTotal: 0,
    searchTotal: 0,
    active: null,
  },
  detail: undefined,
  document: undefined,
  config: undefined,
};

export const saleSlicer = createSlice({
  name: "sale",
  initialState,
  reducers: {
    setSaleList: (state, action: PayloadAction<any>) => {
      if (action.payload) state.list = action.payload;
    },
    setSaleDetail: (state, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
    setSaleDocument: (state, action: PayloadAction<any>) => {
      state.document = action.payload;
    },
    setSaleConfig: (state, action: PayloadAction<any>) => {
      state.config = action.payload;
    },

    clearSaleList: (state) => {
      state.list = initialState.list;
    },

    updateSaleData: (state, action: PayloadAction<any>) => {
      state.detail.validatedData = action.payload;
    },

    updateSaleTable: (state, action: PayloadAction<any>) => {
      const { rowIndex, cellIndex, row, cell, isValid, message } =
        action.payload;

      const table = state.detail?.validatedData?.saleTable?.value;
      if (table?.[rowIndex]?.row?.[cellIndex] && cell) {
        state.detail.validatedData.saleTable.value[rowIndex].row[cellIndex] = {
          ...state.detail.validatedData.saleTable.value[rowIndex].row[
            cellIndex
          ],
          ...cell,
        };
      } else if (table?.[rowIndex] && row) {
        state.detail.validatedData.saleTable.value[rowIndex] = {
          ...state.detail.validatedData.saleTable.value[rowIndex],
          ...row,
        };
      }

      state.detail.validatedData.saleTable.isValid = isValid;
      state.detail.validatedData.saleTable.message = message;
    },

    updateAdditionalField: (state, action: PayloadAction<any>) => {
      if (state.detail?.validatedData?.additionalInputs)
        state.detail.validatedData.additionalInputs = action.payload;
    },

    updateCustomerSaleId: (state, action: PayloadAction<any>) => {
      if (state.detail?.validatedData?.additionalInputs)
        state.detail.validatedData.customerSaleId = action.payload;
    },

    updateIsMap: (state, action: PayloadAction<any>) => {
      const { rowIndex, isMap, isAll } = action.payload;

      if (state.detail.validatedData.saleTable.value[rowIndex]) {
        if (isAll) {
          const table = JSON.parse(
            JSON.stringify(state.detail?.validatedData?.saleTable?.value)
          );

          table.map((row) => {
            if (row) row.isMap = isMap;
          });

          state.detail.validatedData.saleTable.value = table;
        } else {
          state.detail.validatedData.saleTable.value[rowIndex].isMap = isMap;
        }
      }
    },

    addToSaleList: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.list = {
          ...state.list,
          result: state.list?.result
            ? [action.payload, ...state.list.result]
            : [action.payload],
          total: state.list?.result ? state.list.total + 1 : 1,
          resultTotal: state.list?.result ? state.list.resultTotal + 1 : 1,
          searchTotal: state.list?.result ? state.list.searchTotal + 1 : 1,
          active: action.payload,
        };
      }
    },

    deleteFromSaleList: (state, action: PayloadAction<any>) => {
      if (action.payload && state.list?.result) {
        state.list = {
          ...state.list,
          result: state.list.result.filter(
            (item) => item.id !== action.payload
          ),
          total: state.list.total - 1,
          resultTotal: state.list.resultTotal - 1,
          searchTotal: state.list.searchTotal - 1,
        };
      }
    },

    setActiveSale: (state, action: PayloadAction<any>) => {
      if (state?.list) state.list.active = action.payload;
    },

    insertTableRow: (state, action: PayloadAction<any>) => {
      const { rowIndex, row } = action.payload;
      if (row && state.detail?.validatedData?.saleTable?.value)
        state.detail.validatedData.saleTable.value.splice(rowIndex + 1, 0, row);
    },

    deleteTableRow: (state, action: PayloadAction<any>) => {
      const rowList = state.detail?.validatedData?.saleTable?.value;

      if (rowList && action.payload?.length > 0)
        state.detail.validatedData.saleTable.value = rowList.filter(
          (row, index) => !action.payload.includes(index)
        );
    },

    pinColumn: (state, action: PayloadAction<any>) => {
      const { cellIndex } = action.payload;
      const pinList =
        state.detail?.validatedData?.saleTable?.order?.pin ?? null;

      if (pinList) {
        const index = pinList.indexOf(cellIndex);

        if (index > -1) {
          state.detail.validatedData.saleTable.order.pin.splice(index, 1);
          return;
        }
      }

      state.detail.validatedData.saleTable.order = {
        pin: [...(pinList ?? []), cellIndex],
      };
    },
    cleanSaleTable: (state) => {
      const table = JSON.parse(
        JSON.stringify(state.detail?.validatedData?.saleTable?.value)
      );

      if (table) {
        const cleanedTable = table.filter(
          (row) =>
            row?.row?.filter(
              (cell) =>
                cell?.key === camelToSnake("companyProductCode") &&
                cell?.value &&
                cell?.value !== ""
            )?.length > 0
        );

        state.detail.validatedData.saleTable.value = cleanedTable;
      }
    },
  },
});

export const {
  setSaleList,
  setSaleDetail,
  setSaleDocument,
  setSaleConfig,

  clearSaleList,

  updateSaleData,
  updateSaleTable,
  updateAdditionalField,
  updateCustomerSaleId,
  updateIsMap,

  addToSaleList,
  deleteFromSaleList,

  setActiveSale,

  insertTableRow,
  deleteTableRow,

  pinColumn,

  cleanSaleTable,
} = saleSlicer.actions;

export default saleSlicer.reducer;
