const TickIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.27306 0.265654C5.51767 0.435908 3.90906 1.19325 2.66151 2.43787C1.49615 3.60323 0.78284 5.02691 0.521588 6.72358C0.451137 7.19618 0.451137 8.30577 0.521588 8.77837C0.78284 10.4721 1.49028 11.887 2.65564 13.0582C3.821 14.2236 5.24468 14.9369 6.94135 15.1981C7.41396 15.2686 8.52354 15.2686 8.99615 15.1981C10.6899 14.9369 12.1048 14.2295 13.276 13.0641C14.4414 11.8987 15.1547 10.475 15.4159 8.77837C15.4864 8.30577 15.4864 7.19618 15.4159 6.72358C15.1547 5.02984 14.4472 3.61497 13.2819 2.44374C12.1253 1.28424 10.684 0.562131 9.0255 0.309685C8.65857 0.253912 7.65172 0.230429 7.27306 0.265654ZM8.92276 1.48385C10.2789 1.6952 11.4736 2.30284 12.4453 3.27446C13.4257 4.25489 14.0245 5.4408 14.2417 6.82632C14.277 7.05528 14.2916 7.32534 14.2916 7.75098C14.2916 8.17661 14.277 8.44667 14.2417 8.67563C14.0245 10.0612 13.4257 11.2471 12.4453 12.2275C11.4648 13.2079 10.2789 13.8067 8.89341 14.024C8.45016 14.0944 7.48734 14.0944 7.04409 14.024C5.65857 13.8067 4.47266 13.2079 3.49223 12.2275C2.5118 11.2471 1.91298 10.0612 1.69576 8.67563C1.62531 8.23239 1.62531 7.26957 1.69576 6.82632C1.91298 5.4408 2.5118 4.25489 3.49223 3.27446C4.57247 2.19423 5.85525 1.5954 7.45505 1.42808C7.69282 1.4046 8.63803 1.43982 8.92276 1.48385Z" />
      <path d="M10.6398 5.24415C10.5753 5.27057 9.88543 5.93984 8.74062 7.08465L6.9412 8.8782L6.1868 8.12673C5.76997 7.71577 5.38837 7.35765 5.3414 7.33123C5.03905 7.17859 4.69854 7.2872 4.54884 7.58661C4.46077 7.76274 4.4549 7.92419 4.53416 8.08563C4.56938 8.16489 4.95099 8.56411 5.61146 9.22164C6.41576 10.023 6.65647 10.2461 6.74747 10.2755C6.89717 10.3224 7.1232 10.296 7.24355 10.2138C7.38445 10.1169 11.2445 6.2598 11.3531 6.10716C11.4353 5.98974 11.4471 5.95158 11.4471 5.77839C11.4471 5.60814 11.4353 5.56704 11.359 5.45843C11.1946 5.22653 10.8923 5.13554 10.6398 5.24415Z" />
    </svg>
  );
};

export default TickIcon;
