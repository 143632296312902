import CloseIcon from "../../../../Assets/Reusable/CloseIcon";

import Button from "../Button";

const Container: React.FC<{ title?; info?; onClose?; children }> = ({
  title,
  info,
  onClose,
  children,
}) => {
  return (
    <div className="modal__container">
      {onClose && (
        <div className="modal__close">
          <Button
            type="button"
            id="close-btn"
            key="close-button"
            label=""
            icon={<CloseIcon />}
            isLoading={false}
            isDisable={false}
            onClick={onClose}
          />
        </div>
      )}

      {(title || info) && (
        <header className="modal__header">
          {title && <h1 className="modal__title">{title}</h1>}
          {info && <h2 className="modal__info">{info}</h2>}
        </header>
      )}

      <main className="modal__content">{children}</main>
    </div>
  );
};

export default Container;
