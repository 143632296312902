import React from "react";

import Option from "./Option";

const OptionList: React.FC<{
  list: [];
  label: string;
  optionIndex: number;
  setOptionIndex: React.Dispatch<React.SetStateAction<number>>;
  onClick: (item: any) => void;
}> = ({ list, label, optionIndex, setOptionIndex, onClick }) => {
  const renderEmpty = () => {
    return (
      <button
        className="dropdown__option dropdown__option--empty"
        key="option-empty"
      >
        <span className="dropdown__option-label">{`No ${
          label ? label : "match"
        } found!`}</span>
      </button>
    );
  };

  const renderOptionList = list?.map((item, index) => {
    return (
      <Option
        key={index}
        item={item}
        index={index}
        onClick={onClick}
        optionIndex={optionIndex}
        setOptionIndex={setOptionIndex}
      />
    );
  });

  return (
    <>
      {renderOptionList}
      {(!list || list?.length === 0) && renderEmpty()}
    </>
  );
};

export default OptionList;
