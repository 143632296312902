import React, { useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Input as InputType } from "../../../../../Types/input";

import Button from "../../Button";
import File from "../../Card/File";

const Upload: React.FC<InputType> = ({
  type,
  id,
  name,
  label,
  file,
  isEditable,
  isOptional,
  errors,
  onDrop: onFileDrop,
  register,
}) => {
  const fileInputRef = useRef(null);

  const uploadFile = (e) => {
    e.preventDefault();
    fileInputRef?.current?.click();
  };

  const onDrop = useCallback((file) => {
    onFileDrop(name, file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const renderPlaceholder = () => {
    return (
      <label className="upload__placeholder">
        {isDragActive ? (
          <span className="upload__highlight">Drop your document here</span>
        ) : (
          <>
            Drag and Drop or
            <span className="upload__highlight"> Upload </span>
            {file ? "to replace" : ""} your document
          </>
        )}
      </label>
    );
  };

  return (
    <div className={"upload" + (errors?.[name] ? " upload--error" : "")}>
      <label className="upload__label">
        {label}
        <span className="upload__label--optional">
          {isOptional ? " (Optional)" : ""}
        </span>
      </label>

      {file && <File file={file} />}

      {isEditable && (
        <label ref={fileInputRef}>
          <div className="upload__input" {...getRootProps()}>
            <input
              type="file"
              id={id}
              name={name}
              className="file__input"
              multiple={false}
              accept="application/pdf"
              {...register}
              {...getInputProps()}
            />

            <Button type={type} id="upload-file" onClick={uploadFile}>
              <div
                className={
                  "upload__field" +
                  (file ? " upload__field--collapse" : "") +
                  (isDragActive ? " upload__field--drag" : "")
                }
              >
                {renderPlaceholder()}
              </div>
            </Button>
          </div>
        </label>
      )}

      {errors?.[name]?.message && (
        <p className="upload__error">{errors[name].message}</p>
      )}
    </div>
  );
};

export default Upload;
