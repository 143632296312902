import { useState } from "react";

export const useLocalStorage = (keyName: string, defaultValue = undefined) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        if (defaultValue) {
          window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
          return defaultValue;
        } else return undefined;
      }
    } catch (err) {
      console.log(err);
    }
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.log(err);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};
