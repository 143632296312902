import EmptyIcon from "./../../../Assets/Reusable/empty.png";

import Empty from "../Reusable/Empty";
import TemplateCard from "./TemplateCard";

const TemplateList = ({ templateList, searchTerm }) => {
  const renderTemplateList = templateList?.map((template) => {
    if (!template) return;

    return <TemplateCard key={template.id} template={template} />;
  });

  if (!templateList || templateList?.length === 0) {
    const title = searchTerm
      ? `No template matching the customer search '${searchTerm}'`
      : "You don't have any templates!";

    return (
      <Empty
        icon={EmptyIcon}
        alt="Empty screen icon for template"
        title={title}
        info={
          <>
            Click on the <b>Create Template</b> button in the header to create a
            new template
          </>
        }
      />
    );
  }

  return (
    <div className="template__list scroll__child">{renderTemplateList}</div>
  );
};

export default TemplateList;
