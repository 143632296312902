import axios from "axios";

export const deleteUser = async (companyId, userId) => {
  try {
    const res = await axios.delete(`/company/${companyId}/user/${userId}`);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
