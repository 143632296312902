import axios from "axios";

export const createSale = async (companyId, data, params) => {
  try {
    const res = await axios.post(`/company/${companyId}/sale`, data, {
      params,
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
