import { useSelector } from "react-redux";

import { RootState } from "../../../../Redux/store";
import {
  setActiveArea,
  setActiveRegion,
} from "../../../../Redux/Template/parserSlicer";

import Control from "./Control";

const Header: React.FC<{ getRegionIndex }> = ({ getRegionIndex }) => {
  const { regionConfig, activeRegion, activeArea }: any =
    useSelector((state: RootState) => state.parser) ?? {};

  return (
    <header className="label-system__control-list">
      <h4 className="label-system__title">Labelling, Parsing and Validation</h4>
      <Control
        label="Region"
        type="region"
        config={regionConfig}
        activeRegion={activeRegion}
        activeArea={activeArea}
        setActiveRegion={setActiveRegion}
        setActiveArea={setActiveArea}
      />
      <Control
        label="Area"
        type="area"
        config={regionConfig?.region?.[activeRegion]}
        activeRegion={activeRegion}
        activeArea={activeArea}
        setActiveRegion={setActiveRegion}
        setActiveArea={setActiveArea}
        getRegionIndex={getRegionIndex}
      />
    </header>
  );
};

export default Header;
