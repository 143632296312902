import axios from "axios";

export const getSyncStatus = async (companyId, type) => {
  try {
    const res = await axios.get(`/company/${companyId}/${type}/sync`);

    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
