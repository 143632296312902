export const getAvatarInitial = (name) => {
  const nameList = name.split(" ");
  let initial = nameList.map((n) => n[0]?.toUpperCase()).join("");

  if (nameList.length === 1) {
    initial += nameList[0].slice(1, 2).toUpperCase();
  }

  return initial;
};
