import { useDispatch } from "react-redux";

import { updateIsMap } from "../../../../../../Redux/Sale/saleSlicer";

const Map = ({ cell, rowIndex }) => {
  const dispatch = useDispatch();

  const onChange = (isMap) => {
    dispatch(
      updateIsMap({
        rowIndex,
        isMap,
        isAll: rowIndex === 0,
      })
    );
  };

  return (
    <div className="map">
      {rowIndex === 0 && <div className="map__header">Map</div>}

      <label className="checkbox-button">
        <input
          type="checkbox"
          checked={cell?.value}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className="checkbox-button__check"></span>
      </label>
    </div>
  );
};

export default Map;
