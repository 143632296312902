import Input from "./Element/Input";
import TextArea from "./Element/TextArea";
import Upload from "./Element/Upload";
import DropdownSearch from "./Element/Dropdown/DropdownSearch";
import Button from "../Button";

const Form: React.FC<{
  config;
  isLoading?;
  getValues?;
  control?;
  handleSubmit;
  onSubmit?;
  errors;
  clearErrors?;
  register;
}> = ({
  config,
  isLoading,
  getValues,
  control,
  handleSubmit,
  onSubmit,
  errors,
  clearErrors,
  register,
}) => {
  const { fieldList, actionList } = config ?? {};

  const renderInput = (item, field) => {
    return (
      <Input
        type={field.type}
        key={field.key}
        id={field.id}
        name={field.name}
        label={field.label}
        defaultValue={field.defaultValue}
        placeholder={field.placeholder}
        isValidating={field.isValidating}
        isValid={field.isValid}
        isOptional={field.isOptional}
        onFocus={field.onFocus}
        errors={errors}
        register={register(item, {
          ...field.validation,
          onChange: (e) =>
            field?.onChange
              ? field?.onChange(item, e?.target?.value ?? "")
              : null,
        })}
      />
    );
  };

  const renderTextArea = (item, field) => {
    return (
      <TextArea
        type={field.type}
        key={field.key}
        id={field.id}
        name={field.name}
        label={field.label}
        defaultValue={field.defaultValue}
        placeholder={field.placeholder}
        isOptional={field.isOptional}
        errors={errors}
        register={register(item, {
          ...field.validation,
          onChange: (e) =>
            field?.onChange
              ? field?.onChange(item, e?.target?.value ?? "")
              : null,
        })}
      />
    );
  };

  const renderDropdownSearch = (item, field) => {
    return (
      <DropdownSearch
        type={field.type}
        key={field.key}
        id={field.id}
        name={field.name}
        label={field.label}
        offset={field.offset}
        list={field.list}
        isListOpen={field.isListOpen}
        setIsListOpen={field.setIsListOpen}
        scrollElem={field.scrollElem}
        isOptional={field.isOptional}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        onSelect={field.onSelect}
        onFocus={field.onFocus}
        register={register(item, {
          ...field.validation,
          onChange: (e) =>
            field?.onChange
              ? field?.onChange(item, e?.target?.value ?? "")
              : null,
          onBlur: () => (field?.onBlur ? field?.onBlur(item) : null),
        })}
      />
    );
  };

  const renderUpload = (item, field) => {
    return (
      <Upload
        type={field.type}
        key={field.key}
        id={field.id}
        name={field.name}
        label={field.label}
        getValues={getValues}
        errors={errors}
        file={field.file}
        isEditable={field.isEditable}
        isOptional={field.isOptional}
        onDrop={field.onDrop}
        register={register(item, {
          ...field.validation,
          onChange: (e) => field?.onChange(item, e?.target?.files ?? null),
        })}
      />
    );
  };

  const renderButton = (item, field) => {
    return (
      <Button
        type={field.type}
        key={field.key}
        label={field.label}
        id={field.id}
        style={field.style}
        icon={field.icon}
        size={field.size}
        isLoading={isLoading ?? false}
        onClick={field.onClick}
      />
    );
  };

  const renderFieldList =
    fieldList &&
    Object.keys(fieldList)?.map((item) => {
      const field = fieldList[item];

      if (!field || field?.isVisible === false) return;

      switch (field.type) {
        case "text":
        case "number":
        case "password":
          return renderInput(item, field);

        case "textarea":
          return renderTextArea(item, field);

        case "dropdownSearch":
        case "dropdownChoose":
          return renderDropdownSearch(item, field);

        case "upload":
          return renderUpload(item, field);

        default:
          break;
      }
    });

  const renderActionList =
    actionList &&
    Object.keys(actionList)?.map((item) => {
      const action = actionList[item];

      if (!action) return;

      switch (action.type) {
        case "submit":
        case "button":
          return renderButton(item, action);

        default:
          return;
      }
    });

  return (
    <form
      className="form"
      spellCheck="false"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit ?? null)}
    >
      {renderFieldList}

      {errors?.root?.serverError && (
        <p className="form__error">{errors?.root?.serverError?.message}</p>
      )}

      {renderActionList}
    </form>
  );
};

export default Form;
