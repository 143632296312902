import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { defaultTableRegexSetting } from "./formConfig";

import { RootState } from "../../../../../../Redux/store";
import { setRegionConfig } from "../../../../../../Redux/Template/parserSlicer";

import useDebounce from "../../../../../../Hooks/useDebounce";

import { getRegion } from "../../../../../../Utils/labelSystem";

import TableValidation from "./TableValidation";
import TextValidation from "./TextValidation";

const ValidationSetting = () => {
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);
  const [regexSetting, setRegexSetting] = useState(null);

  const { regionConfig, activeRegion } = useSelector(
    (state: RootState) => state.parser
  );

  const region = getRegion(regionConfig, activeRegion);
  const debouncedRegexSetting = useDebounce(regexSetting, 1000);

  useEffect(() => {
    if (debouncedRegexSetting) setValidationSetting();
  }, [debouncedRegexSetting]);

  useEffect(() => {
    if (regionConfig && activeRegion) {
      const region = regionConfig?.region?.[activeRegion];

      setRegexSetting(
        region?.validationSetting?.regex ||
          (region?.regionType === "table"
            ? [defaultTableRegexSetting]
            : region?.regionType === "text"
            ? ""
            : null)
      );
    }
  }, [activeRegion]);

  const setValidationSetting = () => {
    const config = JSON.parse(JSON.stringify(regionConfig));
    if (!config) return;

    const region = config?.region?.[activeRegion];

    let regex = typeof regexSetting === "string" ? regexSetting : null;

    if (typeof regexSetting === "object") {
      regex = regexSetting?.filter((regex) => {
        return (
          Object.prototype.hasOwnProperty.call(regex, "regexPattern") &&
          Object.prototype.hasOwnProperty.call(regex, "columnIndex") &&
          regex?.columnIndex >= 0 &&
          regex?.columnIndex !== ""
        );
      });
    }

    const validationSetting = {
      ...region.validationSetting,
      ...{ regex: regex?.length ? regex : null },
    };

    region.validationSetting = Object.keys(validationSetting).length
      ? validationSetting
      : null;

    dispatch(setRegionConfig(config));
  };

  return (
    <div className="setting--validation">
      <h4 className="setting__title">
        Validation settings
        <div
          className={
            "dropdown__arrow" + (isExpanded ? " dropdown__arrow--expand" : "")
          }
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <img alt="Dropdown arrow" />
        </div>
      </h4>

      {region?.regionType === "text" &&
        isExpanded &&
        (!regexSetting || typeof regexSetting === "string") && (
          <TextValidation
            regexSetting={regexSetting}
            setRegexSetting={setRegexSetting}
            isExpanded={isExpanded}
          />
        )}

      {region?.regionType === "table" &&
        isExpanded &&
        (!regexSetting || typeof regexSetting === "object") && (
          <TableValidation
            regexSetting={regexSetting}
            setRegexSetting={setRegexSetting}
            isExpanded={isExpanded}
          />
        )}
    </div>
  );
};

export default ValidationSetting;
