const Empty = ({ icon, alt, title, info }) => {
  return (
    <div className="empty-panel">
      <div className="empty-panel__icon">
        <img src={icon} alt={alt ?? title} />
      </div>
      <h4 className="empty-panel__title">{title ?? ""}</h4>
      <div className="empty-panel__info">{info ?? ""}</div>
    </div>
  );
};

export default Empty;
