import { configureStore } from "@reduxjs/toolkit";

import settingReducer from "./Setting/settingSlicer";
import saleReducer from "./Sale/saleSlicer";
import emailReducer from "./Email/emailSlicer";
import templateReducer from "./Template/templateSlicer";
import parserReducer from "./Template/parserSlicer";
import productReducer from "./Product/productSlicer";
import customerReducer from "./Customer/customerSlicer";
import userReducer from "./User/userSlicer";
import pdfReducer from "./Pdf/pdfSlicer";

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    sale: saleReducer,
    email: emailReducer,
    template: templateReducer,
    parser: parserReducer,
    product: productReducer,
    customer: customerReducer,
    user: userReducer,
    pdf: pdfReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
