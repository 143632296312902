import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { formConfig } from "./formConfig";

import { Company } from "../../../../Types/company";
import { UserFormField, UserRole } from "../../../../Types/user";

import { RootState } from "../../../../Redux/store";

import { extractEnum } from "../../../../Utils";

import { createUser } from "../../../../APIs/User/createUser";

import Modal from "../../Reusable/Modal/Modal";
import Form from "../../Reusable/Form/Form";
import Container from "../../Reusable/Modal/Container";

const CreateUserModal = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState(null);

  const [searchParam, setSearchParam] = useSearchParams();
  const path = searchParam?.get("modal");
  const target = searchParam?.get("target");

  const pathList = ["create"];
  const targetList = ["user"];
  const isValidRoute = pathList.includes(path) && targetList.includes(target);

  const {
    setValue,
    handleSubmit,
    trigger,
    control,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<UserFormField>();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  useEffect(() => {
    initFormConfig();
  }, [target]);

  useEffect(() => {
    if (userRole) {
      trigger("userRole");
    }
  }, [userRole]);

  const onSelect = (key, value) => {
    if (key === "userRole") setUserRole(value ?? null);
    setValue(key, value?.label ?? "");
  };

  const onSubmit = (value) => {
    if (value?.userRole === userRole?.label) {
      setIsLoading(true);

      const params = {
        name: value?.name,
        userRole: userRole?.key,
        email: value?.email,
        password: value?.password,
      };

      // Create user
      if (target === "user" && path === "create") {
        onTargetAction(
          createUser(company?.id, params),
          "/user",
          "User creation failed due to an unspecified error. Please check your input or contact support"
        );
      }
    }
  };

  const onTargetAction = (api, successPath, errorMessage) => {
    api
      .then(() => {
        navigate(successPath);
      })
      .catch((err) => {
        setError("root.serverError", {
          type: err?.response?.status ?? 400,
          message: err?.response?.data?.detail ?? errorMessage,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const initFormConfig = () => {
    const userRoleList = extractEnum(UserRole);

    formConfig.fieldList = {
      ...formConfig.fieldList,
      userRole: {
        ...formConfig.fieldList.userRole,
        ...{
          list: userRoleList,
          onSelect: onSelect,
          validation: {
            ...formConfig.fieldList.userRole?.validation,
          },
        },
      },
    };
  };

  if (!isValidRoute) return;

  return (
    <Modal id="create-user-modal" navigateTo={-1}>
      <Container
        title={`${path} ${target}`}
        info={
          "Please provide the user's name, email address, password, and select their role to create a new company user. The new user will use their email address and password to log in."
        }
        onClose={() => navigate("")}
      >
        <div className="create-user">
          {formConfig?.fieldList && (
            <Form
              config={formConfig}
              isLoading={isLoading}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              errors={errors}
              clearErrors={clearErrors}
              register={register}
            />
          )}
        </div>
      </Container>
    </Modal>
  );
};

export default CreateUserModal;
