export const formConfig = {
  login: {
    fieldList: {
      username: {
        type: "text",
        key: "username",
        id: "username",
        name: "username",
        label: "Email address",
        validation: {
          required: "Email address is required",
          pattern: {
            value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
            message: "Email address is either invalid or incomplete",
          },
        },
      },
      password: {
        type: "password",
        key: "password",
        id: "password",
        name: "password",
        label: "Password",
        validation: {
          required: "Password is required",
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters long",
          },
        },
      },
    },
    actionList: {
      login: {
        type: "submit",
        key: "login",
        id: "login",
        name: "login",
        label: "Login",
        style: "primary",
        size: "large",
      },
    },
  },
};
