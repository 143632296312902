export const customerCardConfig = {
  attributeList: {
    code: {
      label: "Customer code",
      defaultValue: "Unavailable",
    },
    name: {
      label: "Customer name",
      defaultValue: "Unavailable",
    },
    createdOn: {
      label: "Created on",
      defaultValue: "Unavailable",
    },
    lastModifiedOn: {
      label: "Last modified on",
      defaultValue: "Not yet modified",
    },
    emails: {
      label: "Email IDs",
      defaultValue: "Unavailable",
    },
  },
  order: ["code", "name", "createdOn", "lastModifiedOn", "emails"],
};
