import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  saleDetailConfig,
  documentDetailConfig,
  templateDetailConfig,
} from "./saleDetailConfig";

import { DocumentDetail } from "../../../../Types/document";

import { openLink } from "../../../../Utils";

import { RootState } from "../../../../Redux/store";
import { setSaleDocument } from "../../../../Redux/Sale/saleSlicer";
import { setPdfDetail } from "../../../../Redux/Pdf/pdfSlicer";

import { getDocumentDetail as getDocumentDetailAPI } from "../../../../APIs/Reusable/getDocumentDetail";

import Section from "../../Reusable/Section";
import SaleCustomerDetail from "./SaleCustomerDetail";

const SaleMeta = ({
  company,
  saleDetail,
  isSaving,
  isValidating,
  activeEdit,
  setActiveEdit,
  onTemplateEdit,
}) => {
  const dispatch = useDispatch();
  const [documentList, setDocumentList] = useState(null);

  const saleDocument: DocumentDetail = useSelector(
    (state: RootState) => state.sale.document
  );

  useEffect(() => {
    getDocumentDetail();
  }, [company?.id, saleDetail?.id, saleDetail?.customerId]);

  useEffect(() => {
    if (saleDocument) {
      const document = JSON.parse(JSON.stringify(saleDocument));

      document.action = {
        view: () => dispatch(setPdfDetail(saleDocument)),
        open: () => openLink(saleDocument?.link, true),
      };

      setDocumentList({
        fileList: [document],
      });
    }
  }, [saleDocument]);

  const getDocumentDetail = () => {
    if (company?.id && saleDetail?.id && saleDetail?.customerId) {
      getDocumentDetailAPI(company.id, "sale", saleDetail.id, {
        customerId: saleDetail.customerId,
      })
        .then((res) => {
          dispatch(setSaleDocument(res));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setSaleDocument(null));
        });
    }
  };

  return (
    <div className="sale-detail__meta">
      <div className="sale-detail__meta--left">
        <SaleCustomerDetail
          saleDetail={saleDetail}
          isSaving={isSaving && activeEdit === "customerSaleId"}
          isValidating={isValidating && activeEdit === "customerSaleId"}
          setActiveEdit={setActiveEdit}
        />

        <Section config={saleDetailConfig} data={saleDetail} />
      </div>

      <div className="sale-detail__meta--right">
        <Section
          config={templateDetailConfig}
          data={saleDetail?.template}
          onEdit={onTemplateEdit}
        />
        <Section config={documentDetailConfig} data={documentList} />
      </div>
    </div>
  );
};

export default SaleMeta;
