import React from "react";

import { Input as InputType } from "../../../../../Types/input";

const TextArea: React.FC<InputType> = ({
  type,
  id,
  name,
  label,
  value,
  defaultValue,
  placeholder,
  size,
  icon,
  isEditable = true,
  isOptional,
  onClick,
  errors,
  register,
}) => {
  return (
    <div
      className={
        "input" +
        (icon ? " input--with-icon" : "") +
        (errors?.[name] ? " input--error" : "") +
        (isEditable ? "" : " input--not-editable")
      }
    >
      <label className="input__label label">
        {label}
        <span className="input__label--optional">
          {isOptional ? " (Optional)" : ""}
        </span>
      </label>

      <textarea
        className="input__field input__field--textarea"
        id={id}
        type={type}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onClick={onClick}
        {...register}
      />

      {errors?.[name]?.message && (
        <p className="input__error">{errors[name].message}</p>
      )}
    </div>
  );
};

export default TextArea;
