import { useState, useEffect, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../Redux/store";
import {
  setSaleList,
  clearSaleList,
  setActiveSale,
  addToSaleList,
} from "../../../Redux/Sale/saleSlicer";

import useOnScroll from "../../../Hooks/useOnScroll";

import { getListMessage } from "../../../Utils/listMessage";

import { Button } from "../../../Types/button";
import { Company } from "../../../Types/company";
import { SaleListResponse } from "../../../Types/sale";

import { getSaleList as getSaleListAPI } from "../../../APIs/Sale/getSaleList";
import { streamUpdate } from "../../../APIs/Reusable/streamUpdate";

import BasicInfoModal from "./../Reusable/Modal/BasicInfoModal/BasicInfoModal";
import Dashboard from "../Dashboard/Dashboard";
import SaleList from "./SaleList";

const Sale = () => {
  const saleRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const controller = new AbortController();
  const signal = controller.signal;

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const { result, total, resultTotal, searchTotal }: SaleListResponse =
    useSelector((state: RootState) => state.sale?.list);

  const [searchParam, setSearchParam] = useSearchParams();
  const modalPath = searchParam?.get("modal");
  const searchTerm = searchParam?.get("q");

  const [isListLoading, setIsListLoading] = useState<boolean>(true);

  const defaultLimit = 100;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isListErr, setIsListErr] = useState(false);

  useOnScroll(saleRef);

  const createSale = () => {
    const params = { modal: "create", target: "sale" };
    setSearchParam(createSearchParams(params));
  };

  useEffect(() => {
    if (company?.id) {
      setSkip(0);
      setLimit(defaultLimit);
      getSaleList(company.id, true);
    }
  }, [company, searchTerm]);

  useEffect(() => {
    if (company?.id && !isListLoading && !isListErr) {
      getSaleList(company.id, false);
    }
  }, [company, skip, isListErr]);

  useEffect(() => {
    return () => {
      dispatch(clearSaleList());
    };
  }, []);

  useEffect(() => {
    if (company?.id) {
      streamSaleList();
    }

    return () => {
      controller.abort();
    };
  }, [company]);

  useEffect(() => {
    const elem = saleRef?.current ?? null;

    if (elem) {
      elem.addEventListener("scroll", onScroll);

      return () => {
        elem.removeEventListener("scroll", onScroll);
      };
    }
  }, [result, isListErr]);

  const onScroll = () => {
    const elem: any = saleRef?.current ?? null;
    const offset = 10;

    if (
      elem &&
      elem?.scrollTop + elem?.clientHeight + offset >= elem?.scrollHeight
    ) {
      if (company?.id && isListErr) {
        setIsListErr(false);
      } else {
        setLimit(
          searchTotal <= resultTotal + defaultLimit &&
            searchTotal - resultTotal > 0
            ? searchTotal - resultTotal
            : defaultLimit
        );

        if (searchTotal - resultTotal > 0) {
          setSkip(resultTotal);
        }
      }
    }
  };

  const getSaleList = (companyId, isReset) => {
    setIsListLoading(true);

    const params = {
      skip: isReset ? 0 : skip,
      limit: isReset ? defaultLimit : limit,
      searchTerm,
    };

    getSaleListAPI(companyId, params)
      .then((res: any) => {
        if (res) {
          if (!isReset && result?.length) {
            res.result = [...result, ...res.result];
            res.resultTotal += resultTotal;
          }

          dispatch(setSaleList(res));
          setIsListErr(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsListErr(true);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const streamSaleList = () => {
    streamUpdate(
      company?.id,
      "sales",
      signal,
      onOpen,
      onMessage,
      onClose,
      onError
    );
  };

  const onOpen = () => {
    console.log("Stream update opened:");
  };

  const onMessage = (e) => {
    if (e?.event && e?.data) {
      switch (e?.event) {
        case "create":
          dispatch(addToSaleList(e?.data));

          setTimeout(() => {
            dispatch(setActiveSale(null));
          }, 3000);
          break;

        default:
          break;
      }
    }
  };

  const onClose = () => {
    console.log("Stream update closed");
  };

  const onError = (err) => {
    console.log("Stream update error:", err);
  };

  const actionConfig: Button[] = [
    {
      type: "submit",
      label: "Create sale",
      id: "createSale",
      style: "primary",
      size: "medium",
      onClick: createSale,
    },
  ];

  const onChange = (key, value) => {
    if (value) {
      setSearchParam(createSearchParams({ q: value }));
    } else {
      searchParam.delete("q");
      setSearchParam(searchParam);
    }
  };

  const message = getListMessage(
    "sale",
    isListLoading,
    skip,
    limit,
    resultTotal,
    isListErr,
    searchTotal,
    searchTerm
  );

  const headerConfig = {
    tabList: [
      {
        label: "Sales",
        count: total ?? 0,
      },
    ],
    information: {
      message: message,
      isLoading: isListLoading,
      isError: isListErr,
    },
    filterList: {
      saleSearch: {
        type: "text",
        key: "saleSearch",
        id: "saleSearch",
        name: "saleSearch",
        label: "Search",
        query: "s",
        defaultValue: searchTerm,
        placeholder: "Search using sale or customer details",
        onChange: onChange,
      },
    },
  };

  return (
    <Dashboard actionConfig={actionConfig} headerConfig={headerConfig}>
      <div className="sale h-100-perc scroll" ref={saleRef}>
        <SaleList saleList={result} searchTerm={searchTerm} />
      </div>

      {modalPath && <BasicInfoModal />}
    </Dashboard>
  );
};

export default Sale;
