import { useState, useEffect } from "react";

import { productCardConfig } from "./productCardConfig";

import { parseAttribute } from "../../../Utils/parseAttribute";

import { Product } from "../../../Types/product";

import Attribute from "../Reusable/Attribute/Attribute";
import Button from "../Reusable/Button";
import Card from "../Reusable/Card/Card";

const ProductCard: React.FC<{ product: Product }> = ({ product }) => {
  const [attributeList, setAttributeList] = useState(null);

  const { id: productId } = product ?? {};

  useEffect(() => {
    product = {
      ...product,
      price: `€${product.price}`,
    };

    const list = parseAttribute(product, productCardConfig);
    setAttributeList(list ?? null);
  }, [product]);

  const renderAttributeList = attributeList?.map((attribute, index) => {
    return <Attribute key={index} attribute={attribute} />;
  });

  if (!product) return;

  return (
    <Card>
      <div className="product__card">
        {renderAttributeList}

        <Button
          type="link"
          label="View product"
          id="view-product"
          style="primary"
          size="small"
          to={`/product/${productId}`}
        />
      </div>
    </Card>
  );
};

export default ProductCard;
