import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { Customer, CustomerListResponse } from "../../Types/customer";

const initialState: {
  list: CustomerListResponse;
  detail: Customer;
} = {
  list: {
    result: null,
    total: 0,
    resultTotal: 0,
    searchTotal: 0,
  },
  detail: undefined,
};

export const customerSlicer = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerList: (state, action: PayloadAction<any>) => {
      if (action.payload) state.list = action.payload;
    },
    setCustomerDetail: (state, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
    clearCustomerList: (state) => {
      state.list = initialState.list;
    },
  },
});

export const { setCustomerList, setCustomerDetail, clearCustomerList } =
  customerSlicer.actions;

export default customerSlicer.reducer;
