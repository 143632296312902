import { useNavigate } from "react-router-dom";
import { useRef, PropsWithChildren } from "react";

import { usePortal } from "../../../../Hooks/usePortal";
import useOnClickOutside from "../../../../Hooks/useOnClickOutside";
import useOnKeyPress from "../../../../Hooks/useOnKeyPress";

import { Modal as ModalProps } from "../../../../Types/modal";

import Overlay from "./Overlay";

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  id,
  isModalCloseAllowed,
  setIsModalCloseAllowed,
  navigateTo,
  onClose,
  ...props
}) => {
  const show = true;

  const modalRef = useRef<HTMLDivElement>(null);
  const Portal = usePortal({ elem: "modal", id, show });
  const navigate = useNavigate();

  const handleClickOutside = () => {
    if (isModalCloseAllowed === false) return;
    onClose && onClose();
    if (navigateTo) navigate(navigateTo);
  };

  useOnClickOutside(modalRef, handleClickOutside);
  useOnKeyPress({ Escape: handleClickOutside });

  return (
    <Portal>
      <Overlay>
        <div className="modal" ref={modalRef}>
          {props.children}
        </div>
      </Overlay>
    </Portal>
  );
};

export default Modal;
