export const userCardConfig = {
  attributeList: {
    name: {
      label: "Name",
      defaultValue: "Unavailable",
    },
    userRole: {
      label: "Role",
      defaultValue: "Unavailable",
    },
    email: {
      label: "Email ID",
      defaultValue: "Unavailable",
    },
  },
  order: ["name", "userRole", "email"],
};
