import { Routes, Route, useLocation } from "react-router-dom";

import Onboard from "./Components/Public/Onboard/Onboard";
import Protected from "./Components/Protected/Protected";

import "./APIs/axiosInterceptors";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/u/login" element={<Onboard />} />
        <Route path="/*" element={<Protected />} />
      </Routes>
    </>
  );
};

export default App;
