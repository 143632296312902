import { useState, useEffect } from "react";

import { customerCardConfig } from "./customerCardConfig";

import { parseAttribute } from "../../../Utils/parseAttribute";
import { formatDate } from "../../../Utils/formatDate";

import { Customer } from "../../../Types/customer";

import Attribute from "../Reusable/Attribute/Attribute";
import Button from "../Reusable/Button";
import Card from "../Reusable/Card/Card";

const CustomerCard: React.FC<{ customer: Customer }> = ({ customer }) => {
  const [attributeList, setAttributeList] = useState(null);

  const { id: customerId } = customer ?? {};

  useEffect(() => {
    customer = {
      ...customer,
      createdOn: formatDate(customer.createdOn) ?? null,
      lastModifiedOn: formatDate(customer.lastModifiedOn) ?? null,
    };

    const list = parseAttribute(customer, customerCardConfig);
    setAttributeList(list ?? null);
  }, [customer]);

  const renderAttributeList = attributeList?.map((attribute, index) => {
    return <Attribute key={index} attribute={attribute} />;
  });

  if (!customer) return;

  return (
    <Card>
      <div className="customer__card">
        {renderAttributeList}

        <Button
          type="link"
          label="View customer"
          id="view-customer"
          style="primary"
          size="small"
          to={`/customer/${customerId}?customer=${customerId}`}
        />
      </div>
    </Card>
  );
};

export default CustomerCard;
