import { useCallback, useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { PortalProps, PortalType } from "../Types/portal";

export const usePortal = ({ elem, id, show }: PortalProps) => {
  let newPortalElem: HTMLDivElement;
  const [portal, setPortal] = useState<PortalType>({
    render: () => null,
    remove: () => null,
  });

  useEffect(() => {
    if (show) {
      newPortalElem = document.createElement("div");
      newPortalElem.setAttribute("id", elem);
      newPortalElem.setAttribute(
        "class",
        `portal ${elem && id ? `portal--${id}` : ""}`
      );
      document.body.appendChild(newPortalElem);
      return () => {
        newPortalElem.remove();
      };
    }
  }, [show]);

  const createPortal = useCallback(
    (newPortalElem) => {
      if (show) {
        const Portal = ({ children }) =>
          ReactDOM.createPortal(children, newPortalElem);
        const remove = () => ReactDOM.unmountComponentAtNode(newPortalElem);
        return { render: Portal, remove };
      }
    },
    [show]
  );

  useEffect(() => {
    if (show) {
      if (newPortalElem) portal.remove();
      const newPortal = createPortal(newPortalElem);
      setPortal(newPortal);
      return () => {
        newPortal.remove();
      };
    }
  }, [newPortalElem, show]);

  return portal.render;
};
