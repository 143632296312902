import LabelSetting from "./LabelSetting/LabelSetting";
import ValidationSetting from "./ValidationSetting/ValidationSetting";

const Setting = () => {
  return (
    <div className="setting">
      <LabelSetting />
      <ValidationSetting />
    </div>
  );
};

export default Setting;
