import axios from "axios";

export const getAdditionalFieldList = async (companyId, params, signal?) => {
  try {
    const res = await axios.get(`/company/${companyId}/sale/additional-field`, {
      params,
      signal,
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
