export const customerDetailConfig = {
  label: "Customer details",
  attributeList: {
    code: {
      label: "Customer code",
      defaultValue: "Unavailable",
    },
    name: {
      label: "Customer name",
      defaultValue: "Unavailable",
    },
    createdOn: {
      label: "Created on",
      defaultValue: "Unavailable",
    },
    lastModifiedOn: {
      label: "Last modified on",
      defaultValue: "Unavailable",
    },
    emails: {
      label: "Email IDs",
      defaultValue: "Unavailable",
    },
  },
  order: ["code", "name", "createdOn", "lastModifiedOn", "emails"],
};

export const templateDetailConfig = {
  label: "Default template details",
  attributeList: {
    name: {
      label: "Template name",
      defaultValue: "Unavailable",
    },
    createdByName: {
      label: "Created by",
      defaultValue: "Unavailable",
    },
    description: {
      label: "Description",
      defaultValue: "Unavailable",
    },
  },
  order: ["name", "createdByName", "description"],
};
