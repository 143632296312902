import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { templateDetailConfig } from "./customerDetailConfig";
import { templateDetailFormConfig as formConfig } from "./formConfig";

import { setCustomerDetail } from "../../../Redux/Customer/customerSlicer";

import { getSavedTemplateList } from "../../../APIs/Template/getSavedTemplateList";
import { updateDefaultTemplate } from "../../../APIs/Customer/updateDefaultTemplate";

import Section from "../Reusable/Section";
import Form from "../Reusable/Form/Form";

const CustomerTemplateDetail = ({ customerDetail }) => {
  const {
    setValue,
    handleSubmit,
    control,
    register,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm();

  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);

  const [template, setTemplate] = useState(null);
  const [templateList, setTemplateList] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    onTemplateSearch("template-search", "");
  }, [isEdit]);

  const getTemplateDetail = (companyId, customerId, templateId) => {
    const data = { templateId };

    clearErrors();

    updateDefaultTemplate(companyId, customerId, data)
      .then((res: any) => {
        if (res?.id && res?.template) {
          dispatch(setCustomerDetail(res));
          setIsEdit(false);
        }
      })
      .catch((err) => {
        setError("template", {
          type: "defaultTemplateFail",
          message:
            err?.message ??
            "Failed to update default template. Please try again",
        });
      });
  };

  const onTemplateSearch = (key, searchTerm) => {
    if (customerDetail?.companyId && customerDetail?.id) {
      const params = {
        skip: 0,
        limit: 10000,
        searchTerm: searchTerm,
        customerId: customerDetail?.id,
        searchTermKey: "name",
      };

      getSavedTemplateList(customerDetail?.companyId, params)
        .then((res: any) => {
          const list = res?.result?.map((item) => {
            return {
              label: item?.name,
              key: item?.id,
              ...(item?.isDefault && { isDefault: item.isDefault }),
            };
          });

          setTemplateList(list?.length ? list : null);
          setIsListOpen(true);
        })
        .catch(() => {});
    }
  };

  const onTemplateChange = (key, searchTerm) => {
    onTemplateSearch(key, searchTerm);
    setTemplate(null);
  };

  const onSelect = (key, value) => {
    if (key === "template" && value?.key) {
      getTemplateDetail(
        customerDetail?.companyId,
        customerDetail?.id,
        value?.key
      );
    }

    setValue(key, value?.label ?? "");
  };

  const onFocus = (key, searchTerm) => {
    if (key === "template-search") onTemplateSearch(key, searchTerm);
  };

  const onBlur = (key) => {
    if (key === "template" && !template) {
      setValue(key, "");
    }
  };

  formConfig.fieldList = {
    ...formConfig.fieldList,
    template: {
      ...formConfig.fieldList.template,
      ...{
        list: templateList,
        isListOpen,
        offset: { top: -10 },
        setIsListOpen,
        onChange: onTemplateChange,
        onSelect: onSelect,
        onFocus: onFocus,
        onBlur: onBlur,
      },
    },
  };

  return (
    <div className="customer-template-detail">
      <Section
        config={templateDetailConfig}
        data={customerDetail?.template}
        onEdit={() => setIsEdit(true)}
      >
        {isEdit && (
          <div className="customer-template-detail__field">
            <Form
              config={formConfig}
              control={control}
              handleSubmit={handleSubmit}
              errors={errors}
              onSubmit={() => {}}
              clearErrors={clearErrors}
              register={register}
            />
          </div>
        )}
      </Section>
    </div>
  );
};

export default CustomerTemplateDetail;
