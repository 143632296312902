import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../../Redux/store";

import { formConfig } from "./formConfig";

import Form from "../../../../Reusable/Form/Form";
import { setRegionConfig } from "../../../../../../Redux/Template/parserSlicer";
import { getRegion } from "../../../../../../Utils/labelSystem";

const LabelSetting = () => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { regionConfig, activeRegion } = useSelector(
    (state: RootState) => state.parser
  );

  useEffect(() => {
    if (regionConfig && activeRegion && formConfig) setFormValue();
  }, [regionConfig, activeRegion, formConfig]);

  useEffect(() => {
    const region = getRegion(regionConfig, activeRegion);
    setIsVisible(region?.regionType === "table" ? true : false);
  }, [regionConfig, activeRegion]);

  const setFormValue = () => {
    const labelSetting = regionConfig?.region?.[activeRegion]?.labellingSetting;
    const { fieldList } = formConfig;

    fieldList &&
      Object.keys(fieldList)?.map((key) => {
        const field = fieldList?.[key];
        const value = labelSetting?.[key];

        if (field?.type === "dropdownChoose" && field?.list) {
          const result = field.list.find((item) => item.value === value)?.label;
          if (result) setValue(key, result ?? "");
          else setValue(key, "");
        } else if (field?.type === "number") {
          setValue(key, value);
        }
      });
  };

  const setLabelSetting = (key, value) => {
    const config = JSON.parse(JSON.stringify(regionConfig));
    if (!config) return;

    const region = config?.region?.[activeRegion];
    const setting = region?.labellingSetting ?? null;

    if (setting) setting[key] = value;
    else if (region) {
      region["labellingSetting"] = { [key]: value };
    }

    dispatch(setRegionConfig(config));
  };

  const onSelect = (key, value) => {
    setValue(key, value?.label ?? "");
    setLabelSetting(key, value?.value ?? "");
  };

  const onChange = (key, value) => {
    if (key === "lineScale" || (key === "rowTolerance" && value))
      setLabelSetting(key, parseInt(value) ?? "");
  };

  const scrollSelector = ".template-detail";
  const scrollElem = scrollSelector && document.querySelector(scrollSelector);

  const dropdownFieldConfig = {
    onSelect,
    scrollElem,
  };

  formConfig.fieldList = {
    ...formConfig.fieldList,
    flavor: {
      ...formConfig.fieldList.flavor,
      ...dropdownFieldConfig,
    },
    splitText: {
      ...formConfig.fieldList.splitText,
      ...dropdownFieldConfig,
    },
    flagSize: {
      ...formConfig.fieldList.flagSize,
      ...dropdownFieldConfig,
    },
    repeatForRemainingPages: {
      ...formConfig.fieldList.repeatForRemainingPages,
      ...dropdownFieldConfig,
    },
    processBackground: {
      ...formConfig.fieldList.processBackground,
      ...{
        ...dropdownFieldConfig,
        isVisible: getValues("flavor") === "Lattice",
      },
    },
    lineScale: {
      ...formConfig.fieldList.lineScale,
      ...{ onChange: onChange, isVisible: getValues("flavor") === "Lattice" },
    },
    rowTolerance: {
      ...formConfig.fieldList.rowTolerance,
      ...{ onChange: onChange, isVisible: getValues("flavor") === "Stream" },
    },
  };

  return (
    <div className="setting--label">
      <h4 className="setting__title">
        Labelling settings
        <div
          className={
            "dropdown__arrow" + (isExpanded ? " dropdown__arrow--expand" : "")
          }
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <img alt="Dropdown arrow" />
        </div>
      </h4>

      {isExpanded && isVisible && (
        <Form
          config={formConfig}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          clearErrors={clearErrors}
          register={register}
        />
      )}

      {isExpanded && !isVisible && (
        <div className="setting__not-applicable">
          Labelling settings are not applicable for the selected region
        </div>
      )}
    </div>
  );
};

export default LabelSetting;
