import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDebounce from "../../../../../Hooks/useDebounce";

import saveIcon from "../../../../../Assets/Reusable/save.svg";

import { Company } from "../../../../../Types/company";
import { Template } from "../../../../../Types/template";
import { DocumentDetail } from "../../../../../Types/document";

import { camelToSnake, snakeToCamel } from "../../../../../Utils";

import { saveTemplateRegion } from "../../../../../APIs/Template/saveTemplateRegion";

import { RootState } from "../../../../../Redux/store";
import { setTemplateDetail } from "../../../../../Redux/Template/templateSlicer";
import { setRegionConfig } from "../../../../../Redux/Template/parserSlicer";

import Status from "../../../Reusable/Status";
import PdfViewer from "../../../Reusable/Pdf/PdfViewer";

const ParserInput: React.FC<{ getRegionIndex }> = ({ getRegionIndex }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveAllowed, setIsSaveAllowed] = useState(false);

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const templateDetail: Template = useSelector(
    (state: RootState) => state.template.detail
  );

  const templateDocument: DocumentDetail = useSelector(
    (state: RootState) => state.template.document
  );

  const { regionConfig, activeRegion, activeArea } = useSelector(
    (state: RootState) => state.parser
  );

  const setRegionCoordinate = (
    activeRegion,
    activeArea,
    coordinate,
    pageIndex
  ) => {
    const data = JSON.parse(JSON.stringify(regionConfig));

    let area = data?.region?.[activeRegion]?.area?.[activeArea] ?? null;
    if (!area) return;

    area = {
      ...area,
      coordinate: JSON.parse(JSON.stringify(coordinate)),
      pageNumber: pageIndex + 1,
    };

    data.region[activeRegion].area[activeArea] = area;
    dispatch(setRegionConfig(data));
  };

  const parseData = useDebounce(regionConfig, 1000);

  useEffect(() => {
    if (
      company?.id &&
      templateDetail?.id &&
      templateDetail?.customerId &&
      regionConfig &&
      parseData
    ) {
      if (isSaveAllowed) {
        const params = {
          customerId: templateDetail.customerId,
        };

        const config = JSON.parse(JSON.stringify(regionConfig));
        config.order = camelToSnake(config?.order);

        setIsLoading(true);

        saveTemplateRegion(company.id, templateDetail.id, config, params)
          .then((res) => {
            if (res?.data) {
              res.data.order = snakeToCamel(res.data.order);
              dispatch(setRegionConfig(res?.data));

              if (templateDetail.status === "save") {
                const detail = JSON.parse(JSON.stringify(templateDetail));
                detail.status = "edit";
                dispatch(setTemplateDetail(detail));
              }
            }
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsSaveAllowed(true);
      }
    }
  }, [JSON.stringify(parseData)]);

  return (
    <div className="parser__input">
      <div className="parser__header">
        <h4 className="parser__title">Labelling</h4>

        <div className="parser__status">
          {(templateDetail?.lastModifiedOn || isLoading) && (
            <Status
              icon={saveIcon}
              label="Last saved"
              value={templateDetail?.lastModifiedOn}
              isLoading={isLoading}
              loadingLabel={isLoading ? "Parsing..." : null}
            />
          )}
        </div>

        <div className="parser__info hint">
          Confirm the label and region, then scroll to the desired page and
          proceed to draw the region. For enhanced parsing, optimize the
          labeling and validation settings.
        </div>
      </div>

      <div className="parser__drawer">
        <PdfViewer
          type="draw"
          pdfViewerId={"templateDetail"}
          documentDetail={templateDocument}
          config={regionConfig}
          activeRegion={activeRegion}
          activeArea={activeArea}
          getRegionIndex={getRegionIndex}
          setRegionCoordinate={setRegionCoordinate}
        />
      </div>
    </div>
  );
};

export default ParserInput;
