import { useState, useEffect } from "react";

import EditIcon from "../../../Assets/Reusable/EditIcon";

import { parseAttribute } from "../../../Utils/parseAttribute";

import Attribute from "./Attribute/Attribute";
import File from "./Card/File";

const Section: React.FC<{ config; data?; onEdit?; children? }> = ({
  config,
  data,
  onEdit,
  children,
}) => {
  const [attributeList, setAttributeList] = useState(null);

  useEffect(() => {
    if (config && data) {
      const list = parseAttribute(data, config);
      setAttributeList(list ?? null);
    }
  }, [config, data]);

  const renderAttributeList = attributeList?.map((attribute, index) => {
    return <Attribute key={index} attribute={attribute} />;
  });

  const renderFileList = data?.fileList?.map((file, index) => {
    return <File key={index} file={file} />;
  });

  const renderEmpty = () => {
    if (
      Object.prototype.hasOwnProperty.call(config, "fileList") &&
      config?.defaultValue &&
      !data?.fileList
    ) {
      return (
        <Attribute
          key={"file-empty"}
          attribute={{ defaultValue: config?.defaultValue }}
        />
      );
    } else if (!data) {
      return (
        <Attribute
          key={"attribute-empty"}
          attribute={{ defaultValue: `${config?.label} unavailable` }}
        />
      );
    }
  };

  return (
    <section className="section">
      <div className="section__label">
        {config?.label}
        {onEdit && (
          <div className="section__edit" onClick={onEdit}>
            <EditIcon />
          </div>
        )}
      </div>

      <main className="section__content">
        {renderAttributeList}
        {renderFileList}
        {renderEmpty()}
        {children}
      </main>
    </section>
  );
};

export default Section;
