import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { emailCardConfig, assignedEmailCardConfig } from "./emailCardConfig";

import { parseAttribute } from "../../../Utils/parseAttribute";
import { formatDate } from "../../../Utils/formatDate";

import { Email, EmailListResponse } from "../../../Types/email";
import { Company } from "../../../Types/company";

import { RootState } from "../../../Redux/store";
import { deleteFromEmailList } from "../../../Redux/Email/emailSlicer";

import { updateEmail as updateEmailAPI } from "../../../APIs/Email/updateEmail";
import { createSaleFromEmail as unassignSaleAPI } from "../../../APIs/Email/createSale";
import { getDocumentDetail as getDocumentDetailAPI } from "../../../APIs/Reusable/getDocumentDetail";

import Attribute from "../Reusable/Attribute/Attribute";
import Button from "../Reusable/Button";
import Card from "../Reusable/Card/Card";
import Menu from "../Reusable/Menu/Menu";
import Confirmation from "../Reusable/Confirmation/Confirmation";
import { openLink } from "../../../Utils";

const EmailCard: React.FC<{ email: Email; activeTab }> = ({
  email,
  activeTab,
}) => {
  const [attributeList, setAttributeList] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParam, setSearchParam] = useSearchParams();
  const isAssigned = email.assignedTo ?? false;
  const isMarkForLater = activeTab === "markForLater";

  const dispatch = useDispatch();

  const company: Company = useSelector(
    (state: RootState) => state.user.company
  );

  const active: EmailListResponse["active"] = useSelector(
    (state: RootState) => state.email?.list?.active
  );

  useEffect(() => {
    email = {
      ...email,
      deliveredOn: formatDate(email.deliveredOn) ?? null,
      lastModifiedOn: formatDate(email.lastModifiedOn) ?? null,
      assignedOn: formatDate(email.assignedOn) ?? null,
    };

    const list = parseAttribute(email, emailCardConfig);

    let assignedList = [];
    if (isAssigned) {
      assignedList = parseAttribute(email, assignedEmailCardConfig);
    }

    setAttributeList(
      [
        ...list.slice(0, 4),
        assignedList[1],
        ...list.slice(4, 7),
        assignedList[0],
      ] ?? null
    );
  }, [email]);

  const createSale = () => {
    const params = {
      modal: "create",
      target: "sale",
      eid: email?.id?.toString(),
      cid: email?.customerId?.toString(),
    };
    setSearchParam(createSearchParams(params));
  };

  const updateEmail = () => {
    if (company?.id && email.id) {
      const data = { markLater: !isMarkForLater };

      setIsLoading(true);
      updateEmailAPI(company?.id, email.id, data)
        .then(() => {
          dispatch(deleteFromEmailList({ activeTab, emailId: email.id }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const unassignSale = () => {
    if (email?.id && email?.customerId) {
      const data = {
        assign: false,
      };

      const params = {
        customerId: email?.customerId,
      };

      unassignSaleAPI(company?.id, email?.id, data, params)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getDocumentDetail = () => {
    if (company?.id && email?.id) {
      getDocumentDetailAPI(company.id, "email", email?.id, null)
        .then((res: any) => {
          openLink(res?.link, true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderAttributeList = attributeList?.map((attribute, index) => {
    return <Attribute key={index} attribute={attribute} />;
  });

  if (!email) return;

  const menuList = [
    {
      key: "openFile",
      label: "Open document",
      action: () => getDocumentDetail(),
    },
    {
      key: isMarkForLater ? "moveToInbox" : "markForLater",
      label: isMarkForLater ? "Move to inbox" : "Mark for later",
      isDisabled: isAssigned,
      action: () => setIsConfirm(true),
    },
    ...(isAssigned
      ? [
          {
            key: "unassignSale",
            label: "Unassign sale",
            action: () => unassignSale(),
          },
        ]
      : []),
  ];

  const confirmationConfig = {
    title: `Confirm to${
      isMarkForLater
        ? " move the email to inbox?"
        : " mark the email for later?"
    }`,
    info: (
      <>
        Are you sure you want to
        {isMarkForLater
          ? " move the email to inbox? "
          : " mark the email for later? "}
        It will be relocated to the
        <b>{isMarkForLater ? " Inbox" : " Mark for Later"}</b> tab, where you
        can find it.
        <br />
        If you decide to change it back, you can revert it to the
        <b>{isMarkForLater ? " Marked for Later" : " Inbox"}</b> at a later
        stage
      </>
    ),
    actionList: {
      proceed: {
        type: "button",
        label: isMarkForLater ? "Move to inbox" : "Mark for later",
        id: isMarkForLater ? "moveEmailToInbox" : "markEmailForLater",
        style: "primary",
        size: "medium",
        isLoading: isLoading,
        onClick: updateEmail,
      },
      cancel: {
        type: "button",
        label: "Cancel",
        id: "cancelMarkEmailForLater",
        style: "secondary",
        size: "medium",
        onClick: () => setIsConfirm(false),
      },
    },
  };

  return (
    <Card isActive={email?.id === active?.id}>
      <div
        className={"email__card" + (isAssigned ? " email__card--assigned" : "")}
      >
        {renderAttributeList}

        {!isAssigned && (
          <Button
            type="button"
            label="Create sale"
            id="create-sale"
            style="primary"
            size="small"
            onClick={createSale}
          />
        )}

        {isAssigned && email?.saleId && email.customerId && (
          <Button
            type="link"
            label="View sale"
            id="view-sale"
            style="secondary"
            size="small"
            to={`/sale/${email?.saleId}?customer=${email.customerId}`}
          />
        )}
      </div>

      <Menu list={menuList} />

      {isConfirm && <Confirmation config={confirmationConfig} />}
    </Card>
  );
};

export default EmailCard;
