import { useEffect, RefObject } from "react";

const useOnScroll = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>
) => {
  useEffect(() => {
    const listener = () => {
      const el = ref?.current;
      if (!el) return null;

      if (el.scrollHeight > el.clientHeight) {
        el.classList.add("scroll--active");
      } else {
        el.classList.remove("scroll--active");
      }
    };

    window.addEventListener("resize", listener);
    listener();

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [ref?.current?.scrollHeight]);
};

export default useOnScroll;
