import axios from "axios";

export const getSaleList = async (companyId, params) => {
  try {
    const res = await axios.get(`/company/${companyId}/sale`, { params });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
