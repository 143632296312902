import { useState } from "react";
import { Navigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { formConfig } from "./formConfig";

import { useAuth } from "../../../Hooks/useAuth";
import { LoginFormField } from "../../../Types/login";
import { clearUser, setUser } from "../../../Redux/User/userSlicer";

import { onUserLogin } from "../../../APIs/Onboard/onUserLogin";
import { getUserDetails } from "../../../APIs/Onboard/getUserDetails";

import Form from "../../Protected/Reusable/Form/Form";

const Login = () => {
  const dispatch = useDispatch();
  const { login, session, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<LoginFormField>();

  const onSubmit: SubmitHandler<LoginFormField> = (data) => {
    const loginData = new FormData();
    loginData.append("username", data.username);
    loginData.append("password", data.password);

    setIsLoading(true);

    onUserLogin(loginData)
      .then((res) => {
        if (!res) {
          return;
        }

        login(res);

        getUserDetails()
          .then((user) => {
            dispatch(setUser(user));
          })
          .catch(() => {
            dispatch(clearUser());
            logout();
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);

        let error = {
          type: "auth-failure",
          message:
            "Unexpected error during  the authentication. Please try again later. If issue persists, contact support",
        };

        if (err?.response?.status === 400)
          error = {
            type: "400",
            message:
              "Authentication failed. Please verify your email address and password",
          };

        setError("root.serverError", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (session) return <Navigate to="/" />;

  return (
    <div className="login">
      <header className="login__header">
        <h1 className="login__title">Welcome back!</h1>
        <h2 className="login__info">
          Enter your credentials to access your account
        </h2>
      </header>

      <Form
        config={formConfig["login"]}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        errors={errors}
        register={register}
      />
    </div>
  );
};

export default Login;
