import axios from "axios";

export const deleteTemplate = async (companyId, templateId, params) => {
  try {
    const res = await axios.delete(
      `/company/${companyId}/template/${templateId}`,
      {
        params,
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
